import { call, put } from "redux-saga/effects";

import api from "services/api";
import { notify } from "services/notification";
import { requestErrorHandler } from "utils";
import {
  NextAppointmentsActions,
  NextAppointmentsRequest,
} from "../../ducks/appointment";

export function* nextAppointmentsRequest(action: any) {
  try {
    const { onSuccess } = action as NextAppointmentsRequest;
    const { data } = yield call(
      api.get,
      "appointment/find-client-next-appointments"
    );
    if (onSuccess) onSuccess();
    yield put(NextAppointmentsActions.success(data));
  } catch (error) {
    const { onFailure } = action as NextAppointmentsRequest;
    if (onFailure) onFailure();
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(NextAppointmentsActions.failure(errorMessage));
  }
}
