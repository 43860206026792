import { call, put } from "redux-saga/effects";

import api from "services/api";
import { notify } from "services/notification";
import { requestErrorHandler } from "utils";

import {
  UpdatePasswordRequest,
  UpdatePasswordActions,
} from "store/ducks/passwordRecovery";

export function* updatePasswordRequest(action: any) {
  try {
    const { postData, onSuccess } = action as UpdatePasswordRequest;
    yield call(
      api.post,
      "session/update-password-with-recovery-code",
      postData
    );
    if (onSuccess) onSuccess();
    notify("success", "Password updated");
    yield put(UpdatePasswordActions.success());
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(UpdatePasswordActions.failure(errorMessage));
  }
}
