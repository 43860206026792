export const translations = {
  title: {
    pt: "Fale Conosco",
    en: "Talk to Us",
    es: "Hable con Nosotros",
  },
  subtitle: {
    pt: "Contato",
    en: "Contact",
    es: "Contacto",
  },
  description: {
    pt:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat sed.",
    en:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat sed.",
    es:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat sed.",
  },
};
