import styled from "styled-components";
import { CheckCircle } from "@styled-icons/material-rounded/CheckCircle";
import { Cancel } from "@styled-icons/material-rounded/Cancel";
import { ChevronRight } from "@styled-icons/heroicons-outline/ChevronRight";
import { Button } from "styles/components";

export const MainContainer = styled.div``;

export const Header = styled.div`
  padding: 38px 52px 16px 52px;
`;

export const Title = styled.h6`
  font-family: "GothamBold";
  margin-bottom: 0px;
  font-size: 16px;
  color: #ffffff;
`;

export const ListItem = styled.div<{ active?: boolean }>`
  background-color: ${({ active }) => (active ? "#434343" : "transparent")};
  padding: 0 28px;
  transition: background-color 300ms ease-in-out;
`;

export const ListItemConstrainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px;
  border-bottom: 1px solid #4b4b4b;
`;

export const StatusColumn = styled.div`
  margin-right: 12px;
`;

export const MainColumn = styled.div`
  font-family: "BrandontextRegular";
  font-size: 15px;
`;

export const DateValue = styled.div`
  color: #ffffff;
`;

export const ServicesValue = styled.div`
  color: #bcbcbc;
`;

export const ConcludedIcon = styled(CheckCircle).attrs({
  size: 20,
})`
  color: #0ba86d;
`;

export const CanceledIcon = styled(Cancel).attrs({
  size: 20,
})`
  color: #c83d6b;
`;

export const EmptyIcon = styled(Cancel).attrs({
  size: 20,
})`
  color: #cad5dd;
`;

export const DetailsButton = styled(Button).attrs({
  type: "button",
  whileHover: { scale: 1.1 },
})`
  color: #fff;
  text-transform: uppercase;
  font-size: 13px;
  background-color: transparent;
  margin-left: auto;
  padding: 8px 0;
`;

export const ChevronRightIcon = styled(ChevronRight).attrs({
  size: 20,
})``;
