import styled from "styled-components";
import mina from "assets/images/banner/mina.png";
import { LocationPin } from "@styled-icons/entypo";

export { Button } from "styles/components";

export const Container = styled.section`
  display: flex;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  height: 100%;
  max-height: 650px;
`;

export const BannerImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

export const Mina = styled.img.attrs({ src: mina })`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 50%;
  background-color: #1a1a1a;

  &.left {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 48px;
  }

  &.right {
    overflow: hidden;
  }

  @media screen and (max-width: 980px) {
    &.left {
      max-width: 100%;
    }
    &.right {
      display: none;
    }
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 450px;

  @media screen and (max-width: 980px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const Subtitle = styled.h2`
  font-size: 20px;
  color: #fff;
  margin-bottom: 24px;
  text-transform: uppercase;

  @media screen and (max-width: 980px) {
    text-align: center;
  }
`;

export const Title = styled.h1`
  font-size: 76px;
  line-height: 70px;
  color: #fff;
  margin-bottom: 24px;

  span {
    color: #de0048;
  }

  @media screen and (max-width: 980px) {
    text-align: center;
  }

  @media screen and (max-width: 414px) {
    font-size: 40px;
    line-height: 40px;
  }
`;

export const Description = styled.article`
  font-size: 18px;
  line-height: 24px;
  color: #fff;
  margin-bottom: 32px;

  @media screen and (max-width: 980px) {
    text-align: center;
  }

  @media screen and (max-width: 414px) {
    font-size: 16px;
  }
`;

export const Address = styled.span`
  display: flex;
  align-items: center;
  margin-top: 24px;
  font-size: 15px;
  color: #fff;
`;

export const IconPin = styled(LocationPin).attrs({ size: 20 })`
  margin-right: 8px;
`;
