import React from "react";
import { useTranslation } from "hooks";
import { translations } from "./translations";

import * as S from "./styles";

const Banner: React.FC = () => {
  const { getTranslation } = useTranslation(translations);
  return (
    <S.Container>
      <S.Subtitle>{getTranslation("subtitle")}</S.Subtitle>
      <S.Title>{getTranslation("title")}</S.Title>
      {/* <S.Description>{getTranslation("description")}</S.Description> */}
    </S.Container>
  );
};

export default Banner;
