import styled from "styled-components";
import { Button } from "styles/components";
export { Loading, PageContainer } from "styles/components";

export const ContentConstrainer = styled.div`
  margin: 32px auto;
  max-width: 680px;
  width: 100%;
  padding: 0 24px;
`;

export const Divider = styled.hr`
  border: none;
  border-bottom: 2px solid #cad5dd;
  margin: 32px 0;
`;

// Header

export const Header = styled.div`
  text-align: center;
`;

export const HeaderTitle = styled.h2`
  color: #091524;
  font-size: 30px;
  font-family: "GothamBook";
  font-weight: 400;
  margin-bottom: 16px;
`;

export const ClientInfo = styled.div`
  color: #434e5c;
  font-family: "BrandontextMedium";
  margin-bottom: 24px;
`;

export const ClientName = styled.p`
  font-size: 22px;
  margin-bottom: 24px;
`;

export const ClientContact = styled.p`
  font-size: 16px;
  span {
    display: inline-block;
    margin: 0 12px;
  }
`;

// Appointment
export const Appointment = styled.div`
  border-top: 2px solid #cad5dd;
  border-bottom: 2px solid #cad5dd;
  padding: 32px 0;
  color: #091524;

  @media screen and (min-width: 601px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 32px;
  }
`;

export const Column = styled.div.attrs({ className: "column" })`
  display: flex;
  flex-direction: column;
`;

export const ColumnLabel = styled.div`
  font-size: 16px;
  font-family: "BrandontextRegular";
  margin-bottom: 12px;
`;

export const ColumnValue = styled.div`
  font-size: 18px;
  font-family: "BrandontextMedium";
  margin-bottom: 8px;
`;

export const DateColumnValue = styled.div`
  font-size: 24px;
  font-family: "BrandontextMedium";
  margin-bottom: 8px;
`;

// Price details

export const PaymentDetails = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 18px;
  padding: 24px 0;
  color: #091524;
`;

export const TotalPrice = styled.div`
  flex: 0 1 100px;
`;

export const Fee = styled.div`
  span {
    color: #de0048;
    font-family: "BrandontextBold";
  }
`;

export const PaymentInstructions = styled.div`
  font-size: 16px;
  margin-bottom: 48px;
`;

export const ActionButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const BackButton = styled(Button).attrs({
  btStyle: "outline",
  whileHover: { scale: 1.1 },
})``;

export const PayButton = styled(Button).attrs({
  btStyle: "primary",
  whileHover: { scale: 1.1 },
})``;
