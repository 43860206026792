export const translations = {
  title: {
    en: "Next Appointments",
    pt: "Próximas consultas",
    es: "Próximas citas",
  },
  empty: {
    en: "You have no next appointments yet.",
    pt: "Você ainda não tem próximas consultas.",
    es: "Aún no tienes próximas citas.",
  },
  buttons: {
    details: {
      en: "Details",
      pt: "Detalhes",
      es: "Detalles",
    },
  },
};
