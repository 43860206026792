import React, { useCallback } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

import { useTranslation } from "hooks";
import { AuthActions, AuthState } from "store/ducks/auth";
import { translation } from "./translation";
import * as S from "./styles";

interface UserWidgetProps extends React.ComponentProps<any> {}

type Props = UserWidgetProps;

export const UserWidget: React.FC<Props> = () => {
  const dispath = useDispatch();
  const { getTranslation } = useTranslation(translation);
  const { loggedIn } = useSelector<RootStateOrAny>(
    (state) => state.auth
  ) as AuthState;

  const handleLogout = useCallback((): void => {
    dispath(AuthActions.logoutRequest());
  }, [dispath]);

  return (
    <>
      {loggedIn && (
        <S.MainContainer>
          <S.Button onClick={handleLogout}>
            {getTranslation("buttons", "logout")}
          </S.Button>
        </S.MainContainer>
      )}
    </>
  );
};
