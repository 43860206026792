import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { toast } from "react-toastify";
import { ScrollFixer } from "utils/ScrollFixer";

import "./config/ReactotronConfig";
import { store, persistor } from "./store";

import Routes from "routes";
import GlobalStyle from "./styles/global";

import LanguageProvider from "Language/LanguageProvider";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

toast.configure();

const App: React.FC = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter basename="/">
        <ScrollFixer />
        <GlobalStyle />
        <LanguageProvider>
          <Routes />
        </LanguageProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

export default App;
