import querystring from "querystring";

export const queryBuilder = (params: any) => {
  let cleanParams: any = [];
  Object.keys(params).forEach((key) => {
    const value = params[key];
    if (value) cleanParams[key] = value;
  });
  return querystring.stringify(cleanParams);
};
