import styled from "styled-components";
import { RightArrowAlt, LeftArrowAlt } from "@styled-icons/boxicons-regular/";

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  padding: 64px 32px;

  @media screen and (max-width: 1000px) {
    padding: 48px 32px;
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Subtitle = styled.h5`
  font-size: 16px;
  text-transform: uppercase;
  color: #de0048;
  margin-bottom: 32px;
`;

export const Title = styled.h1`
  font-size: 36px;
  line-height: 40px;
  color: #1a1a1a;
  margin-bottom: 72px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 590px;

  @media screen and (max-width: 1000px) {
    font-size: 30px;
    line-height: 30px;
  }
`;

export const SliderWrapper = styled.div`
  width: 100%;

  img {
    width: 100%;
    object-fit: cover;
    transition: 300ms ease;
    transform: scale(0.5);
  }
  .slick-prev:before,
  .slick-next:before {
    content: "";
    display: none;
  }

  .center {
    .slick-center img {
      transform: scale(1);
    }
  }

  @media screen and (max-width: 1000px) {
    img {
      transform: scale(1);
    }
  }
`;

export const Photo = styled.div`
  @media screen and (max-width: 1000px) {
    padding: 0 32px;
  }
`;

export const IconNext = styled(RightArrowAlt).attrs({ size: 24 })`
  color: #1a1a1a;
`;

export const IconPrev = styled(LeftArrowAlt).attrs({ size: 24 })`
  color: #1a1a1a;
`;

export const PrevButton = styled.button.attrs({ type: "button" })`
  width: 64px;
  height: 64px;
  position: absolute;
  border: 2px #1a1a1a solid;
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 300ms ease;

  @media screen and (max-width: 1000px) {
    display: none !important  ;
  }
`;

export const NextButton = styled(PrevButton)`
  @media screen and (max-width: 1000px) {
    display: none !important;
  }
`;
