interface Translation {
  [props: string]: {
    [props: string]: string;
  };
}

export const translation: Translation = {
  pt: {
    home: "Home",
    "menu.studio": "O Estúdio",
    "menu.services": "Serviços",
    "menu.work": "Nosso trabalho",
    "menu.contact": "Contato",
    "menu.reservation": "Minha reserva",
    "menu.booknow": "Agende agora",

    "home.banner.get_your_hair": "Pegue seu cabelo",
    "home.banner.styled_by_our": "Desenhado por nossa",
    "home.banner.team": "equipe",
    "home.banner.description":
      "Contamos com uma equipe de especialistas em cortes, colorações e tratamentos para que você consiga um penteado personalizado que melhor se adapte a você.",
    "home.banner.book_now": "Agende agora",

    whatsapp: "Precisa de informações? Fale Conosco",

    "home.about.subtitle": "O Estúdio",
    "home.about.title":
      "Desfrute de uma experiência relaxante e confortável no salão",
    "home.about.text.p1":
      "Valorizamos a qualidade de serviço. Trabalhamos com os melhores produtos, equipamentos de alta tecnologia e grandes profissionais. Nossa meta é oferecer uma experiência única a nossos clientes: Conforto, agilidade e serviços de qualidade.O BN Studio vai além de promover a beleza, promovemos autoestima e felicidade.",
    "home.about.knowmore": "Saiba mais",
    "home.about.opening.title": "Horário de Funcionamento",
    "home.about.week.monday": "Segunda",
    "home.about.week.tuesday": "Terça",
    "home.about.week.wednesday": "Quarta",
    "home.about.week.thursday": "Quinta",
    "home.about.week.friday": "Sexta",
    "home.about.week.saturday": "Sábado",
    "home.about.week.sunday": "Domingo",
    "home.about.closed": "Fechado",
    "home.about.opensat9am": "Aberto das 9h às 19h",

    "home.services.subtitle": "serviços",
    "home.services.title":
      "Tudo que você precisa para um cabelo perfeito e brilhante",
    "home.services.haircut": "Corte",
    "home.services.colour": "Colorir",
    "home.services.highlightingPainting": "Luzes e Pintura",
    "home.services.hairTreatment": "Tratamento de cabelo",
    "home.services.extension": "Extensãon",
    "home.services.eyebrownsLashes": "Sobrancelhas e cílios",

    "home.ourWork.subtitle": "Nosso trabalho",
    "home.ourWork.title": "Confira alguns de nossos lindos e felizes clientes",

    "studio.banner.subtitle": "O estúdio",
    "studio.banner.title": "Sobre nós",
    "studio.banner.description":
      "Hair Stylist • Expert em Loiros • Mega Hair Importado • Massagem e Beleza",

    "studio.gallery.subtitle": "nosso estúdio",
    "studio.gallery.title": "Um lugar lindo e relaxante em Miami",

    "footer.description":
      "Visite nosso salão e conheça todos os procedimentos que temos para deixar você ainda mais linda.",
    "footer.hours": "Horário",
    "footer.hours.week.monday": "Segunda",
    "footer.hours.week.tuesday": "Terça",
    "footer.hours.week.wednesday": "Quarta",
    "footer.hours.week.thursday": "Quinta",
    "footer.hours.week.friday": "Sexta",
    "footer.hours.week.saturday": "Sábado",
    "footer.hours.week.sunday": "Domingo",
    "footer.hours.closed": "Fechado",
    "footer.hours.opensat9am": "Aberto das 9h às 19h",
    "footer.findus": "Ache-nos",
    "footer.mapbutton": "Ver mapa",
  },
  en: {
    home: "Home",
    "menu.studio": "The studio",
    "menu.services": "Services",
    "menu.work": "Our Work",
    "menu.contact": "Contact",
    "menu.reservation": "My Appointment",
    "menu.booknow": "Book now",

    "home.banner.get_your_hair": "Get your hair",
    "home.banner.styled_by_our": "Styled by our",
    "home.banner.team": "team",
    "home.banner.description":
      "We have a team of experts in haircuts, colouring and treatments so that you can achieve a customized hairstyle that best suits you.",
    "home.banner.book_now": "Book now",

    whatsapp: "Need information? Talk to us",

    "home.about.subtitle": "The Studio",
    "home.about.title": "Enjoy a relaxing and comfortable salon experience",
    "home.about.text.p1":
      "We value excellent service. We work with the best products, high-tech equipment, and great professionals. Our goal is to offer a unique experience to our customers: Comfort, agility, and quality service. BN Studio goes beyond promoting beauty, we promote self-esteem and happiness.",
    "home.about.knowmore": "Learn More",
    "home.about.opening.title": "Opening Hours",
    "home.about.week.monday": "Monday",
    "home.about.week.tuesday": "Tuesday",
    "home.about.week.wednesday": "Wednesday",
    "home.about.week.thursday": "Thursday",
    "home.about.week.friday": "Friday",
    "home.about.week.saturday": "Saturday",
    "home.about.week.sunday": "Sunday",
    "home.about.closed": "Closed",
    "home.about.opensat9am": "Open from 9am to 7pm",

    "home.services.subtitle": "services",
    "home.services.title": "All you need for perfect and shining hair",
    "home.services.haircut": "Haircut",
    "home.services.colour": "Colour",
    "home.services.highlightingPainting": "Highlighting & Painting",
    "home.services.hairTreatment": "Hair Treatment",
    "home.services.extension": "Extension",
    "home.services.eyebrownsLashes": "Eyebrows & Lashes",

    "home.ourWork.subtitle": "Our work",
    "home.ourWork.title": "Check out some of our beautiful and happy clients",

    "studio.banner.subtitle": "The studio",
    "studio.banner.title": "About us",
    "studio.banner.description":
      "Hair Stylist • Expert in blondes • Imported Mega Hair • Massage and Beauty",

    "studio.gallery.subtitle": "our studio",
    "studio.gallery.title": "A beautiful and relaxing place in Miami",

    "footer.description":
      "Visit our salon and know all the procedures we have to make you even more beautiful.",
    "footer.hours": "Hours",
    "footer.hours.week.monday": "Monday",
    "footer.hours.week.tuesday": "Tuesday",
    "footer.hours.week.wednesday": "Wednesday",
    "footer.hours.week.thursday": "Thursday",
    "footer.hours.week.friday": "Friday",
    "footer.hours.week.saturday": "Saturday",
    "footer.hours.week.sunday": "Sunday",
    "footer.hours.closed": "Closed",
    "footer.hours.opensat9am": "Open from 9am to 7pm",
    "footer.findus": "Find us",
    "footer.mapbutton": "View map",
  },
  es: {
    home: "Home",
    "menu.studio": "El estudio",
    "menu.services": "Servicios",
    "menu.work": "Nuestro trabajo",
    "menu.contact": "Contacto",
    "menu.reservation": "Mi reserva",
    "menu.booknow": "Reservar ahora",

    "home.banner.get_your_hair": "consigue tu cabello",
    "home.banner.styled_by_our": "Diseñado por nuestro",
    "home.banner.team": "equipo",
    "home.banner.description":
      "Contamos con un equipo de expertos en cortes de cabello, coloración y tratamientos para que puedas lograr un peinado personalizado que mejor se adapte a ti.",
    "home.banner.book_now": "Reservar ahora",

    whatsapp: "¿Necesitas información? Háblanos",

    "home.about.subtitle": "El Estudio",
    "home.about.title":
      "Disfrute de una experiencia de salón relajante y cómoda",
    "home.about.text.p1":
      "Valoramos la excelencia en la atención al cliente. Trabajamos con los mejores productos, equipos de alta tecnología y excelentes profesionales. Nuestro objetivo es ofrecer una experiencia única a nuestros clientes: Comodidad, agilidad y servicio de calidad. BN Studio va más allá de promover la belleza, promovemos la autoestima y la felicidad.",
    "home.about.knowmore": "saber más",
    "home.about.opening.title": "Horario de apertura",
    "home.about.week.monday": "Lunes",
    "home.about.week.tuesday": "Martes",
    "home.about.week.wednesday": "Miércoles",
    "home.about.week.thursday": "Jueves",
    "home.about.week.friday": "Viernes",
    "home.about.week.saturday": "Sábado",
    "home.about.week.sunday": "Domingo",
    "home.about.closed": "Cerrado",
    "home.about.opensat9am": "Abierto de 9h a 19h",

    "home.services.subtitle": "servicios",
    "home.services.title":
      "Todo lo que necesitas para un cabello perfecto y brillante",
    "home.services.haircut": "Corte",
    "home.services.colour": "Color",
    "home.services.highlightingPainting": "Resaltado y pintura",
    "home.services.hairTreatment": "Tratamiento capilar",
    "home.services.extension": "Extensión",
    "home.services.eyebrownsLashes": "Cejas y pestañas",

    "home.ourWork.subtitle": "Nuestro trabajo",
    "home.ourWork.title":
      "Echa un vistazo a algunos de nuestros hermosos y felices clientes",

    "studio.banner.subtitle": "El estudio",
    "studio.banner.title": "Sobre nosotros",
    "studio.banner.description":
      "Peluquero • Experto en rubias • Mega Hair importado • Masaje y belleza",

    "studio.gallery.subtitle": "nuestro estudio",
    "studio.gallery.title": "Un lugar hermoso y relajante en Miami.",

    "footer.description":
      "Visita nuestro salón y conoce todos los procedimientos que tenemos para hacerte aún más bella.",
    "footer.hours": "Horas",
    "footer.hours.week.monday": "Lunes",
    "footer.hours.week.tuesday": "Martes",
    "footer.hours.week.wednesday": "Miércoles",
    "footer.hours.week.thursday": "Jueves",
    "footer.hours.week.friday": "Viernes",
    "footer.hours.week.saturday": "Sábado",
    "footer.hours.week.sunday": "Domingo",
    "footer.hours.closed": "Cerrado",
    "footer.hours.opensat9am": "Abierto de 9h a 19h",
    "footer.findus": "Encuéntranos",
    "footer.mapbutton": "Ver el mapa",
  },
};
