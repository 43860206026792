import { call, put, all } from "redux-saga/effects";

import api from "services/api";
import { notify } from "services/notification";
import { requestErrorHandler } from "utils";

import { AuthActions, UserData } from "store/ducks/auth";
import {
  UpdateAccountActions,
  UpdateAccountRequest,
} from "store/ducks/account";

export function* updateAccountRequest(action: any) {
  try {
    const { clientId, putData, onSuccess } = action as UpdateAccountRequest;

    yield call(api.put, `client/${clientId}`, putData);

    const refreshedUserData: Partial<UserData> = {
      first_name: putData.first_name,
      last_name: putData.last_name,
      phone: putData.phone,
    };

    if (onSuccess) onSuccess();
    notify("success", "Account updated");
    yield all([
      put(UpdateAccountActions.success()),
      put(AuthActions.refreshUserData(refreshedUserData)),
    ]);
  } catch (error) {
    console.log("ERROR", error);
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(UpdateAccountActions.failure(errorMessage));
  }
}
