import { createReducer, createActions } from "reduxsauce";
import update from "immutability-helper";

export interface AppointmentState {
  data: Record<string, any> | null;
  loading: boolean;
  error: string | null;
}

export interface AppointmentRequest {
  appointmentId: number;
  onSuccess?(): void;
  onFailure?(): void;
}

interface SuccessAction {
  data: Record<string, any>;
}

interface FailureAction {
  error: string;
}

const { Types, Creators } = createActions(
  {
    request: ["appointmentId", "onSuccess", "onFailure"],
    success: ["data"],
    failure: ["error"],
    reset: [],
  },
  { prefix: "APPOINTMENT_" }
);

const INITIAL_STATE: AppointmentState = {
  data: null,
  loading: false,
  error: null,
};

const request = (state: AppointmentState) =>
  update(state, {
    loading: { $set: true },
    error: { $set: null },
  });

const success = (state: AppointmentState, action: SuccessAction) =>
  update(state, {
    data: { $set: action.data },
    loading: { $set: false },
  });

const failure = (state: AppointmentState, action: FailureAction) =>
  update(state, {
    loading: { $set: false },
    error: { $set: action.error },
  });

const reset = () => INITIAL_STATE;

export const appointment = createReducer(INITIAL_STATE, {
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success,
  [Types.FAILURE]: failure,
  [Types.RESET]: reset,
});

export const AppointmentTypes = Types;
export const AppointmentActions = Creators;
