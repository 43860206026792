import React, { useCallback, useEffect } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { format } from "date-fns";

import { AppointmentActions, AppointmentState } from "store/ducks/appointment";
import { Header, Footer } from "components/shared";
import { useTranslation } from "hooks";
import { translations } from "./translations";
import * as S from "./styles";

interface CheckoutCompletePageProps extends React.ComponentProps<any> {}
type Props = CheckoutCompletePageProps;

export const CheckoutCompletePage: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { getTranslation } = useTranslation(translations);
  const { appointmentId } = useParams<Record<string, any>>();

  const { data: appointment, loading: loadingAppointment } =
    useSelector<RootStateOrAny>(
      (state) => state.appointment
    ) as AppointmentState;

  const AppointmentComponent: React.FC = useCallback(() => {
    if (loadingAppointment) return <S.Loading />;
    if (!appointment) return <></>;

    const {
      starts_at,
      appointment_services = [],
      fee_amount,
      services_amount,
      total_amount,
    } = appointment;

    const services: string[] = appointment_services.map(
      (i: any) => i?.service?.name
    );

    const professionals: string[] = appointment_services.map((i: any) => {
      const { first_name, last_name } = i?.team;
      return `${first_name} ${last_name}`.trim();
    });

    return (
      <>
        <S.Header>
          <S.HeaderStatus>
            <S.ConfirmedIcon />
          </S.HeaderStatus>
          <S.HeaderTitle>
            <h2>{getTranslation("title")}</h2>
            <h1>{getTranslation("subtitle")}</h1>
          </S.HeaderTitle>
        </S.Header>
        <S.Appointment>
          <S.Column>
            <S.ColumnLabel>
              {getTranslation("labels", "reservationDate")}
            </S.ColumnLabel>
            <S.DateColumnValue>
              {format(new Date(starts_at), "MM/dd/yyyy")}
            </S.DateColumnValue>
            <S.ColumnLabel>
              {getTranslation("labels", "reservationTime")}
            </S.ColumnLabel>
            <S.DateColumnValue>
              {format(new Date(starts_at), "hh:mm aaa")}
            </S.DateColumnValue>
          </S.Column>

          <S.Column>
            <S.ColumnLabel>
              {getTranslation("labels", "services")}
            </S.ColumnLabel>
            {services.map((i, key) => (
              <S.ColumnValue key={key}>{i}</S.ColumnValue>
            ))}
          </S.Column>

          <S.Column>
            <S.ColumnLabel>
              {getTranslation("labels", "professionals")}
            </S.ColumnLabel>
            {professionals.map((i, key) => (
              <S.ColumnValue key={key}>{i}</S.ColumnValue>
            ))}
          </S.Column>
        </S.Appointment>

        <S.PaymentDetails>
          <div className="first-column">
            <S.TotalPrice>US$ {total_amount}</S.TotalPrice>
            <S.Fee>
              {getTranslation("labels", "fee")} (20%):{" "}
              <span>
                US${" "}
                {`${fee_amount} (${getTranslation("labels", "confirmedFee")})`}
              </span>
            </S.Fee>
          </div>
          <div className="second-column">
            <S.PendingPayment>
              {getTranslation("labels", "pendingPayment")}:{" "}
              <span>US$ {services_amount}</span>
            </S.PendingPayment>
            <S.PayOnAppointmentDate>
              {getTranslation("labels", "payOnAppointmentDate")}
            </S.PayOnAppointmentDate>
          </div>
        </S.PaymentDetails>
      </>
    );
  }, [getTranslation, appointment, loadingAppointment]);

  const FooterActions: React.FC = useCallback(() => {
    return (
      <S.FooterActions>
        <S.LinkButton
          btStyle="outline"
          whileHover={{ scale: 1.1 }}
          href="https://g.page/bnstudiosalon?share"
          target="_blank"
        >
          {getTranslation("buttons", "findUs")}
        </S.LinkButton>
        <S.MoreInfo>
          <p>{getTranslation("moreInfo")}</p>
          <p>(305) 603-9906</p>
        </S.MoreInfo>
        <S.Button
          btStyle="secondary"
          whileHover={{ scale: 1.1 }}
          onClick={() => history.push("/")}
        >
          {getTranslation("buttons", "goHome")}
        </S.Button>
      </S.FooterActions>
    );
  }, [getTranslation, history]);

  const fetchAppointment = useCallback(() => {
    if (!appointmentId) return;
    dispatch(AppointmentActions.request(appointmentId));
  }, [dispatch, appointmentId]);

  useEffect(() => {
    fetchAppointment();
  }, [fetchAppointment]);

  return (
    <S.PageContainer>
      <Header />
      <S.ContentConstrainer>
        <AppointmentComponent />
        <FooterActions />
      </S.ContentConstrainer>
      <Footer />
    </S.PageContainer>
  );
};
