import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { v4 as uuidV4 } from "uuid";

import { MobileLanguageSelector } from "../LanguageSelector";
import * as S from "./styles";

interface ILinks {
  id: string;
  name: string;
  route: string;
  external?: boolean;
  type: string;
}

const links: Array<ILinks> = [
  {
    id: uuidV4(),
    name: "home",
    route: "/",
    type: "mobile",
  },
  {
    id: uuidV4(),
    name: "menu.booknow",
    route: "https://api.whatsapp.com/send?phone=+17863541690",
    external: true,
    type: "mobile",
  },
  {
    id: uuidV4(),
    name: "menu.studio",
    route: "/studio",
    type: "web",
  },
  {
    id: uuidV4(),
    name: "menu.services",
    route: "/home/Services",
    type: "web",
  },
  {
    id: uuidV4(),
    name: "menu.work",
    route: "/home/OurWork",
    type: "web",
  },
  {
    id: uuidV4(),
    name: "menu.contact",
    route: "/contact",
    type: "web",
  },
  // {
  //   id: uuidV4(),
  //   name: "menu.reservation",
  //   route: "/reservations",
  //   type: "web",
  // },
];

const Menu: React.FC = () => {
  const history = useHistory();
  const [open, setOpen] = useState(false);

  const handleNavigation = useCallback(
    (link): void => {
      if (link.external) {
        window.open(link.route, "_blank");
      } else {
        history.push(link.route);
      }
      setOpen(false);
    },
    [history]
  );

  return (
    <>
      <S.Container>
        {links
          .filter((link) => link.type === "web")
          .map((link, key) => (
            <S.Link key={key} onClick={() => handleNavigation(link)}>
              <FormattedMessage id={link.name} />
            </S.Link>
          ))}
      </S.Container>
      <S.ButtonMobile onClick={() => setOpen(true)}>
        <S.IconMenu />
      </S.ButtonMobile>
      <S.Overlay open={open}>
        <S.CloseButton onClick={() => setOpen(false)}>
          <S.CloseIcon />
        </S.CloseButton>
        <S.Logo />
        <S.WrapperMobileLinks>
          <>
            {links.map((link, key) => (
              <S.Link key={key} onClick={() => handleNavigation(link)} mobile>
                <FormattedMessage id={link.name} />
              </S.Link>
            ))}
          </>
          <MobileLanguageSelector />
        </S.WrapperMobileLinks>
      </S.Overlay>
    </>
  );
};

export default Menu;
