/* eslint-disable object-curly-newline */
/* eslint-disable no-undef */
import React, { useCallback, useEffect, useRef } from "react";
import { useField } from "@unform/core";

import * as S from "./styles";

interface Props {
  type?: string;
  name: string;
  label?: string;
}

type InputProps = JSX.IntrinsicElements["input"] & Props;

export const Input: React.FC<InputProps> = ({
  type = "text",
  name,
  label,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const { fieldName, defaultValue, registerField, error } = useField(name);

  const Label = useCallback(() => {
    if (!label) return <></>;
    return <S.FieldLabel htmlFor={fieldName}>{label}</S.FieldLabel>;
  }, [label, fieldName]);

  const Error = useCallback(() => {
    if (!error) return <></>;
    return <S.FieldError>{error}</S.FieldError>;
  }, [error]);

  useEffect(() => {
    registerField({
      name: fieldName,
      path: "value",
      ref: inputRef.current,
    });
  }, [fieldName, registerField]);

  return (
    <S.FieldContainer>
      <Label />
      <input
        type={type}
        name={fieldName}
        id={fieldName}
        ref={inputRef}
        defaultValue={defaultValue}
        {...rest}
      />
      <Error />
    </S.FieldContainer>
  );
};
