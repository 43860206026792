import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 600px) {
    display: none;
  }
  color: #000;
`;

export const MobileContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: 32px;
  color: #fff;
`;

export const Link = styled.button.attrs({ type: "button" })<{
  active?: boolean;
}>`
  font-size: 14px;
  text-transform: uppercase;
  color: ${({ active }) => (active ? "#de0048" : "inherit")};
  margin-right: 24px;
  transition: 300ms ease;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    color: #de0048;
  }
`;
