import React, { useCallback, useEffect, useRef } from "react";
import { useField } from "@unform/core";
import * as S from "./styles";

interface Props {
  name: string;
  label?: string;
}

type InputProps = JSX.IntrinsicElements["textarea"] & Props;

export const Textarea: React.FC<InputProps> = ({ name, label, ...rest }) => {
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const { fieldName, defaultValue, registerField, error } = useField(name);

  const Label = useCallback(() => {
    if (!label) return <></>;
    return <S.FieldLabel htmlFor={fieldName}>{label}</S.FieldLabel>;
  }, [label, fieldName]);

  const Error = useCallback(() => {
    if (!error) return <></>;
    return <S.FieldError>{error}</S.FieldError>;
  }, [error]);

  useEffect(() => {
    registerField({
      name: fieldName,
      path: "value",
      ref: inputRef.current,
    });
  }, [fieldName, registerField]);

  return (
    <S.FieldContainer>
      <Label />
      <textarea
        name={fieldName}
        id={fieldName}
        ref={inputRef}
        defaultValue={defaultValue}
        {...rest}
      />
      <Error />
    </S.FieldContainer>
  );
};
