export const translations = {
  statuses: {
    completed: {
      en: "Appointment completed",
      pt: "Consulta completa",
      es: "Cita completada",
    },
    canceled: {
      en: "Appointment canceled",
      pt: "Consulta cancelada",
      es: "Cita cancelada",
    },
    confirmed: {
      en: "Appointment confirmed",
      pt: "Consulta confirmada",
      es: "Cita confirmada",
    },
  },
  title: {
    en: "Appointment Details",
    pt: "Detalhes da Reserva",
    es: "Detalles de la Reserva",
  },
  empty: {
    title: {
      en: "No appointments to show",
      pt: "Não há reservas pare exibir",
      es: "No hay reservas para mostrar ",
    },
  },
  labels: {
    reservationDate: {
      en: "Reservation date:",
      pt: "Data da reserva:",
      es: "Fecha de reserva:",
    },
    reservationTime: {
      en: "Reservation time:",
      pt: "Hora da reserva:",
      es: "Hora de reserva:",
    },
    services: {
      en: "Services:",
      pt: "Serviços:",
      es: "Servicios:",
    },
    professionals: {
      en: "Professionals:",
      pt: "Profissionais:",
      es: "Profesionales:",
    },
    fee: {
      en: "Reservation fee",
      pt: "Taxa de reserva",
      es: "Tarifa de reserva",
    },
    confirmed: {
      en: "Confirmed",
      pt: "Confirmado",
      es: "Confirmado",
    },
  },
};
