export const translations = {
  fields: {
    email: {
      pt: "E-mail:",
      en: "Email:",
      es: "Email:",
    },
    phone: {
      pt: "Telefone (completo com o código do país):",
      en: "Phone (complete with country code):",
      es: "Teléfono (completo con código de país):",
    },
    password: {
      pt: "Senha:",
      en: "Password:",
      es: "Contraseña:",
    },
    newPassword: {
      pt: "Nova senha:",
      en: "New password:",
      es: "Nueva contraseña:",
    },
    confirmPassword: {
      pt: "Confirmar senha:",
      en: "Confirm password:",
      es: "Confirmar la contraseña:",
    },
    firstName: {
      pt: "Nome:",
      en: "First name:",
      es: "Nombre:",
    },
    lastName: {
      pt: "Sobrenome:",
      en: "Last name:",
      es: "Apellido:",
    },
    passwordRecoveryCode: {
      pt: "Código de recuperação de senha:",
      en: "Password recovery code:",
      es: "Código de recuperación de contraseña",
    },
  },
  buttons: {
    back: {
      pt: "Voltar",
      en: "Back",
      es: "Vuelve",
    },
    submit: {
      pt: "Enviar",
      en: "Submit",
      es: "Enviar",
    },
    createAccount: {
      pt: "Criar conta",
      en: "Create account",
      es: "Crear una cuenta",
    },
    forgotPassword: {
      pt: "Esqueceu sua senha?",
      en: "Forgot you password?",
      es: "¿Olvidaste tu contraseña?",
    },
  },
  errors: {
    firstName: {
      required: {
        pt: "Nome obrigatório",
        en: "First name is required",
        es: "Nombre necesario",
      },
    },
    lastName: {
      required: {
        pt: "Sobrenome obrigatório",
        en: "Last name is required",
        es: "Apellido necesario",
      },
    },
    phone: {
      required: {
        pt: "Telefone obrigatório",
        en: "Phone number is required",
        es: "Teléfono necesario",
      },
    },
    email: {
      invalid: {
        pt: "E-mail inválido",
        en: "Invalid email",
        es: "Email inválido",
      },
      required: {
        pt: "E-mail obrigatório",
        en: "Email is required",
        es: "Email necesario",
      },
    },
    password: {
      required: {
        pt: "Senha obrigatória",
        en: "Password is required",
        es: "Contraseña necesaria",
      },
    },
    confirmPassword: {
      matches: {
        pt: "Revise as senhas",
        en: "Review the passwords",
        es: "Revise las contraseñas",
      },
    },
    passwordRecoveryCode: {
      required: {
        en: "Enter your password recovery code",
        pt: "Informe seu código de recuperação de senha",
        es: "Ingrese su código de recuperación de contraseña",
      },
    },
  },
};
