import React, { useCallback } from "react";
import { format } from "date-fns";

import { useTranslation } from "hooks";
import { Appointment } from "../interfaces";
import { translations } from "./translations";
import * as S from "./styles";

interface SidebarNextAppointmentsProps extends React.ComponentProps<any> {
  currentId: number | null;
  appointments: Appointment[];
  isBusy: boolean;
  onSelect(appointmentId: number): void;
}
type Props = SidebarNextAppointmentsProps;

export const SidebarNextAppointments: React.FC<Props> = ({
  currentId,
  appointments,
  isBusy,
  onSelect,
}) => {
  const { getTranslation } = useTranslation(translations);

  const EmptyListComponent: React.FC = useCallback(() => {
    if (appointments.length || isBusy) return <></>;
    return (
      <S.ListItem>
        <S.ListItemConstrainer>
          <S.IconColumn>
            <S.EmptyIcon />
          </S.IconColumn>
          <S.MainColumn>
            <S.DateValue>{getTranslation("empty")}</S.DateValue>
          </S.MainColumn>
        </S.ListItemConstrainer>
      </S.ListItem>
    );
  }, [appointments, isBusy, getTranslation]);

  const listItembuilder = useCallback(
    ({ id, canceled_at, starts_at, services }: Appointment, key: number) => {
      return (
        <S.ListItem key={key} active={id === currentId}>
          <S.ListItemConstrainer>
            <S.IconColumn>
              <S.CalendarIcon />
            </S.IconColumn>
            <S.MainColumn>
              <S.DateValue>
                {format(new Date(starts_at), "MM/dd/yyyy 'at' hh:mm aaa")}
                {canceled_at && ` (${getTranslation("canceled")})`}
              </S.DateValue>
              <S.ServicesValue>{services.join(", ")}</S.ServicesValue>
            </S.MainColumn>
            <S.DetailsButton onClick={() => onSelect(id)}>
              {getTranslation("buttons", "details")}
              <S.ChevronRightIcon />
            </S.DetailsButton>
          </S.ListItemConstrainer>
        </S.ListItem>
      );
    },
    [getTranslation, onSelect, currentId]
  );

  return (
    <S.MainContainer>
      <S.Header>
        <S.Title>{getTranslation("title")}</S.Title>
      </S.Header>
      <EmptyListComponent />
      {appointments.map((i, key) => listItembuilder(i, key))}
    </S.MainContainer>
  );
};
