import React from "react";
import Slider from "react-slick";
import { v4 as uuidV4 } from "uuid";
import { FormattedMessage } from "react-intl";

import { useTranslation } from "hooks";
import { translations } from "./translations";

import * as S from "./styles";

interface IService {
  id: string;
  price: string;
  name: string;
  text: string;
  image: object;
}

const Services: React.FC = () => {
  const { getTranslation } = useTranslation(translations);

  const settings = {
    slidesToShow: 1,
    speed: 500,
    adaptiveHeight: true,
    dots: true,
  };

  const services: Array<IService> = [
    {
      id: uuidV4(),
      price: "$40-$300",
      name: getTranslation("hairstyle", "name"),
      text: getTranslation("hairstyle", "description"),
      image: <S.ServiceHairstyle />,
    },
    {
      id: uuidV4(),
      price: "$20-$75",
      name: getTranslation("nails", "name"),
      text: getTranslation("nails", "description"),
      image: <S.ServiceNails />,
    },
    {
      id: uuidV4(),
      price: "$15-$125",
      name: getTranslation("eyeBrowsLashes", "name"),
      text: getTranslation("eyeBrowsLashes", "description"),
      image: <S.ServiceEyebrows />,
    },
    {
      id: uuidV4(),
      price: "$65-$270",
      name: getTranslation("face", "name"),
      text: getTranslation("face", "description"),
      image: <S.ServiceFace />,
    },
    {
      id: uuidV4(),
      price: "$10-$50",
      name: getTranslation("wax", "name"),
      text: getTranslation("wax", "description"),
      image: <S.ServiceWax />,
    },
    {
      id: uuidV4(),
      price: "$15-$110",
      name: getTranslation("menServices", "name"),
      text: getTranslation("menServices", "description"),
      image: <S.ServiceMan />,
    },
    {
      id: uuidV4(),
      price: "$100-$120",
      name: getTranslation("Massage", "name"),
      text: getTranslation("Massage", "description"),
      image: <S.ServiceMassage />,
    },
  ];
  return (
    <S.Container>
      <S.Content>
        <S.Subtitle>
          <FormattedMessage id="home.services.subtitle" />
        </S.Subtitle>
        <S.Title>
          <FormattedMessage id="home.services.title" />
        </S.Title>
        <S.Wrapper>
          {services.map((service) => (
            <S.Box key={service.id}>
              <S.BoxHeader>
                {service.name}
                <svg height="12" width="40">
                  <polygon points="0,0 40,0 20,12" className="triangle" />
                </svg>
              </S.BoxHeader>
              {service.image}
              <S.BoxContent>
                {/* <S.Price>{service.price}</S.Price> */}
                <S.Description>{service.text}</S.Description>
              </S.BoxContent>
            </S.Box>
          ))}
        </S.Wrapper>
        <S.MobileWrapper>
          <Slider {...settings}>
            {services.map((service) => (
              <S.Box key={service.id}>
                <S.BoxHeader>
                  {service.name}
                  <svg height="12" width="40">
                    <polygon points="0,0 40,0 20,12" className="triangle" />
                  </svg>
                </S.BoxHeader>
                {service.image}
                <S.BoxContent>
                  {/* <S.Price>{service.price}</S.Price> */}
                  <S.Description>{service.text}</S.Description>
                </S.BoxContent>
              </S.Box>
            ))}
          </Slider>
        </S.MobileWrapper>
      </S.Content>
    </S.Container>
  );
};

export default Services;
