import React from "react";
import { FormattedMessage } from "react-intl";

import * as S from "./styles";

const Whatsapp: React.FC = () => {
  return (
    <S.Container href="https://wa.me/7863541690">
      <span>
        <FormattedMessage id="whatsapp" />
      </span>
      <S.IconWhatsapp />
    </S.Container>
  );
};

export default Whatsapp;
