import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";

import { useTranslation } from "hooks";
import { AuthState } from "store/ducks/auth";
import {
  PaginateAppointmentsActions,
  PaginateAppointmentsState,
  NextAppointmentsActions,
  NextAppointmentsState,
  AppointmentActions,
  AppointmentState,
} from "store/ducks/appointment";
import { Header, Footer, Paginator } from "components/shared";
import { LoginOrCreateAccount } from "components/Auth";
import {
  AppointmentView,
  SidebarNextAppointments,
  SidebarPreviousAppointments,
} from "components/Appointments";
import { translations } from "./translations";
import * as S from "./styles";

interface ReservationsPageProps extends React.ComponentProps<any> {}
type Props = ReservationsPageProps;

export const MyAppointmentsPage: React.FC<Props> = () => {
  const dispatch = useDispatch();

  const { getTranslation } = useTranslation(translations);

  const [appointmentsQuery, setAppointmentsQuery] = useState({
    limit: 5,
    page: 1,
    order: "recent",
    filter: "past",
  });

  const [appointmentId, setAppointmentId] = useState<number | null>(null);

  const { loggedIn } = useSelector<RootStateOrAny>(
    (state) => state.auth
  ) as AuthState;

  const {
    data: previousAppointments,
    loading: loadingPreviousAppointments,
    pagination,
  } = useSelector<RootStateOrAny>(
    (state) => state.paginateAppointments
  ) as PaginateAppointmentsState;

  const {
    data: nextAppointments,
    loading: loadingNextAppointments,
  } = useSelector<RootStateOrAny>(
    (state) => state.nextAppointments
  ) as NextAppointmentsState;

  const {
    data: appointment,
    loading: loadingAppointment,
  } = useSelector<RootStateOrAny>(
    (state) => state.appointment
  ) as AppointmentState;

  const AuthComponent: React.FC = useCallback(() => {
    return (
      <S.AuthContainer>
        <S.AuthContentConstrainer>
          <S.AuthHeader>
            <h4>{getTranslation("auth", "subtitle")}</h4>
            <h1>{getTranslation("auth", "title")}</h1>
            <p>{getTranslation("auth", "subtitle2")}</p>
          </S.AuthHeader>
          <LoginOrCreateAccount
            loginHeader={
              <S.AuthSubheader>
                {getTranslation("auth", "login")}
              </S.AuthSubheader>
            }
            createAccountHeader={
              <S.AuthSubheader>
                {getTranslation("auth", "account")}
              </S.AuthSubheader>
            }
            generateRecoveryCodeHeader={
              <S.AuthSubheader>
                {getTranslation("auth", "generateCode")}
              </S.AuthSubheader>
            }
            updatePasswordHeader={
              <S.AuthSubheader>
                {getTranslation("auth", "updatePassword")}
              </S.AuthSubheader>
            }
          />
        </S.AuthContentConstrainer>
      </S.AuthContainer>
    );
  }, [getTranslation]);

  const handleAppointmentOnSelect = useCallback(
    (id: number) => {
      setAppointmentId(id);
    },
    [setAppointmentId]
  );

  const handlePaginatorOnPageChange = useCallback(
    (page: number) => {
      setAppointmentsQuery((state) => ({ ...state, page }));
    },
    [setAppointmentsQuery]
  );

  const fetchNextAppointments = useCallback(() => {
    if (!loggedIn) return;
    dispatch(NextAppointmentsActions.request());
  }, [dispatch, loggedIn]);

  const onLoadNextAppointments = useCallback((): void => {
    if (nextAppointments.length > 0) {
      setAppointmentId(nextAppointments[0]?.id);
    }
  }, [nextAppointments, setAppointmentId]);

  const fetchPreviousAppointments = useCallback((): void => {
    if (!loggedIn) return;
    dispatch(PaginateAppointmentsActions.request(appointmentsQuery));
  }, [dispatch, loggedIn, appointmentsQuery]);

  const fetchAppointment = useCallback((): void => {
    if (!appointmentId) return;
    dispatch(AppointmentActions.request(appointmentId));
  }, [dispatch, appointmentId]);

  const onAppointmentLoaded = useCallback((): void => {
    if (!appointment) return;
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [appointment]);

  useEffect(() => {
    fetchNextAppointments();
  }, [fetchNextAppointments]);

  useEffect(() => {
    onLoadNextAppointments();
  }, [onLoadNextAppointments]);

  useEffect(() => {
    fetchPreviousAppointments();
  }, [fetchPreviousAppointments]);

  useEffect(() => {
    fetchAppointment();
  }, [fetchAppointment]);

  useEffect(() => {
    onAppointmentLoaded();
  }, [onAppointmentLoaded]);

  useEffect(() => {
    return () => {
      dispatch(NextAppointmentsActions.reset());
      dispatch(PaginateAppointmentsActions.reset());
      dispatch(AppointmentActions.reset());
    };
  }, [dispatch]);

  return (
    <S.PageContainer>
      <Header />
      {!loggedIn && <AuthComponent />}

      {loggedIn && (
        <S.ContentContainer>
          <S.Sidebar>
            <S.LinksContainer>
              <S.Links>
                <S.Link to="/my-account">
                  {getTranslation("links", "myAccount")}
                </S.Link>
              </S.Links>
            </S.LinksContainer>
            <SidebarNextAppointments
              currentId={appointmentId}
              appointments={nextAppointments}
              isBusy={loadingNextAppointments}
              onSelect={handleAppointmentOnSelect}
            />
            <SidebarPreviousAppointments
              currentId={appointmentId}
              appointments={previousAppointments}
              isBusy={loadingPreviousAppointments}
              onSelect={handleAppointmentOnSelect}
            />
            <S.PaginatorContainer>
              <Paginator
                pagination={pagination}
                onPageChange={handlePaginatorOnPageChange}
              />
            </S.PaginatorContainer>
          </S.Sidebar>
          <S.MainContainer>
            <AppointmentView
              appointment={appointment}
              isBusy={loadingAppointment}
            />
          </S.MainContainer>
        </S.ContentContainer>
      )}
      <Footer />
    </S.PageContainer>
  );
};
