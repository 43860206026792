import { createReducer, createActions } from "reduxsauce";
import update from "immutability-helper";

interface Pagination {
  total: number;
  perPage: number;
  page: number;
  lastPage: number;
}

export interface PaginateAppointmentsState {
  data: any[];
  pagination: Pagination | null;
  loading: boolean;
  error: string | null;
}

export interface PaginateAppointmentsRequest {
  query: Record<string, any>;
  onSuccess?(): void;
  onFailure?(): void;
}

interface SuccessAction {
  data: any[];
  pagination: Pagination;
}

interface FailureAction {
  error: string;
}

const { Types, Creators } = createActions(
  {
    request: ["query", "onSuccess", "onFailure"],
    success: ["data", "pagination"],
    failure: ["error"],
    reset: [],
  },
  { prefix: "LAST_APPOINTMENTS_" }
);

const INITIAL_STATE: PaginateAppointmentsState = {
  data: [],
  pagination: null,
  loading: false,
  error: null,
};

const request = (
  state: PaginateAppointmentsState,
  action: PaginateAppointmentsRequest
) =>
  update(state, {
    loading: { $set: true },
    error: { $set: null },
  });

const success = (state: PaginateAppointmentsState, action: SuccessAction) =>
  update(state, {
    data: { $set: action.data },
    pagination: { $set: action.pagination },
    loading: { $set: false },
  });

const failure = (state: PaginateAppointmentsState, action: FailureAction) =>
  update(state, {
    loading: { $set: false },
    error: { $set: action.error },
  });

const reset = () => INITIAL_STATE;

export const paginateAppointments = createReducer(INITIAL_STATE, {
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success,
  [Types.FAILURE]: failure,
  [Types.RESET]: reset,
});

export const PaginateAppointmentsTypes = Types;
export const PaginateAppointmentsActions = Creators;
