type HandledErrorContract = {
  errorMessage: string;
};

export const requestErrorHandler = (
  error: any | Error
): HandledErrorContract => {
  if (error?.response?.data?.error) {
    const { message: errorMessage = "Couldn't complete your request" } =
      error?.response?.data?.error;
    return { errorMessage };
  }

  if (error instanceof Error) {
    return { errorMessage: error.message };
  }

  return { errorMessage: "Couldn't complete your request" };
};
