import styled from "styled-components";

export { Button, PageContainer, Loading } from "styles/components";

export const MainContainer = styled.div`
  width: 100%;
  max-width: 600px;
  padding: 64px 32px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  form {
    width: 100%;
  }

  @media screen and (max-width: 414px) {
    padding-top: 48px;
    padding-bottom: 48px;
  }
`;
export const Title = styled.h1`
  font-size: 36px;
  line-height: 40px;
  color: #1a1a1a;
  margin-bottom: 24px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 560px;

  @media screen and (max-width: 414px) {
    font-size: 30px;
    line-height: 30px;
  }
`;

export const Description = styled.article`
  text-align: center;
  font-size: 18px;
  line-height: 24px;
  color: #1a1a1a;
  width: 100%;
  max-width: 310px;
  margin-bottom: 32px;
`;

export const FormSection = styled.h1`
  padding: 24px 0;
  font-size: 16px;
  color: #1a1a1a;
`;

export const ActionButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 56px;
`;
