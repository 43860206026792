export const translations = {
  title: {
    en: "My Account",
    pt: "Minha Conta",
    es: "Mi Cuenta",
  },
  description: {
    en: "Update your account details",
    pt: "Atualize os detalhes de sua conta",
    es: "Actualice los detalles de su cuenta",
  },
  changePassword: {
    en: "Change your password",
    pt: "Alteração de senha",
    es: "Cambia tu contraseña",
  },
  fields: {
    phone: {
      pt: "Telefone (completo com o código do país):",
      en: "Phone (complete with country code):",
      es: "Teléfono (completo con código de país):",
    },
    password: {
      pt: "Senha:",
      en: "Password:",
      es: "Contraseña:",
    },
    confirmPassword: {
      pt: "Confirmar senha:",
      en: "Confirm password:",
      es: "Confirmar la contraseña:",
    },
    firstName: {
      pt: "Nome:",
      en: "First name:",
      es: "Nombre:",
    },
    lastName: {
      pt: "Sobrenome:",
      en: "Last name:",
      es: "Apellido:",
    },
  },
  buttons: {
    submit: {
      en: "Save",
      pt: "Salvar",
      es: "Guardar",
    },
  },
  errors: {
    firstName: {
      required: {
        pt: "Nome obrigatório",
        en: "First name is required",
        es: "Nombre necesario",
      },
    },
    lastName: {
      required: {
        pt: "Sobrenome obrigatório",
        en: "Last name is required",
        es: "Apellido necesario",
      },
    },
    phone: {
      required: {
        pt: "Telefone obrigatório",
        en: "Phone number is required",
        es: "Teléfono necesario",
      },
    },
    password: {
      required: {
        pt: "Senha obrigatória",
        en: "Password is required",
        es: "Contraseña necesaria",
      },
    },
    confirmPassword: {
      matches: {
        pt: "Revise as senhas",
        en: "Review the passwords",
        es: "Revise las contraseñas",
      },
    },
  },
};
