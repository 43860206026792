import React, { useEffect, useCallback, useState } from "react";
import Slider from "react-slick";
import { v4 as uuidV4 } from "uuid";
import { FormattedMessage } from "react-intl";

import * as S from "./styles";

interface IPhoto {
  id: string;
  image: string;
}

const OurWork: React.FC = () => {
  const [photos, setPhotos] = useState<Array<IPhoto> | null>(null);
  const settings = {
    className: "center",
    centerMode: true,
    slidesToShow: 3,
    speed: 500,
    adaptiveHeight: true,
    nextArrow: (
      <S.NextButton>
        <S.IconNext />
      </S.NextButton>
    ),
    prevArrow: (
      <S.PrevButton>
        <S.IconPrev />
      </S.PrevButton>
    ),
    responsive: [
      {
        breakpoint: 1001,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const getPhotos = useCallback(() => {
    const gallerylength = 46;
    const galleryArray = [];

    const baseUrl =
      process?.env?.NODE_ENV === "development"
        ? "http://localhost:3000/2021"
        : "https://bnstudiosalon.com/2021";

    for (let index = 1; index <= gallerylength; index++) {
      galleryArray.push({
        id: uuidV4(),
        image: `${baseUrl}/gallery/works-home/${index}.jpg`,
      });
    }

    setPhotos(galleryArray);
  }, []);

  useEffect(() => {
    getPhotos();
  }, [getPhotos]);
  return (
    <S.Container>
      <S.Content>
        <S.Subtitle>
          <FormattedMessage id="home.ourWork.subtitle" />
        </S.Subtitle>
        <S.Title>
          <FormattedMessage id="home.ourWork.title" />
        </S.Title>
        <S.SliderWrapper>
          {photos && (
            <Slider {...settings}>
              {photos.map((photo) => (
                <S.Photo key={photo.id}>
                  <img src={photo.image} alt="our work" />
                </S.Photo>
              ))}
            </Slider>
          )}
        </S.SliderWrapper>
      </S.Content>
    </S.Container>
  );
};

export default OurWork;
