import styled, { css } from "styled-components";
import { MenuAltRight } from "@styled-icons/boxicons-regular";
import { Close } from "@styled-icons/evaicons-solid";
import logo from "assets/images/logo-white.png";

export const Container = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 1060px) {
    display: none;
  }
`;

export const Link = styled.button.attrs({ type: "button" })<{
  mobile?: boolean;
}>`
  font-size: 14px;
  text-transform: uppercase;
  color: #000;
  margin-right: 32px;
  transition: 300ms ease;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    color: #de0048;
  }

  ${({ mobile }) =>
    mobile &&
    css`
      margin-right: 0;
      padding: 16px 0;
      color: white;
    `}
`;

export const ButtonMobile = styled.button.attrs({ type: "button" })`
  display: none;

  @media screen and (max-width: 1060px) {
    display: flex;
  }
`;

export const IconMenu = styled(MenuAltRight).attrs({ size: 32 })`
  color: #000;
`;

export const Overlay = styled.div<{ open: Boolean }>`
  position: fixed;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: ${({ open }) => (open ? "flex" : "none")};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px;
  background-color: #1a1a1a;
  z-index: 1000;
  top: 0;
  left: 0;
  overflow: auto;
`;

export const CloseIcon = styled(Close).attrs({ size: 32 })`
  color: white;
  position: absolute;
  top: 32px;
  right: 32px;
`;

export const CloseButton = styled.button.attrs({ type: "button" })`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Logo = styled.img.attrs({ src: logo })`
  width: 100%;
  height: auto;
  max-height: max-content;
  max-width: max-content;
  margin: 16px 0;
`;

export const WrapperMobileLinks = styled.div`
  border-top: 1px #ffffff80 solid;
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
`;
