export const translations = {
  title: {
    en: "Previous Appointments",
    pt: "Consultas passadas",
    es: "Citas pasadas",
  },
  empty: {
    en: "You have no previous appointments.",
    pt: "Você não tem consultas passadas.",
    es: "No tienes citas previas.",
  },
  buttons: {
    details: {
      en: "Details",
      pt: "Detalhes",
      es: "Detalles",
    },
  },
  canceled: {
    en: "Canceled",
    pt: "Cancelada",
    es: "Cancelada",
  },
};
