import { call, put, all } from "redux-saga/effects";

import api from "services/api";
import { notify } from "services/notification";
import { requestErrorHandler } from "utils";
import { AuthActions, LoginPostData } from "store/ducks/auth";
import {
  CreateAccountActions,
  CreateAccountRequest,
} from "store/ducks/account";

export function* createAccountRequest(action: any) {
  try {
    const { postData, onSuccess } = action as CreateAccountRequest;

    yield call(api.post, "client", postData);
    if (onSuccess) onSuccess();

    const loginPostData: LoginPostData = {
      phone: postData.phone,
      password: postData.password,
    };

    yield all([
      put(CreateAccountActions.success()),
      put(AuthActions.loginRequest(loginPostData)),
    ]);
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(CreateAccountActions.failure(errorMessage));
  }
}
