export const translations = {
  hairstyle: {
    name: {
      pt: "Penteado",
      en: "Hairstyle",
      es: "Peinada",
    },
    description: {
      pt: `A cor que você quer está aqui no BN Studio Hair Salon & Spa:

Blonde
Highlights
Balayage
Platinum
Lighted Brunette
Root dying

Também oferecemos os serviços de tratamentos capilares, blow-dry, baby-liss, corte de cabelo, coloração, pintura e apliques`,
      en: `The color you want is here at BN Studio Hair Salon & Spa:

Blonde
Highlights
Balayage
Platinum
Lighted Brunette
Root dying

We also offer the services of hair treatments, blow-dry, baby-liss, hair cut, color, toner and extensions.`,
      es: `Aquel color que deseas está aquí en BN Studio Hair Salon & SPA:

Rubio
Luces
Balayage
Platinado
Morena Iluminada
Tintura de raíz

También ofrecemos los servicios de tratamientos capilares, cepillé el pelo, baby-liss, corte de pelo, color, tinte y extensiones.`,
    },
  },
  nails: {
    name: {
      pt: "Unhas",
      en: "Nails",
      es: "Clavos",
    },
    description: {
      pt: `Serviços de manicure e pedicure com profissionais qualificados:

Manicure/ pedicure regular                     
Shellac gel manicure        
Shellac gel pedicure
Gel bath                   
Dip nails       
Dip + extensions
Acrylic
Polish change`,
      en: `Manicure and pedicure service with qualified professionals:

Manicure/ pedicure regular                     
Shellac gel manicure        
Shellac gel pedicure
Gel bath                   
Dip nails       
Dip + extensions
Acrylic
Polish change`,
      es: `Servicio de manicura y pedicura con profesionales cualificados:

Manicure/ pedicure regular                     
Shellac gel manicure        
Shellac gel pedicure
Gel bath                   
Dip nails       
Dip + extensions
Acrylic
Polish change`,
    },
  },
  eyeBrowsLashes: {
    name: {
      pt: "Sonbrancelhas e Cílios",
      en: "Eyebrows & Lashes",
      es: "Cejas y Pestañas",
    },
    description: {
      pt: `Tenha um visual chamativo!

Eyebrow design
Henna
Eyebrow design + tint
Eyebrow wax
Eyelashes
Classic
2d/5d 
Hybrid`,
      en: `Have a striking look!

Eyebrow design
Henna
Eyebrow design + tint
Eyebrow wax
Eyelashes
Classic
2d/5d 
Hybrid`,
      es: `¡Que tenga un aspecto llamativo!

Eyebrow design
Henna
Eyebrow design + tint
Eyebrow wax
Eyelashes
Classic
2d/5d 
Hybrid`,
    },
  },
  face: {
    name: {
      pt: "Rosto",
      en: "Face",
      es: "Rostro",
    },
    description: {
      pt: `Para uma pele linda e saudável temos os serviços:

Facial simple and complete
BBglow
Facial back
Derma V
botox + vitamines + minerals + peptides`,
      en: `For a beautiful and healthy skin we have the services:

Facial simple and complete
BBglow
Facial back
Derma V
botox + vitamines + minerals + peptides`,
      es: `Para una piel bonita y sana tenemos los servicios:

Facial simple and complete
BBglow
Facial back
Derma V
botox + vitamines + minerals + peptides`,
    },
  },
  wax: {
    name: {
      pt: "Cera",
      en: "Wax",
      es: "Cera",
    },
    description: {
      pt: `Livre-se dos pelos indesejados:

Brazilian bikini complete
Half leg
Full leg
Half thighs
Armpit
Fuzz`,
      en: `Get rid of unwanted hair:

Brazilian bikini complete
Half leg
Full leg
Half thighs
Armpit
Fuzz`,
      es: `Deshacerse del vello no deseado:

Brazilian bikini complete
Half leg
Full leg
Half thighs
Armpit
Fuzz`,
    },
  },
  menServices: {
    name: {
      pt: "Serviços masculinos",
      en: "Men Services",
      es: "Servicios para hombres",
    },
    description: {
      pt: `Homens são bem-vindos ao BN Studio. Confira os serviços:

haircut
beard clean up
full beard trim/shape
wax - ears / nose
manicure
pedicure
body massage`,
      en: `Men are welcome at BN Studio. Check out the services:

haircut
beard clean up
full beard trim/shape
wax - ears / nose
manicure
pedicure
body massage`,
      es: `Los hombres son bienvenidos en BN Studio. Consulta los servicios:

haircut
beard clean up
full beard trim/shape
wax - ears / nose
manicure
pedicure
body massage`,
    },
  },
  Massage: {
    name: {
      pt: "Massagem",
      en: "Massage",
      es: "Masaje",
    },
    description: {
      pt: `Temos os melhores equipamentos para massagens relaxantes e modeladoras:

Detox
Regenerative
Pregnancy Massage
Massagem Deals
Tripolar
Vaccum Cupping
Ionizing Machine`,
      en: `We have the best equipment and techniques for relaxing and modeling massages:

Detox
Regenerative
Pregnancy Massage
Massagem Deals
Tripolar
Vaccum Cupping
Ionizing Machine`,
      es: `Disponemos de los mejores equipos y técnicas para realizar masajes relajantes y modeladores:

Detox
Regenerative
Pregnancy Massage
Massagem Deals
Tripolar
Vaccum Cupping
Ionizing Machine`,
    },
  },
};
