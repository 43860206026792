export const translation = {
  header: {
    title: {
      line1: {
        pt: "Faça sua",
        en: "Make your",
        es: "Haz tu",
      },
      line2: {
        pt: "reserva",
        en: "appointment",
        es: "reserva",
      },
    },
    reservationFee: {
      pt: "Taxa de reserva",
      en: "Reservation fee",
      es: "Tarifa de reserva",
    },
  },
  body: {
    title: {
      pt: "Horário de funcionamento",
      en: "Opening hours",
      es: "Horas de trabajo",
    },
    column1: {
      label: {
        pt: "Terça à Sábado",
        en: "Tuesday to Saturday",
        es: "Martes a Sábado",
      },
      value: {
        pt: "Aberto de 9h às 19h",
        en: "Open from 9am to 7pm",
        es: "Abierto de 9h a 19h",
      },
    },
    column2: {
      label: {
        pt: "Domingos e Segundas",
        en: "Sundays and Mondays",
        es: "Domingos y Lunes",
      },
      value: {
        pt: "Fechado",
        en: "Closed",
        es: "Cerrado",
      },
    },
  },
};
