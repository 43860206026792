import React from "react";
import { useSelector } from "react-redux";
import { IntlProvider } from "react-intl";
import { translation } from "./translattion";
import { LanguageState } from "store/ducks/language";

const LanguageProvider: React.FC = ({ children }) => {
  const { language } = useSelector(
    (state: any) => state.language
  ) as LanguageState;

  return (
    <IntlProvider
      locale={language}
      messages={translation[language]}
      defaultLocale="pt"
    >
      {children}
    </IntlProvider>
  );
};

export default LanguageProvider;
