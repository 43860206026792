export const translations = {
  login: {
    title: {
      pt: "Entre ou crie uma conta",
      en: "Sign in or create an account",
      es: "Inicia sesión o crea una cuenta",
    },
    subtitle: {
      pt: "Entre com seu e-mail e senha para continuar.",
      en: "Enter your email and password to continue.",
      es: "Ingrese su email y contraseña para continuar.",
    },
  },
  account: {
    title: {
      pt: "Crie sua conta",
      en: "Create your account",
      es: "Crea tu cuenta",
    },
    subtitle: {
      pt: "Preencha o formulário abaixo para criar sua conta.",
      en: "Fill up the form below to create your account.",
      es: "Complete el formulario a continuación para crear su cuenta.",
    },
  },
  generateCode: {
    title: {
      en: "Password recovery",
      pt: "Recuperação de senha",
      es: "Recuperación de contraseña",
    },
    subtitle: {
      en: "Enter the email you use to sign in",
      pt: "Insira o e-mail que você usa para logar",
      es: "Ingrese el correo electrónico que usa para iniciar sesión",
    },
  },
  updatePassword: {
    title: {
      en: "Use your code to recover your password",
      pt: "Use seu código para recuperar sua senha",
      es: "Use su código para recuperar su contraseña",
    },
    subtitle: {
      en: "Fill the form below to procede",
      pt: "Preencha o formulário abaixo para continuar",
      es: "Complete el siguiente formulario para continuar",
    },
  },
};
