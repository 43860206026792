import { createReducer, createActions } from "reduxsauce";
import update from "immutability-helper";

export interface GenerateRecoveryCodeRequestBody {
  email: string;
}
export interface GenerateRecoveryCodeRequest {
  postData: GenerateRecoveryCodeRequestBody;
  onSuccess?(): void;
}

export interface GenerateRecoveryCodeState {
  loading: boolean;
  error: string | null;
}

interface FailureAction {
  error: string;
}

const INITIAL_STATE: GenerateRecoveryCodeState = {
  loading: false,
  error: null,
};

const { Types, Creators } = createActions(
  {
    request: ["postData", "onSuccess"],
    success: [],
    failure: ["error"],
    reset: [],
  },
  { prefix: "GENERATE_RECOVERY_CODE_" }
);

const request = (state: GenerateRecoveryCodeState) =>
  update(state, {
    loading: { $set: true },
    error: { $set: null },
  });

const success = (state: GenerateRecoveryCodeState) =>
  update(state, {
    loading: { $set: false },
  });

const failure = (state: GenerateRecoveryCodeState, action: FailureAction) =>
  update(state, {
    error: { $set: action.error },
    loading: { $set: false },
  });

const reset = () => INITIAL_STATE;

export const generateRecoveryCode = createReducer(INITIAL_STATE, {
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success,
  [Types.FAILURE]: failure,
  [Types.RESET]: reset,
});

export const GenerateRecoveryCodeTypes = Types;
export const GenerateRecoveryCodeActions = Creators;
