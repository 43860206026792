import React, { useCallback } from "react";
import { format } from "date-fns";

import { useTranslation } from "hooks";
import { translations } from "./translation";
import * as S from "./styles";

interface AppointmentViewProps extends React.ComponentProps<any> {
  appointment: Record<string, any> | null;
  isBusy: boolean;
}
type Props = AppointmentViewProps;

function getStatus(appointment: any) {
  const { canceled_at, concluded_at } = appointment;
  switch (true) {
    case canceled_at !== null:
      return "canceled";
    case concluded_at !== null:
      return "completed";
    default:
      return "confirmed";
  }
}

export const AppointmentView: React.FC<Props> = ({ appointment, isBusy }) => {
  const { getTranslation } = useTranslation(translations);

  const LoadingComponent: React.FC = useCallback(() => {
    if (!isBusy) return <></>;
    return <S.Loading />;
  }, [isBusy]);

  const NoneSelectedComponent: React.FC = useCallback(() => {
    if (appointment || isBusy) return <></>;
    return (
      <S.Header>
        <S.HeaderStatus status="empty">
          <S.FailureIcon />
        </S.HeaderStatus>
        <S.HeaderTitle>
          <h1>{getTranslation("empty", "title")}</h1>
        </S.HeaderTitle>
      </S.Header>
    );
  }, [appointment, isBusy, getTranslation]);

  const AppointmentComponent: React.FC = useCallback(() => {
    if (!appointment || isBusy) return <></>;
    const { starts_at, appointment_services = [] } = appointment;

    const status = getStatus(appointment);

    const services: string[] = appointment_services.map(
      (i: any) => i?.service?.name
    );

    const professionals: string[] = appointment_services.map((i: any) => {
      const { first_name, last_name } = i?.team;
      return `${first_name} ${last_name}`.trim();
    });

    return (
      <>
        <S.Header>
          <S.HeaderStatus status={status}>
            {["completed", "confirmed"].includes(status) && <S.SuccessIcon />}
            {status === "canceled" && <S.FailureIcon />}
          </S.HeaderStatus>
          <S.HeaderTitle>
            <h4>{getTranslation("statuses", status)}</h4>
            <h1>{getTranslation("title")}</h1>
          </S.HeaderTitle>
        </S.Header>

        <S.Appointment>
          <S.Column>
            <S.ColumnLabel>
              {getTranslation("labels", "reservationDate")}
            </S.ColumnLabel>
            <S.DateColumnValue>
              {format(new Date(starts_at), "MM/dd/yyyy")}
            </S.DateColumnValue>
            <S.ColumnLabel>
              {getTranslation("labels", "reservationTime")}
            </S.ColumnLabel>
            <S.DateColumnValue>
              {format(new Date(starts_at), "hh:mm aaa")}
            </S.DateColumnValue>
          </S.Column>

          <S.Column>
            <S.ColumnLabel>
              {getTranslation("labels", "services")}
            </S.ColumnLabel>
            {services.map((i, key) => (
              <S.ColumnValue key={key}>{i}</S.ColumnValue>
            ))}
          </S.Column>

          <S.Column>
            <S.ColumnLabel>
              {getTranslation("labels", "professionals")}
            </S.ColumnLabel>
            {professionals.map((i, key) => (
              <S.ColumnValue key={key}>{i}</S.ColumnValue>
            ))}
          </S.Column>
        </S.Appointment>
      </>
    );
  }, [appointment, isBusy, getTranslation]);

  const PaymentComponent: React.FC = useCallback(() => {
    if (!appointment || isBusy) return <></>;
    const { total_amount, fee_amount } = appointment;

    return (
      <S.Payment>
        <S.TotalAmount>US$ {total_amount}</S.TotalAmount>
        <S.FeeAmountLabel>
          {getTranslation("labels", "fee")} (20%):{" "}
          <S.FeeAmountValue>
            US$ {fee_amount} ({getTranslation("labels", "confirmed")})
          </S.FeeAmountValue>
        </S.FeeAmountLabel>
      </S.Payment>
    );
  }, [appointment, isBusy, getTranslation]);

  return (
    <S.MainContainer>
      <LoadingComponent />
      <NoneSelectedComponent />
      <AppointmentComponent />
      <PaymentComponent />
    </S.MainContainer>
  );
};
