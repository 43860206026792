import { call, put } from "redux-saga/effects";

import api from "services/api";
import { notify } from "services/notification";
import { requestErrorHandler } from "utils";
import { AuthActions, AuthState, LoginRequest } from "../../ducks/auth";

export function rehydrateAccessToken({ payload }: any) {
  if (!payload?.auth?.data) return;
  const { token } = payload.auth as AuthState;
  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  }
}

export function* loginRequest(action: any) {
  try {
    const { postData, onSuccess } = action as LoginRequest;
    const { data: responseData } = yield call(api.post, "session", postData);

    const { token, ...data } = responseData;
    api.defaults.headers.Authorization = `Bearer ${token}`;

    if (onSuccess) onSuccess();
    yield put(AuthActions.loginSuccess(data, token));
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(AuthActions.loginFailure(errorMessage));
  }
}

export function* logoutRequest() {
  try {
    yield put(AuthActions.logoutSuccess());
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(AuthActions.logoutFailure(errorMessage));
  }
}
