import React from "react";

import { useTranslation } from "hooks";
import { translation } from "./translation";
import * as S from "./styles";

interface FeaturedHeaderProps extends React.ComponentProps<any> {}

type Props = FeaturedHeaderProps;

export const FeaturedHeader: React.FC<Props> = () => {
  const { getTranslation } = useTranslation(translation);

  return (
    <S.MainContainer>
      <S.Header>
        <S.HeaderTitle>
          {getTranslation("header", "title", "line1")}
          <br />
          <span>{getTranslation("header", "title", "line2")}</span>
        </S.HeaderTitle>
        <S.HeaderSubtitle>
          {getTranslation("header", "reservationFee")}: <span>20%</span>
        </S.HeaderSubtitle>
      </S.Header>
      <S.Body>
        <S.BodyTitle>{getTranslation("body", "title")}</S.BodyTitle>
        <S.BodyContent>
          <div>
            <S.BodyLabel>
              {getTranslation("body", "column1", "label")}
            </S.BodyLabel>
            {getTranslation("body", "column1", "value")}
          </div>
          <div>
            <S.BodyLabel>
              {getTranslation("body", "column2", "label")}
            </S.BodyLabel>
            {getTranslation("body", "column2", "value")}
          </div>
        </S.BodyContent>
      </S.Body>
      <S.Footer>
        <p>
          <S.MapMarkerIcon /> Suite 101, 30NW 34th Street Miami 33127
        </p>
      </S.Footer>
    </S.MainContainer>
  );
};
