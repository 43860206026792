import styled from "styled-components";
import { MapMarkerAlt } from "@styled-icons/fa-solid/MapMarkerAlt";

export const MainContainer = styled.div.attrs({
  className: "booking-featured-header",
})`
  display: flex;
  flex-direction: column;
  background-color: #1a1a1a;
  color: #fff;
`;

export const Header = styled.div`
  border-bottom: 1px solid #4b4b4b;
  padding-bottom: 38px;
`;

export const HeaderTitle = styled.h1`
  font-family: "GothamBold";
  font-size: 62px;
  margin-bottom: 26px;
  line-height: 1.2em;
  span {
    color: #de0048;
  }
  @media screen and (max-width: 800px) {
    font-size: 32px;
  }
`;

export const HeaderSubtitle = styled.h2`
  font-family: "GothamBold";
  font-size: 18px;
  margin-bottom: 8px;
  text-transform: uppercase;
  span {
    color: #de0048;
  }
  @media screen and (max-width: 800px) {
    font-size: 14px;
  }
`;

export const Label = styled.p`
  font-family: "BrandontextRegular";
  font-size: 16px;
  color: #d3d3d3;
`;

export const Disclaimer = styled(Label)``;

export const Body = styled.div`
  padding-top: 32px;
  padding-bottom: 32px;
`;

export const BodyTitle = styled.h2`
  font-family: "GothamBold";
  font-size: 18px;
  margin-bottom: 8px;
  text-transform: uppercase;
`;

export const BodyContent = styled.div`
  display: flex;
  font-family: "BrandontextMedium";
  flex-direction: row;
  font-size: 16px;

  @media screen and (min-width: 801px) {
    > div {
      flex: 1 1 50%;
    }
  }

  @media screen and (max-width: 800px) {
    flex-direction: column;
    > div {
      margin-bottom: 16px;
    }
  }
`;

export const BodyLabel = styled(Label)``;

export const Footer = styled.div`
  font-family: "BrandontextRegular";
  font-size: 16px;
  color: #fff;
`;

export const MapMarkerIcon = styled(MapMarkerAlt).attrs({
  size: 16,
})`
  color: #e01154;
  margin-bottom: 0;
`;
