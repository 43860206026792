export const translations = {
  auth: {
    title: {
      en: "Review your appointment details with us",
      pt: "Revise os detalhes de suas consultas com a gente",
      es: "Revise los detalles de sus citas con nosotros",
    },
    subtitle: {
      en: "My appointment",
      pt: "Minhas reservas",
      es: "Mi reservas",
    },

    login: {
      en: "Sign in to your account.",
      pt: "Faça login em sua conta.",
      es: "Iniciar sesión en su cuenta.",
    },
    account: {
      en: "Create your account.",
      pt: "Crie sua conta.",
      es: "Crea tu cuenta.",
    },
    generateCode: {
      en: "Enter the email you use to sign in",
      pt: "Insira o e-mail que você usa para logar",
      es: "Ingrese el correo electrónico que usa para iniciar sesión",
    },
    updatePassword: {
      en: "Fill the form below to procede",
      pt: "Preencha o formulário abaixo para continuar",
      es: "Complete el siguiente formulario para continuar",
    },
  },
  links: {
    myAccount: {
      en: "My account",
      pt: "Minha conta",
      es: "Mi cuenta",
    },
  },
};
