import React from "react";

import { useTranslation } from "hooks";
import { Header, Whatsapp, Footer } from "components/shared";
import { Banner, Gallery } from "components/Studio";
import { translations } from "./translations";
import * as S from "./styles";

const Studio: React.FC = () => {
  const { getTranslation } = useTranslation(translations);
  return (
    <S.Container>
      <Whatsapp />
      <Header />
      <Banner />
      <S.Background>
        <S.Content>
          <S.Title>{getTranslation("title")}</S.Title>
          <S.Text>{getTranslation("mission")}</S.Text>
        </S.Content>
      </S.Background>
      <Gallery />
      <Footer />
    </S.Container>
  );
};

export default Studio;
