import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import { FormHandles, SubmitHandler } from "@unform/core";
import { Form } from "@unform/web";

import { Select } from "components/shared";
import { useTranslation } from "hooks";
import {
  BookingPageActions,
  BookingPageState,
  ISelectedService,
} from "store/ducks/bookingPage";
import { AuthState } from "store/ducks/auth";
import { translations } from "./translations";
import * as S from "./styles";

interface IOption {
  label: string;
  value: number | string;
}
export interface IMembersByService {
  id: number;
  name: string;
  description: string | null;
  members: { id: number; name: string }[];
}
interface ServicesSelectorProps extends React.ComponentProps<any> {
  onPressSearch(): void;
  onChange(): void;
  membersByService: IMembersByService[];
  isSearching: boolean;
}

type Props = ServicesSelectorProps;

export const ServicesSelector: React.FC<Props> = ({
  onPressSearch,
  onChange,
  membersByService,
  isSearching,
}) => {
  const formRef = useRef<FormHandles>(null);

  const dispatch = useDispatch();
  const { getTranslation } = useTranslation(translations);

  const { selectedServices } = useSelector<RootStateOrAny, BookingPageState>(
    (state) => state.bookingPage
  );

  const { data: userData } = useSelector<RootStateOrAny, AuthState>(
    (state) => state.auth
  );

  const [canIAddService, setCanIAddService] = useState<boolean>(false);

  const [servicesOptions, setServicesOptions] = useState<IOption[]>([]);
  const [membersOptions, setMembersOptions] = useState<IOption[]>([]);

  const handleRemoveMemberService = useCallback(
    (selectedService: ISelectedService) => {
      dispatch(BookingPageActions.removeService(selectedService));
      onChange();
    },
    [dispatch, onChange]
  );

  const handleServiceChange = useCallback(
    ({ value }) => {
      formRef.current?.setFieldValue("team_id", null);
      const service = membersByService.find((i) => i?.id === value);
      if (service) {
        setMembersOptions(() => {
          return service?.members.map((i: any) => {
            const { id, name } = i;
            return { label: name, value: id };
          });
        });
      }
    },
    [membersByService]
  );

  const handleMemberChange = useCallback(() => {
    onChange();
    setTimeout(function () {
      formRef.current?.submitForm();
    }, 50);
  }, [onChange]);

  const handleSubmit: SubmitHandler<any> = useCallback(
    async (data, { reset }): Promise<void> => {
      const {
        id: service_id,
        name: service_name,
        description: service_description,
        members = [],
      } = membersByService.find((i: any) => i.id === data.service_id) as any;

      const { id: team_id, name: team_name } = members.find(
        (i: any) => i.id === data.team_id
      ) as any;

      const selectedService: ISelectedService = {
        service_id,
        service_name,
        service_description,
        team_id,
        team_name,
      };

      reset();
      setCanIAddService(false);

      dispatch(BookingPageActions.addService(selectedService));
    },
    [dispatch, membersByService]
  );

  const serviceComponentBuilder = useCallback(
    (service: ISelectedService, key: number) => {
      const { service_name, service_description } = service;

      return (
        <S.Service key={key}>
          <S.ServiceNameAndDescriptionRow>
            <S.Column>
              <S.Label>{getTranslation("fields", "service")}</S.Label>
              {service_name}
            </S.Column>
            <S.Column>
              <S.Label>{getTranslation("fields", "description")}</S.Label>
              {(service_description?.length && service_description) ||
                "No description available"}
            </S.Column>
          </S.ServiceNameAndDescriptionRow>

          <S.ProfessionalNameRow>
            <S.Column>
              <S.Label>{getTranslation("fields", "professional")}</S.Label>
              {service.team_name}
            </S.Column>
            <S.Column>
              <S.RemoveServiceButton
                onClick={() => handleRemoveMemberService(service)}
              >
                <S.TrashIcon />
              </S.RemoveServiceButton>
            </S.Column>
          </S.ProfessionalNameRow>
        </S.Service>
      );
    },
    [handleRemoveMemberService, getTranslation]
  );

  const HeaderComponent = useCallback((): JSX.Element => {
    if (!userData) return <></>;

    const { first_name, last_name, email, phone } = userData;

    return (
      <S.Header>
        <S.HeaderTitle>{getTranslation("title")}</S.HeaderTitle>
        <S.ClientInfo>
          <S.ClientName>{`${first_name} ${last_name}`.trim()}</S.ClientName>
          <S.ClientContact>
            {email && (
              <>
                {email} <span>|</span>{" "}
              </>
            )}
            {phone}
          </S.ClientContact>
        </S.ClientInfo>
      </S.Header>
    );
  }, [userData, getTranslation]);

  const ActionButtonsComponent = useCallback((): JSX.Element => {
    return (
      <S.ActionButtons>
        {servicesOptions.length > 0 && (
          <S.AddServiceButton onClick={() => setCanIAddService(true)}>
            {getTranslation("buttons", "addService")}
          </S.AddServiceButton>
        )}
        {selectedServices.length > 0 && (
          <S.StartSearchButton onClick={onPressSearch}>
            {(isSearching && <S.Loading />) ||
              getTranslation("buttons", "newSearch")}
          </S.StartSearchButton>
        )}
      </S.ActionButtons>
    );
  }, [
    servicesOptions,
    selectedServices,
    onPressSearch,
    getTranslation,
    isSearching,
  ]);

  const setServicesOptionsCB = useCallback((): void => {
    setServicesOptions(
      membersByService
        .filter((i: IMembersByService) => {
          return !selectedServices.some((j) => j.service_id === i.id);
        })
        .map((i: IMembersByService) => {
          return { label: i.name, value: i.id };
        })
    );
  }, [selectedServices, membersByService]);

  useEffect(() => {
    setServicesOptionsCB();
  }, [setServicesOptionsCB]);

  return (
    <S.MainContainer>
      {membersByService.length > 0 && (
        <S.ContentConstrainer>
          <HeaderComponent />
          <S.Divider />
          <S.SelectedServices>
            {selectedServices.map((i, key) => serviceComponentBuilder(i, key))}
          </S.SelectedServices>
          {(canIAddService || selectedServices.length === 0) && (
            <Form ref={formRef} onSubmit={handleSubmit}>
              <S.FormRow>
                <Select
                  name="service_id"
                  label={getTranslation("fields", "service")}
                  options={servicesOptions}
                  onChange={handleServiceChange}
                />
                <Select
                  name="team_id"
                  label={getTranslation("fields", "professional")}
                  options={membersOptions}
                  onChange={handleMemberChange}
                  isDisabled={membersOptions.length === 0}
                />
              </S.FormRow>
            </Form>
          )}
          <S.Divider />
          <ActionButtonsComponent />
        </S.ContentConstrainer>
      )}
    </S.MainContainer>
  );
};
