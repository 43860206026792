export const translations = {
  header: {
    title: {
      pt: "Datas disponíveis:",
      en: "Available dates:",
      es: "Fechas Disponibles:",
    },
  },
  fields: {
    startsAt: {
      pt: "Selecione um horário disponível",
      en: "Select an available time",
      es: "Seleccione una hora disponible",
    },
  },
  errors: {
    startsAt: {
      invalid: {
        pt: "Selecione um horário disponível",
        en: "Select an available time",
        es: "Seleccione una hora disponible",
      },
    },
  },
  labels: {
    services: {
      pt: "Serviços:",
      en: "Services:",
      es: "Servicios:",
    },
    professionals: {
      pt: "Profissionais:",
      en: "Professionals:",
      es: "Profesionales:",
    },
    price: {
      pt: "Preço:",
      en: "Price:",
      es: "Precio:",
    },
    fee: {
      pt: "Taxa de reserva:",
      en: "Reservation fee:",
      es: "Tarifa de reserva:",
    },
  },
  buttons: {
    viewMore: {
      pt: "Ver mais",
      en: "View more",
      es: "Ver más",
    },
    viewMoreDates: {
      pt: "Ver mais datas",
      en: "View more dates",
      es: "Ver más fechas",
    },
    bookNow: {
      pt: "Reservar agora",
      en: "Book now",
      es: "Reservar ahora",
    },
  },
};
