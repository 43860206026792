import styled from "styled-components";
import bg from "assets/images/studio-banner-about.png";

export const Container = styled.section`
  width: 100%;
  max-width: 1920px;
  height: 620px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #1a1a1a url(${bg}) no-repeat center center;

  @media screen and (max-width: 414px) {
    padding: 96px 32px;
    height: auto;
    background-size: cover;
  }
`;

export const Subtitle = styled.h5`
  font-size: 16px;
  line-height: 16px;
  text-transform: uppercase;
  color: #de0048;
  margin-bottom: 24px;
`;

export const Title = styled.h1`
  font-size: 76px;
  line-height: 70px;
  color: #fff;
  margin-bottom: 24px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 414px) {
    font-size: 40px;
    line-height: 40px;
  }
`;

export const Description = styled.article`
  font-size: 18px;
  line-height: 24px;
  color: #fff;
  text-align: center;
  width: 100%;
  max-width: 420px;

  @media screen and (max-width: 414px) {
    font-size: 16px;
  }
`;
