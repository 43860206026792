export const translations = {
  title: {
    en: "Payment Confirmed",
    pt: "Pagamento Confirmado",
    es: "Pao Confirmado",
  },
  subtitle: {
    en: "We are waiting for you!",
    pt: "Estamos esperando por você!",
    es: "Nosotros te estamos esperando!",
  },
  labels: {
    reservationDate: {
      en: "Reservation date:",
      pt: "Data da reserva:",
      es: "Fecha de reserva:",
    },
    reservationTime: {
      en: "Reservation time:",
      pt: "Hora da reserva:",
      es: "Hora de reserva:",
    },
    services: {
      en: "Services:",
      pt: "Serviços:",
      es: "Servicios:",
    },
    professionals: {
      en: "Professionals:",
      pt: "Profissionais:",
      es: "Profesionales:",
    },
    fee: {
      en: "Reservation fee",
      pt: "Taxa de reserva",
      es: "Tarifa de reserva",
    },
    confirmedFee: {
      en: "Confirmed",
      pt: "Confirmado",
      es: "confirmado",
    },
    pendingPayment: {
      en: "Pending Payment",
      pt: "Pagamento Pendente",
      es: "Pago Pendiente",
    },
    payOnAppointmentDate: {
      en: "Pay on your appointment day",
      pt: "Pague no dia de sua reserva",
      es: "Paga el día de tu reserva ",
    },
  },
  buttons: {
    findUs: {
      en: "FIND US (VIEW MAP)",
      pt: "ONDE ESTAMOS (VER MAPA)",
      es: "ENCUÉNTRANOS (VER MAPA)",
    },
    goHome: {
      en: "GO TO HOME",
      pt: "VOLTAR PARA A HOME",
      es: "VOLVER AL HOME",
    },
  },
  moreInfo: {
    en: "More information:",
    pt: "Mais informações:",
    es: "Más información:",
  },
};
