export const translations = {
  title: {
    pt: "Confirme sua reserva",
    en: "Confirm your reservation",
    es: "Confirma tu reserva",
  },
  labels: {
    reservationDate: {
      pt: "Data da reserva:",
      en: "Reservation date:",
      es: "Fecha de reserva:",
    },
    reservationTime: {
      pt: "Hora da reserva:",
      en: "Reservation time:",
      es: "Hora de reserva:",
    },
    services: {
      pt: "Serviços:",
      en: "Services:",
      es: "Servicios:",
    },
    professionals: {
      pt: "Profissionais:",
      en: "Professionals:",
      es: "Profesionales:",
    },
    fee: {
      pt: "Taxa de reserva",
      en: "Reservation fee",
      es: "Tarifa de reserva",
    },
  },
  paymentInstructions: {
    pt:
      "Revise os detalhes de pagamento de sua reserva e clique no botão abaixo para pagar sua taxa de reserva.",
    en:
      "Review your appointment details and click on the button below to pay your reservation fee.",
    es:
      "Revise los detalles de su cita y haga clic en el botón de abajo para pagar su tarifa de reserva.",
  },
  buttons: {
    pay: {
      pt: "Ir para o pagamento",
      en: "Go to payment",
      es: "Ir ao pago",
    },
    back: {
      pt: "Escolher outra data",
      en: "Choose another date",
      es: "Elige otra fecha",
    },
  },
};
