import React, { useCallback, useEffect, useState } from "react";
import Slider from "react-slick";
import { FormattedMessage } from "react-intl";
import { v4 as uuidV4 } from "uuid";

import * as S from "./styles";

interface IPhoto {
  id: string;
  image: string;
}

const Gallery: React.FC = () => {
  const [photos, setPhotos] = useState<Array<IPhoto> | null>(null);
  const settings = {
    className: "center",
    centerMode: true,
    slidesToShow: 3,
    speed: 500,
    adaptiveHeight: true,
    nextArrow: (
      <S.NextButton>
        <S.IconNext />
      </S.NextButton>
    ),
    prevArrow: (
      <S.PrevButton>
        <S.IconPrev />
      </S.PrevButton>
    ),
    responsive: [
      {
        breakpoint: 1001,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const getPhotos = useCallback(() => {
    const gallerylength = 17;
    let galleryArray = [];

    for (let index = 1; index <= gallerylength; index++) {
      galleryArray.push({
        id: uuidV4(),
        image: `gallery/studio/${index}.png`,
      });
    }

    setPhotos(galleryArray);
  }, []);

  useEffect(() => {
    getPhotos();
  }, [getPhotos]);
  return (
    <S.Container>
      <S.Subtitle>
        <FormattedMessage id="studio.gallery.subtitle" />
      </S.Subtitle>
      <S.Title>
        <FormattedMessage id="studio.gallery.title" />
      </S.Title>
      <S.SliderWrapper>
        {photos && (
          <Slider {...settings}>
            {photos.map((photo) => (
              <S.Photo key={photo.id}>
                <img src={photo.image} alt="studio" />
              </S.Photo>
            ))}
          </Slider>
        )}
      </S.SliderWrapper>
    </S.Container>
  );
};

export default Gallery;
