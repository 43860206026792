import styled from "styled-components";
import { Trash2 } from "@styled-icons/feather/Trash2";
import { Button } from "styles/components";
export { Loading } from "styles/components";
export { FormRow } from "styles/form";

export const MainContainer = styled.div`
  padding: 64px 2rem;
  background-color: #ededed;
`;

export const ContentConstrainer = styled.div`
  margin: 0 auto;
  max-width: 680px;
  width: 100%;
  form {
    margin-top: 32px;
    animation: formShowUp 300ms ease-in-out;
  }
  @keyframes formShowUp {
    from {
      margin-top: 64px;
      opacity: 0.5;
    }
    to {
      margin-top: 32px;
      opacity: 1;
    }
  }
`;

export const Divider = styled.hr`
  border: none;
  border-bottom: 2px solid #cad5dd;
  margin: 32px 0;
`;
// Header

export const Header = styled.div`
  text-align: center;
`;

export const HeaderTitle = styled.h2`
  color: #091524;
  font-size: 30px;
  font-family: "GothamBold";
  margin-bottom: 16px;
`;

export const ClientInfo = styled.div`
  color: #434e5c;
  font-family: "BrandontextMedium";
  margin-bottom: 24px;
`;

export const ClientName = styled.p`
  font-size: 22px;
  margin-bottom: 24px;
`;

export const ClientContact = styled.p`
  font-size: 16px;
  span {
    display: inline-block;
    margin: 0 12px;
  }
`;

// Control Action Buttons

export const ActionButtons = styled.div`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 800px) {
    flex-direction: column;
    button:not(:last-child) {
      margin-bottom: 16px;
    }
  }
`;

export const AddServiceButton = styled(Button).attrs({
  whileHover: { scale: 1.1 },
  btStyle: "outline",
})`
  @media screen and (min-width: 801px) {
    margin-right: auto;
  }
`;

export const StartSearchButton = styled(Button).attrs({
  whileHover: { scale: 1.1 },
})`
  @media screen and (min-width: 801px) {
    margin-left: auto;
  }
`;

// Selected Services Listing

export const SelectedServices = styled.div``;

export const Service = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 0px;
  column-gap: 24px;
  font-family: "GothamBook";

  &:not(:last-child) {
    border-bottom: 2px solid #cad5dd66;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media screen and (max-width: 800px) {
    flex-wrap: wrap;
  }
`;

export const ServiceNameAndDescriptionRow = styled(Row)`
  margin-bottom: 16px;
  .column {
    &:first-child {
      flex: 0 1 240px;
    }
  }
  @media screen and (max-width: 800px) {
    .column {
      flex: 1 0 100%;
      &:first-child {
        margin-bottom: 16px;
      }
    }
  }
`;

export const ProfessionalNameRow = styled(Row)`
  justify-content: space-between;
`;

export const Column = styled.div.attrs({ className: "column" })`
  display: flex;
  flex-direction: column;
  color: #091524;
`;

export const Label = styled.div`
  color: #ac7a40;
  margin-bottom: 8px;
`;

export const RemoveServiceButton = styled(Button).attrs({
  type: "button",
  btStyle: "transparent",
  whileHover: { scale: 1.1 },
})``;

export const TrashIcon = styled(Trash2).attrs({
  size: 24,
})`
  color: #de0048;
`;
