import styled from "styled-components";
import { Check2 } from "@styled-icons/bootstrap/Check2";
import { Close } from "@styled-icons/material/Close";

export { Loading } from "styles/components";

export const MainContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  max-width: 680px;
`;

// Header

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 38px;
  width: 100%;
  @media screen and (max-width: 1100px) {
    flex-direction: column;
  }
`;

const statusColor: Record<string, string> = {
  confirmed: "#0BA86D",
  completed: "#0BA86D",
  canceled: "#CB3D6B",
  empty: "#CAD5DD",
};

export const HeaderStatus = styled.div.attrs({ className: "header-status" })<{
  status: string;
}>`
  display: flex;
  height: 64px;
  width: 64px;
  flex: 0 0 64px;
  border-color: ${({ status }) => `${statusColor[status]}`};
  border-width: 2px;
  border-style: solid;
  border-radius: 32px;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  svg {
    color: ${({ status }) => `${statusColor[status]}`};
  }
`;

export const SuccessIcon = styled(Check2).attrs({ size: 32 })``;
export const FailureIcon = styled(Close).attrs({ size: 32 })``;

export const HeaderTitle = styled.div`
  color: #1a1a1a;
  h4 {
    font-family: "GothamBold";
    font-size: 16px;
    text-transform: uppercase;
  }
  h1 {
    font-family: "GothamBook";
    font-size: 30px;
  }
  @media screen and (max-width: 1100px) {
    text-align: center;
    margin-top: 24px;
  }
`;

// Appointment Main Details

export const Appointment = styled.div`
  border-top: 2px solid #cad5dd;
  border-bottom: 2px solid #cad5dd;
  padding: 32px 0;
  color: #091524;

  @media screen and (min-width: 601px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 32px;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ColumnLabel = styled.div`
  font-size: 16px;
  font-family: "BrandontextRegular";
  margin-bottom: 12px;
`;

export const ColumnValue = styled.div`
  font-size: 18px;
  font-family: "BrandontextMedium";
  margin-bottom: 8px;
`;

export const DateColumnValue = styled.div`
  font-size: 24px;
  font-family: "BrandontextMedium";
  margin-bottom: 8px;
`;

export const Payment = styled.div`
  display: flex;
  flex-direction: column;
  padding: 22px 0;
`;

export const TotalAmount = styled.div`
  font-family: "BrandontextMedium";
  font-size: 20px;
`;

export const FeeAmountLabel = styled.div`
  font-size: 16px;
  font-family: "BrandontextRegular";
`;

export const FeeAmountValue = styled.span`
  font-family: "BrandontextBold";
  color: #0ba86d;
`;
