import { createReducer, createActions } from "reduxsauce";
import update from "immutability-helper";

export interface CreateAccountState {
  loading: boolean;
  error: string | null;
}

export interface CreateAccountPostData {
  first_name: string;
  last_name: string;
  email?: string;
  password: string;
  phone: string;
  register_origin: string;
}

export interface CreateAccountRequest {
  postData: CreateAccountPostData;
  onSuccess?(): void;
  onFailure?(): void;
}

interface FailureAction {
  error: string;
}

const { Types, Creators } = createActions(
  {
    request: ["postData", "onSuccess", "onFailure"],
    success: [],
    failure: ["error"],
    reset: [],
  },
  { prefix: "CREATE_ACCOUNT_" }
);

const INITIAL_STATE: CreateAccountState = {
  loading: false,
  error: null,
};

const request = (state: CreateAccountState, action: CreateAccountRequest) =>
  update(state, {
    loading: { $set: true },
    error: { $set: null },
  });

const success = (state: CreateAccountState) =>
  update(state, {
    loading: { $set: false },
  });

const failure = (state: CreateAccountState, action: FailureAction) =>
  update(state, {
    loading: { $set: false },
    error: { $set: action.error },
  });

const reset = () => INITIAL_STATE;

export const createAccount = createReducer(INITIAL_STATE, {
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success,
  [Types.FAILURE]: failure,
  [Types.RESET]: reset,
});

export const CreateAccountTypes = Types;
export const CreateAccountActions = Creators;
