import { call, put, all } from "redux-saga/effects";

import api from "services/api";
import { notify } from "services/notification";
import { requestErrorHandler } from "utils";
import {
  CreateAppointmentRequest,
  CreateAppointmentActions,
} from "../../ducks/appointment";

export function* createAppointmentRequest(action: any) {
  try {
    const { postData, onSuccess } = action as CreateAppointmentRequest;
    const url = `appointment`;
    const { data: responseData } = yield call(api.post, url, postData);

    if (onSuccess) onSuccess(responseData.id);
    yield all([put(CreateAppointmentActions.success())]);
  } catch (error) {
    const { onFailure } = action as CreateAppointmentRequest;
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    if (onFailure) onFailure();
    yield put(CreateAppointmentActions.failure(errorMessage));
  }
}
