import { createReducer, createActions } from "reduxsauce";
import { format } from "date-fns";
import update from "immutability-helper";

export interface FindTimeframesState {
  data: Record<string, any>[];
  nextTargetDate: string;
  totalPrice: number;
  totalDuration: number;
  loading: boolean;
  error: string | null;
}

export interface FindTimeframesRequest {
  postData: Record<string, any>;
  onSuccess?: Function;
  onFailure?: Function;
}

interface SuccessAction {
  data: Record<string, any>[];
  extraData: {
    nextTargetDate: string;
    totalPrice: number;
    totalDuration: number;
  };
}

interface FailureAction {
  error: string;
}

const { Types, Creators } = createActions(
  {
    request: ["postData", "onSuccess", "onFailure"],
    success: ["data", "extraData"],
    failure: ["error"],
    reset: [],
  },
  { prefix: "FIND_TIMEFRAMES_" }
);

const INITIAL_STATE: FindTimeframesState = {
  data: [],
  nextTargetDate: format(new Date(), "yyy-MM-dd"),
  totalPrice: 0,
  totalDuration: 0,
  loading: false,
  error: null,
};

const request = (state: FindTimeframesState, action: FindTimeframesRequest) =>
  update(state, {
    loading: { $set: true },
    error: { $set: null },
  });

const success = (state: FindTimeframesState, action: SuccessAction) =>
  update(state, {
    data: { $set: action.data },
    nextTargetDate: { $set: action.extraData.nextTargetDate },
    totalPrice: { $set: action.extraData.totalPrice },
    totalDuration: { $set: action.extraData.totalDuration },
    loading: { $set: false },
  });

const failure = (state: FindTimeframesState, action: FailureAction) =>
  update(state, {
    loading: { $set: false },
    error: { $set: action.error },
  });

const reset = () => INITIAL_STATE;

/* Reducers to types */
export const findTimeframes = createReducer(INITIAL_STATE, {
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success,
  [Types.FAILURE]: failure,
  [Types.RESET]: reset,
});

export const FindTimeframesTypes = Types;
export const FindTimeframesActions = Creators;
