import styled from "styled-components";
import { ChevronDown } from "@styled-icons/bootstrap/ChevronDown";
import { Button } from "styles/components";

export { FormRow } from "styles/form";
export { Loading } from "styles/components";

export const MainContainer = styled.div`
  padding: 64px 2rem;
  background-color: #fff;
`;

export const ContentConstrainer = styled.div`
  margin: 0 auto;
  max-width: 680px;
  width: 100%;
`;

// Header

export const Header = styled.div`
  text-align: left;
`;

export const HeaderTitle = styled.h2`
  color: #596879;
  font-size: 30px;
  font-family: "GothamBook";
  font-weight: 400;
  margin-bottom: 40px;
`;

// Dates List

export const ListView = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ListItem = styled.div`
  &.active {
    button.toggle-button svg {
      transform: rotate(180deg);
    }
  }
`;

export const ItemHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #b2bec7;
  padding: 22px 0px;
`;

export const ItemHeaderTitle = styled.div`
  color: #091524;
  font-size: 20px;
  span {
    text-transform: capitalize;
  }
`;

export const ToggleButton = styled.button.attrs({
  type: "button",
  className: "toggle-button",
})`
  color: #8a8f95;
  text-transform: uppercase;
  margin-left: auto;

  @media screen and (max-width: 800px) {
    font-size: 0px;
  }
`;

export const ToggleIcon = styled(ChevronDown).attrs({
  size: 16,
})`
  color: #1a1a1a;
  margin: 0 8px;
  transition: all 100ms linear;
`;

export const ItemBody = styled.div`
  animation: showUpItemBody 300ms ease-in-out;

  @keyframes showUpItemBody {
    from {
      opacity: 0.5;
      margin-top: 24px;
    }
    to {
      opacity: 1;
      margin-top: 0px;
    }
  }
`;

export const Subheader = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 32px;
  color: #ac7a40;
  padding: 32px 32px 16px 32px;
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const BodyBox = styled.div`
  background-color: #ededed;
  padding: 40px 32px;
  border-radius: 6px;
  form {
    margin-top: 24px;
    align-items: baseline;
  }

  @media screen and (min-width: 801px) {
    form {
      display: grid;
      grid-template-columns: 2fr 1fr;
      column-gap: 32px;
    }
  }

  @media screen and (max-width: 800px) {
    margin-top: 16px;
    form {
      display: flex;
      flex-direction: column;
      .select-container {
        flex: 1 0 100%;
      }
      button {
        width: 100%;
      }
    }
  }
`;

export const ServiceDetails = styled.div`
  @media screen and (max-width: 800px) {
    .column {
      margin-bottom: 16px;
      h4 {
        font-family: "BrandontextRegular";
        color: #ac7a40;
        font-size: 14px;
        line-height: 14px;
      }
    }
  }

  @media screen and (min-width: 801px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 32px;
    color: #0a1624;
    .column {
      h4 {
        display: none;
      }
    }
  }
`;

export const Column = styled.div.attrs({ className: "column" })`
  align-self: stretch;
`;
export const ColumnValue = styled.div`
  margin-bottom: 12px;
`;

export const PriceValue = styled.div`
  font-size: 18px;
  margin-bottom: 12px;
`;
export const FeeLabel = styled.div``;
export const FeeValue = styled.div`
  color: #486a91;
`;

// Action Buttons

export const ActionButtons = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: row;
`;

export const ViewMoreDatesButton = styled(Button).attrs({
  whileHover: { scale: 1.1 },
  btStyle: "outline",
})`
  margin-right: auto;
`;

export const BookNowButton = styled(Button).attrs({
  whileHover: { scale: 1.1 },
})`
  display: flex;
`;
