import styled, { css } from "styled-components";
import { Loader } from "@styled-icons/feather";
import { motion } from "framer-motion";

type ButtonStyleOption =
  | "primary"
  | "secondary"
  | "transparent"
  | "cancel"
  | "outline"
  | "danger";

interface ButtonStyle {
  btStyle?: ButtonStyleOption;
}

const styleButton = {
  primary: css`
    background-color: #de0048;
    color: #fff;
  `,
  secondary: css`
    background-color: #1a1a1a;
    color: #fff;
  `,
  outline: css`
    background-color: transparent;
    color: #de0048;
    padding: 16px;
    border: 2px #de0048 solid;
  `,
  transparent: css`
    background-color: transparent;
    color: #1a1a1a;
    padding: 16px 0;
  `,
  cancel: css`
    background-color: #e0e0e0;
    color: #888;
  `,
  danger: css`
    background-color: #ff2e4a;
    color: #fff;
  `,
};

export const Button = styled(motion.button)<ButtonStyle>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  padding: 0 24px;
  height: 52px;
  border-radius: 8px;
  ${({ btStyle }) => (btStyle ? styleButton[btStyle] : styleButton.primary)}
`;

export const LinkButton = styled(motion.a)<ButtonStyle>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  padding: 0 24px;
  height: 52px;
  border-radius: 8px;
  font-family: "GothamBold";
  ${({ btStyle }) => (btStyle ? styleButton[btStyle] : styleButton.primary)}
`;

export const PageContainer = styled.main`
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
`;

export const Loading = styled(Loader).attrs({
  size: 24,
  className: "icon-spin",
})``;
