import styled from "styled-components";
import serviceEyebrows from "assets/images/services/service-eyebrows.png";
import serviceFace from "assets/images/services/service-face.png";
import serviceHairstyle from "assets/images/services/service-hairstyle.png";
import serviceMan from "assets/images/services/service-man.png";
import serviceMassage from "assets/images/services/service-massage.png";
import serviceNails from "assets/images/services/service-nails.png";
import serviceWax from "assets/images/services/service-wax.png";

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  padding: 64px 32px;

  @media screen and (max-width: 414px) {
    padding-top: 48px;
    padding-bottom: 48px;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1100px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Subtitle = styled.h5`
  font-size: 16px;
  text-transform: uppercase;
  color: #de0048;
  margin-bottom: 32px;
`;

export const Title = styled.h1`
  font-size: 36px;
  line-height: 40px;
  color: #1a1a1a;
  margin-bottom: 72px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 560px;

  @media screen and (max-width: 414px) {
    font-size: 30px;
    line-height: 30px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media screen and (max-width: 1085px) {
    display: none;
  }
`;

export const MobileWrapper = styled.div`
  width: 100%;
  max-width: 432px;
  display: none;

  .slick-prev:before,
  .slick-next:before {
    content: "";
    display: none;
  }

  @media screen and (max-width: 1085px) {
    display: block;
  }
`;

export const Box = styled.div`
  width: 100%;
  max-width: 340px;
  max-height: 840px;
  border: 1px #dedede solid;
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const BoxHeader = styled.div`
  width: 100%;
  background-color: #ededed;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 24px;

  font-family: "GothamBold";
  font-size: 16px;
  color: #1a1a1a;
  text-transform: uppercase;

  svg {
    position: absolute;
    bottom: -12px;
    .triangle {
      fill: #ededed;
    }
  }
`;

export const BoxContent = styled.div`
  width: 100%;
  padding: 48px;
`;

export const Price = styled.h6`
  color: #ac7a40;
  font-size: 16px;
  line-height: 16px;
  margin-bottom: 16px;
`;

export const Description = styled.p`
  color: #1a1a1a;
  font-size: 18px;
  line-height: 24px;
  white-space: pre-wrap;
`;

export const ServiceEyebrows = styled.img.attrs({ src: serviceEyebrows })`
  width: 100%;

  max-width: max-content;
  max-height: max-content;
`;
export const ServiceFace = styled.img.attrs({ src: serviceFace })`
  width: 100%;

  max-width: max-content;
  max-height: max-content;
`;
export const ServiceHairstyle = styled.img.attrs({ src: serviceHairstyle })`
  width: 100%;

  max-width: max-content;
  max-height: max-content;
`;
export const ServiceMan = styled.img.attrs({ src: serviceMan })`
  width: 100%;

  max-width: max-content;
  max-height: max-content;
`;
export const ServiceMassage = styled.img.attrs({ src: serviceMassage })`
  width: 100%;

  max-width: max-content;
  max-height: max-content;
`;
export const ServiceNails = styled.img.attrs({ src: serviceNails })`
  width: 100%;

  max-width: max-content;
  max-height: max-content;
`;
export const ServiceWax = styled.img.attrs({ src: serviceWax })`
  width: 100%;

  max-width: max-content;
  max-height: max-content;
`;
