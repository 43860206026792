import styled from "styled-components";
import { Calendar } from "@styled-icons/heroicons-outline";
import { ReactComponent as LogoSvg } from "assets/images/logo.svg";

export const Container = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px;
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 999;
`;

export const Logo = styled(LogoSvg)`
  width: 100%;
  max-width: max-content;
  margin: 16px 0;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CalendarIcon = styled(Calendar).attrs({ size: 20 })`
  margin-right: 8px;
  color: #de0048;
`;

export const BookButton = styled.button.attrs({ type: "button" })`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 111px;
  font-size: 14px;
  line-height: 14px;
  text-transform: uppercase;
  color: #000;
  transition: 300ms ease;
  padding: 0 32px;
  border-left: 1px #d9d9d9 solid;
  border-right: 1px #d9d9d9 solid;
  margin: 0 32px;

  &:hover {
    color: #de0048;
  }

  @media screen and (max-width: 600px) {
    margin-right: 0;
    border-right: 0;
    padding-right: 0;
  }

  @media screen and (max-width: 375px) {
    display: none;
  }
`;
