import styled from "styled-components";
export { FieldLabel, FieldError } from "styles/form";

export const FieldContainer = styled.div.attrs({
  className: "select-container",
})`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;
  &:not(:last-child) {
    margin-right: 16px;
  }
`;

export const customStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    borderBottom: `1px #B4C5D3 solid`,
    padding: 8,
    fontFamily: "BrandontextRegular",
    fontSize: 16,
    boxSizing: "border-box",
    outline: "none",
    backgroundColor: state.isSelected ? "#313131" : "transparent",
  }),
  input: (provided: any) => ({
    ...provided,
    padding: 8,
    fontSize: 16,
    margin: 0,
  }),
  container: (provided: any) => ({
    ...provided,
    padding: 0,
    width: "100%",
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    padding: 0,
    fontFamily: "BrandontextRegular",
    fontSize: 16,
    color: "#091524",
  }),
  placeholder: (provided: any) => ({
    ...provided,
    marginLeft: 14,
    fontSize: 14,
    padding: 0,
  }),
  control: (provided: any) => ({
    ...provided,
    backgroundColor: "#FFF",
    height: 52,
    borderRadius: 4,
    border: `1px #B4C5D3 solid`,
    boxSizing: "border-box",
    outline: "none",
  }),
  singleValue: (provided: any) => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    fontSize: 10,
    color: "#ccc",
  }),
};
