import { call, put } from "redux-saga/effects";

import api from "services/api";
import { notify } from "services/notification";
import { requestErrorHandler, queryBuilder } from "utils";
import {
  PaginateAppointmentsActions,
  PaginateAppointmentsRequest,
} from "../../ducks/appointment";

export function* paginateAppointmentsRequest(action: any) {
  try {
    const { query = {}, onSuccess } = action as PaginateAppointmentsRequest;
    const queryString = queryBuilder(query);
    const { data: responseData } = yield call(
      api.get,
      `appointment?${queryString}`
    );
    const { data, pagination } = responseData;
    if (onSuccess) onSuccess();
    yield put(PaginateAppointmentsActions.success(data, pagination));
  } catch (error) {
    const { onFailure } = action as PaginateAppointmentsRequest;
    if (onFailure) onFailure();
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(PaginateAppointmentsActions.failure(errorMessage));
  }
}
