import { createReducer, createActions } from "reduxsauce";
import update from "immutability-helper";

export interface ISelectedService {
  team_id: number;
  team_name: string;
  service_id: number;
  service_name: string;
  service_description: string | null;
}

export interface IAppointmentService {
  team_id: number;
  service_id: number;
}

export interface BookingPageState {
  selectedServices: ISelectedService[];
}

interface AddOrRemoveServiceAction {
  selectedService: ISelectedService;
}

const { Creators, Types } = createActions(
  {
    addService: ["selectedService"],
    removeService: ["selectedService"],
    reset: [],
  },
  { prefix: "BOOKING_PAGE_" }
);

const INITIAL_STATE: BookingPageState = {
  selectedServices: [],
};

const addService = (
  state: BookingPageState,
  action: AddOrRemoveServiceAction
) => {
  const { selectedService } = action;
  return update(state, {
    selectedServices: {
      $set: [...state.selectedServices, selectedService],
    },
  });
};

const removeService = (
  state: BookingPageState,
  action: AddOrRemoveServiceAction
) => {
  const { selectedService } = action;
  return update(state, {
    selectedServices: {
      $set: state.selectedServices.filter(
        (i) => i.service_id !== selectedService.service_id
      ),
    },
  });
};

const reset = () => INITIAL_STATE;

export const bookingPage = createReducer(INITIAL_STATE, {
  [Types.ADD_SERVICE]: addService,
  [Types.REMOVE_SERVICE]: removeService,
  [Types.RESET]: reset,
});

export const BookingPageTypes = Types;
export const BookingPageActions = Creators;
