import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import { format } from "date-fns";

import { CartState } from "store/ducks/cart";
import { AuthState } from "store/ducks/auth";
import {
  CreateAppointmentActions,
  CreateAppointmentState,
  CreateAppointmentPostData,
} from "store/ducks/appointment";
import {
  ProcessPaymentActions,
  ProcessPaymentState,
} from "store/ducks/payment";
import { Header } from "components/shared";
import { SquarePayment, SquarePaymentData } from "components/SquarePayment";

import { useTranslation } from "hooks";
import { translations } from "./translations";
import * as S from "./styles";

interface BookingReviewPageProps extends React.ComponentProps<any> {}
type Props = BookingReviewPageProps;

export const BookingReviewPage: React.FC<Props> = () => {
  const { getTranslation } = useTranslation(translations);
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    selectedServices,
    startsAt,
    totalPrice,
  } = useSelector<RootStateOrAny>((state) => state.cart) as CartState;

  const { data: userData } = useSelector<RootStateOrAny>(
    (state) => state.auth
  ) as AuthState;

  const { loading: creatingAppointment } = useSelector<RootStateOrAny>(
    (state) => state.createAppointment
  ) as CreateAppointmentState;

  const { loading: processingPayment } = useSelector<RootStateOrAny>(
    (state) => state.processPayment
  ) as ProcessPaymentState;

  const isBusy = creatingAppointment || processingPayment;

  const HeaderComponent: React.FC = useCallback(() => {
    if (!userData) return <></>;

    const { first_name, last_name, email, phone } = userData;
    return (
      <S.Header>
        <S.HeaderTitle>{getTranslation("title")}</S.HeaderTitle>
        <S.ClientInfo>
          <S.ClientName>{`${first_name} ${last_name}`.trim()}</S.ClientName>
          <S.ClientContact>
            {email} <span>|</span> {phone}
          </S.ClientContact>
        </S.ClientInfo>
      </S.Header>
    );
  }, [userData, getTranslation]);

  const AppointmentComponent: React.FC = useCallback(() => {
    return (
      <S.Appointment>
        <S.Column>
          <S.ColumnLabel>
            {getTranslation("labels", "reservationDate")}
          </S.ColumnLabel>
          <S.DateColumnValue>
            {format(new Date(startsAt), "MM/dd/yyyy")}
          </S.DateColumnValue>
          <S.ColumnLabel>
            {getTranslation("labels", "reservationTime")}
          </S.ColumnLabel>
          <S.DateColumnValue>
            {format(new Date(startsAt), "hh:mm aaa")}
          </S.DateColumnValue>
        </S.Column>

        <S.Column>
          <S.ColumnLabel>{getTranslation("labels", "services")}</S.ColumnLabel>
          {selectedServices.map(({ service_name }, key) => (
            <S.ColumnValue key={key}>{service_name}</S.ColumnValue>
          ))}
        </S.Column>

        <S.Column>
          <S.ColumnLabel>
            {getTranslation("labels", "professionals")}
          </S.ColumnLabel>
          {selectedServices.map(({ team_name }, key) => (
            <S.ColumnValue key={key}>{team_name}</S.ColumnValue>
          ))}
        </S.Column>
      </S.Appointment>
    );
  }, [getTranslation, startsAt, selectedServices]);

  const PaymentDetailsComponent: React.FC = useCallback(() => {
    return (
      <S.PaymentDetails>
        <S.TotalPrice>US$ {totalPrice}</S.TotalPrice>
        <S.Fee>
          {getTranslation("labels", "fee")} (20%):{" "}
          <span>US$ {totalPrice * 0.2}</span>
        </S.Fee>
      </S.PaymentDetails>
    );
  }, [totalPrice, getTranslation]);

  const handleGoBack = useCallback(() => {
    history.push("/booking");
  }, [history]);

  const navigateToCheckoutComplete = useCallback(
    (appointmentId: number) => {
      history.push(`/checkout-complete/${appointmentId}`);
    },
    [history]
  );

  const handleCreateAppointment = useCallback(
    ({ payment_token }: Record<string, string>) => {
      if (!userData) return;

      const appointmentServices = selectedServices.map(
        ({ service_id, team_id }) => ({ service_id, team_id })
      );

      const postData: CreateAppointmentPostData = {
        client_id: userData.client_id,
        appointment_services: appointmentServices,
        register_origin: "Site",
        starts_at: format(new Date(startsAt), "yyyy-MM-dd HH:mm"),
        payment_token,
      };

      dispatch(
        CreateAppointmentActions.request(postData, navigateToCheckoutComplete)
      );
    },
    [userData, selectedServices, startsAt, dispatch, navigateToCheckoutComplete]
  );

  const handleProcessPayment = useCallback(
    (paymentData: SquarePaymentData) => {
      dispatch(
        ProcessPaymentActions.request(paymentData, handleCreateAppointment)
      );
    },
    [dispatch, handleCreateAppointment]
  );

  return (
    <S.PageContainer>
      <Header />
      <S.ContentConstrainer>
        <HeaderComponent />
        <AppointmentComponent />
        <PaymentDetailsComponent />
        <S.PaymentInstructions>
          {getTranslation("paymentInstructions")}
        </S.PaymentInstructions>

        <SquarePayment
          amount={totalPrice * 0.2}
          isBusy={isBusy}
          isSandbox={process.env.NODE_ENV === "development"}
          applicationId={process.env.REACT_APP_SQUARE_APPLICATION_ID as string}
          locationId={process.env.REACT_APP_SQUARE_LOCATION_ID as string}
          processPayment={handleProcessPayment}
          cancelButton={
            <S.BackButton disabled={isBusy} onClick={handleGoBack}>
              {getTranslation("buttons", "back")}
            </S.BackButton>
          }
        />
      </S.ContentConstrainer>
    </S.PageContainer>
  );
};
