import React, { useCallback } from "react";

import * as S from "./styles";

interface Pagination {
  total: number;
  perPage: number;
  page: number;
  lastPage: number;
}

interface PaginationProps extends React.ComponentProps<any> {
  pagination: Pagination | null;
  onPageChange(page: number): void;
  neighbors?: number;
}

type Props = PaginationProps;

export const Paginator: React.FC<Props> = ({
  pagination,
  onPageChange,
  neighbors = 1,
}) => {
  const ButtonsComponent: React.FC = useCallback(() => {
    if (!pagination) return <></>;

    const { page, lastPage } = pagination;

    const pages = lastPage;
    const targetSize = neighbors * 2;
    const middle = Math.floor(targetSize / 2);
    const paginatorArray: number[] = [];

    if (pages > 1) {
      let inicio = page > middle ? page - middle : 1;
      inicio = inicio > pages - targetSize ? pages - targetSize : inicio;
      inicio = inicio < 1 ? 1 : inicio;
      let t = inicio + targetSize;
      t = pages < t ? pages : t;
      for (let i = inicio; i <= t; i++) paginatorArray.push(i);
    }

    return (
      <>
        {paginatorArray.map((i) => (
          <S.Button active={page === i} key={i} onClick={() => onPageChange(i)}>
            {i}
          </S.Button>
        ))}
      </>
    );
  }, [pagination, neighbors, onPageChange]);

  return (
    <S.Container>
      <ButtonsComponent />
    </S.Container>
  );
};
