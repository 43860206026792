import styled from "styled-components";

import { Button } from "styles/components";
export { Loading } from "styles/components";
export { FormRow } from "styles/form";

export const MainContainer = styled.div.attrs({
  className: "booking-login-or-create-account",
})`
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  border-bottom: 1px solid #b1c0cb;
  padding-bottom: 32px;
  margin-bottom: 24px;
`;

export const HeaderTitle = styled.h2`
  font-size: 30px;
  margin-bottom: 14px;
  color: #091524;
`;

export const HeaderSubtitle = styled.p`
  color: #434e5c;
`;

export const CancelButton = styled(Button).attrs({
  type: "button",
  whileHover: { scale: 1.1 },
  btStyle: "cancel",
})``;

export const SubmitButton = styled(Button).attrs({
  type: "submit",
  whileHover: { scale: 1.1 },
  btStyle: "primary",
})``;

export const TransparentButton = styled(Button).attrs({
  type: "button",
  whileHover: { scale: 1.1 },
  btStyle: "transparent",
})``;

export const ActionButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 56px;

  @media screen and (max-width: 800px) {
    flex-direction: column;
    button:not(:last-child) {
      margin-bottom: 16px;
    }
  }
`;
