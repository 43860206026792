import React, { useCallback, useRef } from "react";

import { FormHandles, SubmitHandler } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";

import { useTranslation, useValidation } from "hooks";
import { Header, Whatsapp, Footer, Input, Textarea } from "components/shared";
import { Banner } from "components/Contact";

import { translations } from "./translations";
import * as S from "./styles";

interface WhatsAppMessageBody {
  name: string;
  phone: string;
  email?: string;
  message: string;
}

const Contact: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const { handleFormErrors } = useValidation();
  const { getTranslation } = useTranslation(translations);

  const sendMessageViaWhatsapp = useCallback(
    (messageBody: WhatsAppMessageBody) => {
      try {
        const { name, phone, message, email } = messageBody;

        const emailPart = email ? `My email is ${email}.\n` : "";

        const whatsappPhone = "7863541690";
        const fullMessage = encodeURIComponent(
          `Hello, I came from your website. I am ${name}.\nMy phone is ${phone}.\n${emailPart}My message: ${message}`.trim()
        );

        window.open(
          `https://wa.me/${whatsappPhone}?text=${fullMessage}`,
          "_blank",
          "noopener"
        );
      } catch (error) {
        console.log("error on sending message", error);
      }
    },
    []
  );

  const handleSubmit: SubmitHandler<WhatsAppMessageBody> = useCallback(
    async (data, { reset }) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required(
            getTranslation("errors", "name", "required")
          ),
          phone: Yup.string().required(
            getTranslation("errors", "phone", "required")
          ),
          message: Yup.string().required(
            getTranslation("errors", "message", "required")
          ),
          email: Yup.string().optional(),
        });

        await schema.validate(data, { abortEarly: false });

        sendMessageViaWhatsapp({
          name: data.name,
          phone: data.phone,
          message: data.message,
          ...(data.email && { email: data.email }),
        });
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [handleFormErrors, sendMessageViaWhatsapp, getTranslation]
  );

  return (
    <S.Container>
      <Whatsapp />
      <Header />
      <Banner />
      <S.Content>
        <S.Subtitle>{getTranslation("subtitle")}</S.Subtitle>
        <S.Title>{getTranslation("title")}</S.Title>
        <S.Description>{getTranslation("description")}</S.Description>
        <S.Subtitle>{getTranslation("sendMessage")}</S.Subtitle>

        <Form ref={formRef} onSubmit={handleSubmit}>
          <Input name="name" label={getTranslation("fields", "name")} />
          <Input name="phone" label={getTranslation("fields", "phone")} />
          <Input
            type="email"
            name="email"
            label={getTranslation("fields", "email")}
          />
          <Textarea
            name="message"
            label={getTranslation("fields", "message")}
          />
          <S.ActionButtons>
            <S.Button whileHover={{ scale: 1.1 }}>
              {getTranslation("buttons", "submit")}
            </S.Button>
          </S.ActionButtons>
        </Form>
      </S.Content>
      <Footer />
    </S.Container>
  );
};

export default Contact;
