import styled from "styled-components";
import { Facebook, Instagram } from "@styled-icons/boxicons-logos";

import { ReactComponent as LogoSvg } from "assets/images/logo.svg";

export const Container = styled.footer`
  width: 100%;
  max-width: 1920px;
  background-color: #f4edea;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 72px 32px;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding-bottom: 40px;
  margin-bottom: 40px;
  border-bottom: 1px #c4c4c4 solid;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;

  @media screen and (max-width: 680px) {
    flex-direction: column;
  }
`;

export const Column = styled.div`
  margin-right: 24px;
  margin-bottom: 32px;

  &.about {
    width: 320px;
  }

  &:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }

  @media screen and (max-width: 1000px) {
    &.about {
      width: 100%;
    }
  }
`;

export const Logo = styled(LogoSvg)`
  margin-bottom: 32px;
`;

export const About = styled.article`
  font-size: 17px;
  line-height: 24px;
  color: #1a1a1a;
`;

export const Title = styled.h3`
  color: #ac7a40;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 18px;
  margin-bottom: 16px;
`;

export const MenuButton = styled.button.attrs({ type: "button" })`
  display: flex;
  width: 100%;
  font-size: 17px;
  font-family: "BrandontextMedium";
  color: #1a1a1a;
  margin-bottom: 16px;
  transition: 300ms ease;

  &:hover {
    color: #de0048;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Week = styled.span`
  font-size: 17px;
  color: #454545;
  display: block;
  margin-bottom: 8px;
`;

export const Address = styled.article`
  font-size: 17px;
  line-height: 24px;
  color: #454545;
  width: 250px;
`;

export const MapButton = styled.a.attrs({
  href: "https://g.page/bnstudiosalon?share",
  target: "_blank",
})`
  color: #de0048;
  display: flex;
  margin: 16px 0;
  transition: 300ms ease;
  text-transform: uppercase;

  font-family: "GothamBold";
  font-size: 12px;

  &:hover {
    color: #1a1a1a;
  }
`;

export const Social = styled.div`
  display: flex;
  margin-top: 24px;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1a1a1a;
    padding: 16px;
    margin-right: 16px;
    transition: 300ms ease;

    &:hover {
      background-color: #de0048;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const IconFacebook = styled(Facebook).attrs({ size: 20 })`
  color: #fff;
`;

export const IconInstagram = styled(Instagram).attrs({ size: 20 })`
  color: #fff;
`;

export const Developer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;

  span {
    font-size: 14px;
    color: #1a1a1a;

    a {
      text-decoration: underline;
      color: #1a1a1a;

      &:hover {
        color: #de0048;
      }
    }
  }

  @media screen and (max-width: 580px) {
    flex-direction: column;
  }
`;
