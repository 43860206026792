import React, { useCallback } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";

import { LanguageActions, LanguageState } from "store/ducks/language";

import * as S from "./styles";

export const LanguageSelector: React.FC = () => {
  const dispatch = useDispatch();
  const { language } = useSelector<RootStateOrAny>(
    (state) => state.language
  ) as LanguageState;

  const handleLaguage = useCallback(
    (lang: string) => {
      dispatch(LanguageActions.setLanguage(lang));
    },
    [dispatch]
  );

  return (
    <S.Container>
      <S.Link onClick={() => handleLaguage("en")} active={language === "en"}>
        EN
      </S.Link>
      <S.Link onClick={() => handleLaguage("es")} active={language === "es"}>
        ES
      </S.Link>
      <S.Link onClick={() => handleLaguage("pt")} active={language === "pt"}>
        PT
      </S.Link>
    </S.Container>
  );
};

export const MobileLanguageSelector: React.FC = () => {
  const dispatch = useDispatch();
  const { language } = useSelector<RootStateOrAny>(
    (state) => state.language
  ) as LanguageState;

  const handleLaguage = useCallback(
    (lang: string) => {
      dispatch(LanguageActions.setLanguage(lang));
    },
    [dispatch]
  );

  return (
    <S.MobileContainer>
      <S.Link onClick={() => handleLaguage("en")} active={language === "en"}>
        EN
      </S.Link>
      <S.Link onClick={() => handleLaguage("es")} active={language === "es"}>
        ES
      </S.Link>
      <S.Link onClick={() => handleLaguage("pt")} active={language === "pt"}>
        PT
      </S.Link>
    </S.MobileContainer>
  );
};
