export const translations = {
  title: {
    pt: "Precisa de informações sobre nosso estúdio nossos compromissos?",
    en: "Need information about our studio our appointments?",
    es: "¿Necesitas información sobre nuestro estudio nuestras citas?",
  },
  subtitle: {
    pt: "Números de telefone",
    en: "Phone numbers",
    es: "Números de teléfono",
  },
  description: {
    pt: "Ligue para nós: (305) 603-9906 WhatsApp (786) 354-1690",
    en: "Give us a call on: (305) 603-9906 WhatsApp (786) 354-1690",
    es: "Danos una llamada al: (305) 603-9906 WhatsApp (786) 354-1690",
  },
  sendMessage: {
    pt: "ou envie-nos uma mensagem",
    en: "or send us a message",
    es: "o envíanos un mensaje",
  },

  fields: {
    name: {
      en: "Your name:",
      pt: "Seu nome:",
      es: "Tu nombre:",
    },
    phone: {
      en: "Phone number:",
      pt: "Telefone",
      es: "Teléfono:",
    },
    email: {
      en: "Email (optional):",
      pt: "E-mail (opcional):",
      es: "Email (opcional):",
    },
    message: {
      en: "Your message:",
      pt: "Sua mensagem:",
      es: "Tu mensaje:",
    },
  },
  errors: {
    name: {
      required: {
        en: "Your name:",
        pt: "Seu nome:",
        es: "Tu nombre:",
      },
    },
    phone: {
      required: {
        en: "Phone number:",
        pt: "Telefone",
        es: "Teléfono:",
      },
    },
    email: {
      required: {
        en: "Email (optional):",
        pt: "E-mail (opcional):",
        es: "Email (opcional):",
      },
    },
    message: {
      required: {
        en: "Your message:",
        pt: "Sua mensagem:",
        es: "Tu mensaje:",
      },
    },
  },
  buttons: {
    submit: {
      en: "SEND MESSAGE",
      pt: "ENVIAR MENSAGEM",
      es: "ENVIAR MENSAJE",
    },
  },
};
