import { createReducer, createActions } from "reduxsauce";
import update from "immutability-helper";

export interface ProcessPaymentState {
  loading: boolean;
  error: string | null;
}

interface PaymentData extends Record<string, any> {}

export interface ProcessPaymentRequest {
  paymentData: PaymentData;
  onSuccess?(param: any): void;
  onFailure?(): void;
}

interface SuccessAction {}

interface FailureAction {
  error: string;
}

const { Types, Creators } = createActions(
  {
    request: ["paymentData", "onSuccess", "onFailure"],
    success: [],
    failure: ["error"],
    reset: [],
  },
  { prefix: "PROCESS_PAYMENT_" }
);

const INITIAL_STATE: ProcessPaymentState = {
  loading: false,
  error: null,
};

const request = (state: ProcessPaymentState, action: ProcessPaymentRequest) =>
  update(state, {
    loading: { $set: true },
    error: { $set: null },
  });

const success = (state: ProcessPaymentState, action: SuccessAction) =>
  update(state, {
    loading: { $set: false },
  });

const failure = (state: ProcessPaymentState, action: FailureAction) =>
  update(state, {
    loading: { $set: false },
    error: { $set: action.error },
  });

const reset = () => INITIAL_STATE;

export const processPayment = createReducer(INITIAL_STATE, {
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success,
  [Types.FAILURE]: failure,
  [Types.RESET]: reset,
});

export const ProcessPaymentTypes = Types;
export const ProcessPaymentActions = Creators;
