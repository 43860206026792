import React, { useEffect, useCallback } from "react";
import { Element, scroller } from "react-scroll";
import { useParams } from "react-router-dom";

import { Header, Whatsapp, Footer } from "components/shared";
import { Banner, About, Services, OurWork } from "components/Main";
import * as S from "./styles";

interface ParamTypes {
  slug: string;
}

const Home: React.FC = () => {
  const { slug } = useParams<ParamTypes>();
  const handleMenu = useCallback((element: string) => {
    scroller.scrollTo(element, {
      duration: 400,
      delay: 0,
      offset: -90,
    });
  }, []);

  useEffect(() => {
    handleMenu(slug);
  }, [handleMenu, slug]);
  return (
    <S.Container>
      <Whatsapp />
      <Header />
      <Banner />
      <About />
      <Element name="Services" />
      <Services />
      <Element name="OurWork" />
      <OurWork />
      <Footer />
    </S.Container>
  );
};

export default Home;
