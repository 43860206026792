import { call, put } from "redux-saga/effects";

import api from "services/api";
import { notify } from "services/notification";
import { requestErrorHandler } from "utils";

import {
  ProcessPaymentRequest,
  ProcessPaymentActions,
} from "store/ducks/payment";

export function* processPaymentRequest(action: any) {
  try {
    const { paymentData, onSuccess } = action as ProcessPaymentRequest;

    const { data: responseData } = yield call(
      api.post,
      "payment/process-payment",
      paymentData
    );

    const { payment_token } = responseData;

    if (onSuccess) onSuccess({ payment_token });
    notify("success", "We received your payment");
    yield put(ProcessPaymentActions.success());
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(ProcessPaymentActions.failure(errorMessage));
  }
}
