import React, { useCallback, useRef, useEffect } from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import { useHistory } from "react-router-dom";
import { FormHandles, SubmitHandler } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";

import { useTranslation, useValidation } from "hooks";
import { AuthState } from "store/ducks/auth";
import {
  UpdateAccountActions,
  UpdateAccountRequestBody,
  UpdateAccountState,
} from "store/ducks/account";
import { Header, Footer, Input } from "components/shared";

import { translations } from "./translations";
import * as S from "./styles";

interface UpdateFormData extends UpdateAccountRequestBody {
  confirm_password?: string;
}

export const UpdateAccountPage: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const { getTranslation } = useTranslation(translations);
  const { handleFormErrors } = useValidation();

  const { data: userData } = useSelector<RootStateOrAny>(
    (state) => state.auth
  ) as AuthState;

  const { loading: updatingAccount } = useSelector<RootStateOrAny>(
    (state) => state.updateAccount
  ) as UpdateAccountState;

  const onUpdateAccountSuccess = useCallback(() => {
    history.push("/");
  }, [history]);

  const handleSubmit: SubmitHandler<UpdateFormData> = useCallback(
    async (data, { reset }) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          first_name: Yup.string().required(
            getTranslation("errors", "firstName", "required")
          ),
          last_name: Yup.string().required(
            getTranslation("errors", "lastName", "required")
          ),
          phone: Yup.string().required(
            getTranslation("errors", "phone", "required")
          ),
          password: Yup.string().optional(),
          confirm_password: Yup.string().test(
            "test_confirm_pwd",
            getTranslation("errors", "confirmPassword", "matches"),
            function (value: string | undefined): boolean {
              return this?.parent?.password === value;
            }
          ),
        });

        await schema.validate(data, { abortEarly: false });

        const clientId = userData?.client_id;

        const putData: UpdateAccountRequestBody = {
          first_name: data.first_name,
          last_name: data.last_name,
          phone: data.phone,
          ...(data.password && { password: data.password }),
        };

        dispatch(
          UpdateAccountActions.request(
            clientId,
            putData,
            onUpdateAccountSuccess
          )
        );
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [
      handleFormErrors,
      onUpdateAccountSuccess,
      dispatch,
      userData,
      getTranslation,
    ]
  );

  const onUserDataLoaded = useCallback((): void => {
    if (!userData) return;
    const { first_name, last_name, phone } = userData;
    formRef.current?.setFieldValue("first_name", first_name);
    formRef.current?.setFieldValue("last_name", last_name);
    formRef.current?.setFieldValue("phone", phone);
  }, [userData]);

  useEffect(() => {
    onUserDataLoaded();
  }, [onUserDataLoaded]);

  return (
    <S.PageContainer>
      <Header />
      <S.MainContainer>
        <S.Title>{getTranslation("title")}</S.Title>
        <S.Description>{getTranslation("description")}</S.Description>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Input
            name="first_name"
            label={getTranslation("fields", "firstName")}
          />

          <Input
            name="last_name"
            label={getTranslation("fields", "lastName")}
          />

          <Input name="phone" label={getTranslation("fields", "phone")} />

          <S.FormSection>{getTranslation("changePassword")}</S.FormSection>

          <Input
            type="password"
            name="password"
            label={getTranslation("fields", "password")}
          />
          <Input
            type="password"
            name="confirm_password"
            label={getTranslation("fields", "confirmPassword")}
          />
          <S.ActionButtons>
            <S.Button btStyle="primary" whileHover={{ scale: 1.1 }}>
              {(updatingAccount && <S.Loading />) ||
                getTranslation("buttons", "submit")}
            </S.Button>
          </S.ActionButtons>
        </Form>
      </S.MainContainer>
      <Footer />
    </S.PageContainer>
  );
};
