import { call, put } from "redux-saga/effects";

import api from "services/api";
import {
  FindTimeframesRequest,
  FindTimeframesActions,
} from "store/ducks/booking";

import { requestErrorHandler } from "utils";

export function* findTimeframesRequest(action: any) {
  try {
    const { postData, onSuccess } = action as FindTimeframesRequest;

    const url = "/appointment/find-available-timeframes";
    const { data: responseData } = yield call(api.post, url, postData);

    const { data, ...extraData } = responseData;
    const usefulData = data.filter((i: any) => {
      const { timeframes } = i;
      return timeframes.length > 0;
    });
    if (onSuccess) onSuccess();
    yield put(FindTimeframesActions.success(usefulData, extraData));
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    const { onFailure } = action as FindTimeframesRequest;
    if (onFailure) onFailure();
    yield put(FindTimeframesActions.failure(errorMessage));
  }
}
