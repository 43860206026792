import React, { useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import Menu from "./Menu";
import { UserWidget } from "./UserWidget";
import { LanguageSelector } from "./LanguageSelector";
import * as S from "./styles";

const Header: React.FC = () => {
  const history = useHistory();

  const handleNavigation = useCallback((route): void => {
    // history.push(route);
    window.open(route, "_blank");
  }, []);

  return (
    <S.Container>
      <Link to="/">
        <S.Logo />
      </Link>
      <S.Wrapper>
        <Menu />
        <S.BookButton
          onClick={() =>
            handleNavigation("https://api.whatsapp.com/send?phone=+17863541690")
          }
        >
          <S.CalendarIcon />
          <FormattedMessage id="menu.booknow" />
        </S.BookButton>
        <LanguageSelector />
        <UserWidget />
      </S.Wrapper>
    </S.Container>
  );
};

export default Header;
