import styled from "styled-components";

export const Container = styled.section`
  width: 100%;
  margin: 0 auto;
  position: relative;
`;

export const Background = styled.div`
  width: 100%;
  margin: 0 auto;
  background-color: #f4edea;
`;
export const Content = styled.div`
  width: 100%;
  max-width: 1250px;
  padding: 96px 32px;
  margin: 0 auto;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  :first-child {
    padding-top: 64px;
  }

  @media screen and (max-width: 414px) {
    &.last {
      padding: 0;
    }
  }
`;

export const Subtitle = styled.h5`
  font-size: 16px;
  text-transform: uppercase;
  color: #de0048;
  margin-bottom: 32px;
`;

export const Title = styled.h1`
  font-size: 36px;
  line-height: 40px;
  color: #1a1a1a;
  margin-bottom: 40px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 590px;

  @media screen and (max-width: 414px) {
    font-size: 30px;
    line-height: 30px;
  }
`;

export const Text = styled.article`
  font-size: 18px;
  line-height: 24px;
  color: #1a1a1a;
  text-align: center;
  max-width: 590px;

  P {
    margin-bottom: 24px;
  }

  @media screen and (max-width: 414px) {
    font-size: 16px;
  }
`;
