import React, { useCallback, useMemo, useRef, useState } from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import { FormHandles, SubmitHandler } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";

import { AuthActions, AuthState } from "store/ducks/auth";
import {
  CreateAccountState,
  CreateAccountActions,
  CreateAccountPostData,
} from "store/ducks/account";
import { useTranslation, useValidation } from "hooks";
import { Input } from "components/shared";
import { translations } from "./translations";
import * as S from "./styles";
import {
  GenerateRecoveryCodeActions,
  GenerateRecoveryCodeState,
  UpdatePasswordActions,
  UpdatePasswordState,
} from "store/ducks/passwordRecovery";

interface LoginOrCreateAccountProps extends React.ComponentProps<any> {
  loginHeader?: JSX.Element;
  createAccountHeader?: JSX.Element;
  generateRecoveryCodeHeader?: JSX.Element;
  updatePasswordHeader?: JSX.Element;
}

type Props = LoginOrCreateAccountProps;

type ActiveView = "login" | "account" | "generateCode" | "updatePassword";

export const LoginOrCreateAccount: React.FC<Props> = ({
  loginHeader = <></>,
  createAccountHeader = <></>,
  generateRecoveryCodeHeader = <></>,
  updatePasswordHeader = <></>,
}) => {
  const loginFormRef = useRef<FormHandles>(null);
  const createAccountFormRef = useRef<FormHandles>(null);
  const generateCodeFormRef = useRef<FormHandles>(null);
  const updatePasswordFormRef = useRef<FormHandles>(null);

  const dispatch = useDispatch();
  const { getTranslation } = useTranslation(translations);
  const { handleFormErrors } = useValidation();

  const { loading: loggingIn } = useSelector<RootStateOrAny, AuthState>(
    (state) => state.auth
  );

  const { loading: creatingAccount } = useSelector<
    RootStateOrAny,
    CreateAccountState
  >((state) => state.createAccount);

  const { loading: generatingRecoveryCode } = useSelector<
    RootStateOrAny,
    GenerateRecoveryCodeState
  >((state) => state.generateRecoveryCode);

  const { loading: updatingPassword } = useSelector<
    RootStateOrAny,
    UpdatePasswordState
  >((state) => state.updatePassword);

  const isBusy = useMemo(() => {
    return [
      creatingAccount,
      loggingIn,
      generatingRecoveryCode,
      updatingPassword,
    ].includes(true);
  }, [creatingAccount, generatingRecoveryCode, loggingIn, updatingPassword]);

  const [activeView, setActiveView] = useState<ActiveView>("login");
  const [phoneToRecoverPWD, setPhoneToRecoverPWD] = useState<string | null>(
    null
  );

  const handleLoginSubmit: SubmitHandler = useCallback(
    async (data, { reset }) => {
      try {
        loginFormRef.current?.setErrors({});
        const schema = Yup.object().shape({
          phone: Yup.string().required(
            getTranslation("errors", "phone", "required")
          ),
          password: Yup.string().required(
            getTranslation("errors", "password", "required")
          ),
        });
        const validData = await schema.validate(data, {
          abortEarly: false,
        });
        dispatch(AuthActions.loginRequest(validData, () => reset()));
      } catch (error) {
        handleFormErrors(error, loginFormRef);
      }
    },
    [handleFormErrors, getTranslation, dispatch]
  );

  const handleCreateAccountSubmit: SubmitHandler = useCallback(
    async (data, { reset }) => {
      try {
        createAccountFormRef.current?.setErrors({});

        const schema = Yup.object().shape({
          first_name: Yup.string().required(
            getTranslation("errors", "firstName", "required")
          ),
          last_name: Yup.string().required(
            getTranslation("errors", "lastName", "required")
          ),
          phone: Yup.string().required(
            getTranslation("errors", "phone", "required")
          ),
          email: Yup.string()
            .email(getTranslation("errors", "email", "invalid"))
            .optional(),
          password: Yup.string().required(
            getTranslation("errors", "password", "required")
          ),
          confirm_password: Yup.string().test(
            "test_confirm_pwd",
            getTranslation("errors", "confirmPassword", "test"),
            function (value: string | undefined): boolean {
              return this?.parent?.password === value;
            }
          ),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        const postData: CreateAccountPostData = {
          email: data.email,
          first_name: data.first_name,
          last_name: data.last_name,
          phone: data.phone.replace(/![0-9]/, ""),
          password: data.password,
          register_origin: "Site",
        };

        dispatch(CreateAccountActions.request(postData, () => reset()));
      } catch (error) {
        handleFormErrors(error, createAccountFormRef);
      }
    },
    [handleFormErrors, getTranslation, dispatch]
  );

  const onGenerateRecoveryCodeSuccess = useCallback(
    (email) => {
      setActiveView("updatePassword");
      setPhoneToRecoverPWD(email);
    },
    [setActiveView, setPhoneToRecoverPWD]
  );

  const handleGenerateRecoveryCodeSubmit: SubmitHandler = useCallback(
    async (data, { reset }) => {
      try {
        generateCodeFormRef.current?.setErrors({});

        const schema = Yup.object().shape({
          phone: Yup.string().required(
            getTranslation("errors", "phone", "required")
          ),
        });

        const validData = await schema.validate(data, { abortEarly: false });

        dispatch(
          GenerateRecoveryCodeActions.request(validData, () => {
            onGenerateRecoveryCodeSuccess(validData.phone);
            reset();
          })
        );
      } catch (error) {
        handleFormErrors(error, generateCodeFormRef);
      }
    },
    [handleFormErrors, onGenerateRecoveryCodeSuccess, getTranslation, dispatch]
  );

  const onUpdatePasswordSuccess = useCallback(() => {
    setActiveView("login");
  }, [setActiveView]);

  const handleUpdatePasswordSubmit: SubmitHandler = useCallback(
    async (data): Promise<void> => {
      try {
        updatePasswordFormRef.current?.setErrors({});
        const schema = Yup.object().shape({
          password_recovery_code: Yup.string().required(
            getTranslation("errors", "passwordRecoveryCode", "required")
          ),
          password: Yup.string().required(
            getTranslation("errors", "password", "required")
          ),
          confirm_password: Yup.string().test(
            "test_confirm_pwd",
            getTranslation("errors", "confirmPassword", "matches"),
            function (value: string | undefined): boolean {
              return this?.parent?.password === value;
            }
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const postData = {
          phone: phoneToRecoverPWD,
          password: data.password,
          password_recovery_code: data.password_recovery_code,
        };
        dispatch(
          UpdatePasswordActions.request(postData, onUpdatePasswordSuccess)
        );
      } catch (error) {
        handleFormErrors(error, updatePasswordFormRef);
      }
    },
    [
      handleFormErrors,
      onUpdatePasswordSuccess,
      getTranslation,
      dispatch,
      phoneToRecoverPWD,
    ]
  );

  const LoginComponent = useCallback((): JSX.Element => {
    return (
      <>
        {loginHeader}
        <Form ref={loginFormRef} onSubmit={handleLoginSubmit}>
          <S.FormRow>
            <Input name="phone" label={getTranslation("fields", "phone")} />
          </S.FormRow>
          <S.FormRow>
            <Input
              type="password"
              name="password"
              label={getTranslation("fields", "password")}
            />
          </S.FormRow>
          <S.TransparentButton onClick={() => setActiveView("generateCode")}>
            {getTranslation("buttons", "forgotPassword")}
          </S.TransparentButton>
          <S.ActionButtons>
            <S.CancelButton onClick={() => setActiveView("account")}>
              {getTranslation("buttons", "createAccount")}
            </S.CancelButton>
            <S.SubmitButton>
              {(isBusy && <S.Loading />) || getTranslation("buttons", "submit")}
            </S.SubmitButton>
          </S.ActionButtons>
        </Form>
      </>
    );
  }, [getTranslation, handleLoginSubmit, isBusy, loginHeader]);

  const AccountComponent = useCallback((): JSX.Element => {
    return (
      <>
        {createAccountHeader}
        <Form ref={createAccountFormRef} onSubmit={handleCreateAccountSubmit}>
          <S.FormRow>
            <Input
              name="first_name"
              label={getTranslation("fields", "firstName")}
            />
            <Input
              name="last_name"
              label={getTranslation("fields", "lastName")}
            />
          </S.FormRow>
          <Input name="email" label={getTranslation("fields", "email")} />

          <Input
            type="number"
            name="phone"
            label={getTranslation("fields", "phone")}
          />

          <S.FormRow>
            <Input
              type="password"
              name="password"
              label={getTranslation("fields", "password")}
            />
            <Input
              type="password"
              name="confirm_password"
              label={getTranslation("fields", "confirmPassword")}
            />
          </S.FormRow>
          <S.ActionButtons>
            <S.CancelButton onClick={() => setActiveView("login")}>
              {getTranslation("buttons", "back")}
            </S.CancelButton>
            <S.SubmitButton>
              {(isBusy && <S.Loading />) || getTranslation("buttons", "submit")}
            </S.SubmitButton>
          </S.ActionButtons>
        </Form>
      </>
    );
  }, [getTranslation, handleCreateAccountSubmit, isBusy, createAccountHeader]);

  const GenerateCodeComponent = useCallback((): JSX.Element => {
    return (
      <>
        {generateRecoveryCodeHeader}
        <Form
          ref={generateCodeFormRef}
          onSubmit={handleGenerateRecoveryCodeSubmit}
        >
          <S.FormRow>
            <Input
              type="text"
              name="phone"
              label={getTranslation("fields", "phone")}
            />
          </S.FormRow>
          <S.ActionButtons>
            <S.CancelButton onClick={() => setActiveView("login")}>
              {getTranslation("buttons", "back")}
            </S.CancelButton>
            <S.SubmitButton>
              {(isBusy && <S.Loading />) || getTranslation("buttons", "submit")}
            </S.SubmitButton>
          </S.ActionButtons>
        </Form>
      </>
    );
  }, [
    getTranslation,
    handleGenerateRecoveryCodeSubmit,
    isBusy,
    generateRecoveryCodeHeader,
  ]);

  const UpdatePasswordComponent = useCallback((): JSX.Element => {
    return (
      <>
        {updatePasswordHeader}
        <Form ref={updatePasswordFormRef} onSubmit={handleUpdatePasswordSubmit}>
          <S.FormRow>
            <Input
              name="password_recovery_code"
              label={getTranslation("fields", "passwordRecoveryCode")}
            />
          </S.FormRow>
          <S.FormRow>
            <Input
              type="password"
              name="password"
              label={getTranslation("fields", "newPassword")}
            />
            <Input
              type="password"
              name="confirm_password"
              label={getTranslation("fields", "confirmPassword")}
            />
          </S.FormRow>
          <S.ActionButtons>
            <S.CancelButton onClick={() => setActiveView("generateCode")}>
              {getTranslation("buttons", "back")}
            </S.CancelButton>
            <S.SubmitButton>
              {(isBusy && <S.Loading />) || getTranslation("buttons", "submit")}
            </S.SubmitButton>
          </S.ActionButtons>
        </Form>
      </>
    );
  }, [
    getTranslation,
    handleUpdatePasswordSubmit,
    isBusy,
    updatePasswordHeader,
  ]);

  return (
    <S.MainContainer>
      {activeView === "login" && <LoginComponent />}
      {activeView === "account" && <AccountComponent />}
      {activeView === "generateCode" && <GenerateCodeComponent />}
      {activeView === "updatePassword" && <UpdatePasswordComponent />}
    </S.MainContainer>
  );
};
