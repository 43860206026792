import { createGlobalStyle } from "styled-components";

import BrandontextBold from "assets/fonts/brandontext-bold.ttf";
import BrandontextMedium from "assets/fonts/brandontext-medium.ttf";
import BrandontextRegular from "assets/fonts/brandontext-regular.ttf";
import GothamBold from "assets/fonts/gotham_bold.ttf";
import GothamBook from "assets/fonts/gotham_book.ttf";

const GlobalStyle = createGlobalStyle`

  @font-face {
    font-family: "BrandontextBold";
    src: url(${BrandontextBold});
  }

  @font-face {
    font-family: "BrandontextMedium";
    src: url(${BrandontextMedium});
  }

  @font-face {
    font-family: "BrandontextRegular";
    src: url(${BrandontextRegular});
  }

  @font-face {
    font-family: "GothamBold";
    src: url(${GothamBold});
  }

  @font-face {
    font-family: "GothamBook";
    src: url(${GothamBook});
  }


  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
  }

  html,
  body,
  #root {
    height: 100%;
    scroll-behavior: smooth;
  }

  body {
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    background:#F5F5F5;
    font-family: "BrandontextRegular";
    font-size: 16px;
    color: #606060;
    font-weight: normal;
    overflow-x: hidden;
  }

  a {
    text-decoration: none;
  }

  button {
    font-family: "GothamBold";
    border: 0;
    cursor: pointer;
    background-color: transparent;
  }

  input, textarea {
    font-family: "BrandontextRegular";
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: "GothamBold";
    font-size: 40px;
    line-height: 48px;
  }

  ul {
    list-style: none;
  }

  .toastsuccess {
    border-radius: 6px;
    background-color: #39254d !important;
  }

  .Toastify__toast--error {
    border-radius: 6px;
    background-color: #ec1751 !important;
  }

  .Toastify__toast-body {
    font-family: "GothamBold" !important;
    font-size: 14px;
    padding: 0 16px;
  }

  .icon-spin {
    animation: iconSpin 2s infinite linear;
  }

  @keyframes iconSpin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }
`;

export default GlobalStyle;
