import styled from "styled-components";
import { Link as _Link } from "react-router-dom";

export { PageContainer } from "styles/components";

// If not logged in
export const AuthContainer = styled.div`
  max-width: 520px;
  margin: 0 auto;
  padding: 24px;
`;

export const AuthContentConstrainer = styled.div`
  padding: 76px 0;
`;

export const AuthHeader = styled.div`
  margin: 0 auto;
  max-width: 420px;
  text-align: center;
  h4 {
    font-size: 16px;
    text-transform: uppercase;
    font-family: "GothamBold";
    color: #de0048;
    margin-bottom: 24px;
  }
  h1 {
    font-size: 36px;
    font-family: "GothamBold";
    color: #1a1a1a;
    margin-bottom: 24px;
  }
`;

export const AuthSubheader = styled.div`
  text-align: center;
  margin-bottom: 32px;
`;

// If logged in

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
`;

export const Sidebar = styled.div`
  background-color: #1a1a1a;
  min-height: calc(100vh - 111px);
  max-width: 500px;
  width: 100%;
  @media screen and (max-width: 800px) {
    margin-top: 32px;
    max-width: 800px;
    min-height: auto;
    order: 2;
  }
`;

export const PaginatorContainer = styled.div`
  padding: 0 52px;
`;

export const MainContainer = styled.div`
  padding-top: 72px;
  padding-left: 98px;
  padding-right: 24px;
  flex: 1 1 100%;
  @media screen and (max-width: 1100px) {
    padding-left: 24px;
  }
`;

export const LinksContainer = styled.div`
  padding: 52px 24px 24px 24px;
`;

export const Links = styled.div`
  border-bottom: 1px solid #4b4b4b;
  padding: 0px 26px 24px 26px;
`;

export const Link = styled(_Link)`
  font-family: "GothamBold";
  font-size: 16px;
  color: #ffffff;
  transition: 300ms ease;
  &:hover {
    color: #de0048;
  }
`;
