import { combineReducers } from "redux";

import { auth } from "./auth";
import { createAccount, updateAccount } from "./account";
import {
  appointment,
  createAppointment,
  nextAppointments,
  paginateAppointments,
} from "./appointment";
import { lastHomeBanner } from "./banners";
import { findTimeframes } from "./booking";
import { bookingPage } from "./bookingPage";
import { cart } from "./cart";
import { language } from "./language";
import { processPayment } from "./payment";
import { generateRecoveryCode, updatePassword } from "./passwordRecovery";
import { membersByService } from "./utils";

const reducers = combineReducers({
  auth,
  createAccount,
  updateAccount,
  appointment,
  createAppointment,
  nextAppointments,
  paginateAppointments,
  language,
  lastHomeBanner,
  findTimeframes,
  bookingPage,
  cart,
  processPayment,
  generateRecoveryCode,
  updatePassword,
  membersByService,
});

export default reducers;
