import styled from "styled-components";
import { Check2 } from "@styled-icons/bootstrap/Check2";

export { PageContainer, Loading, Button, LinkButton } from "styles/components";

export const ContentConstrainer = styled.div`
  margin: 32px auto;
  max-width: 680px;
  width: 100%;
  padding: 0 24px;
`;

// header

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 28px;

  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
`;

export const HeaderStatus = styled.div`
  border: 2px solid #0ba86d;
  height: 64px;
  width: 64px;
  border-radius: 32px;
  display: flex;
  flex: 0 0 64px;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
`;

export const ConfirmedIcon = styled(Check2).attrs({
  size: 32,
})`
  color: #0ba86d;
`;

export const HeaderTitle = styled.div`
  flex: 0 1 auto;
  h2 {
    font-size: 16px;
    font-family: "GothamBold";
    text-transform: uppercase;
    color: #1a1a1a;
    line-height: 30px;
  }
  h1 {
    font-family: "GothamBook";
    color: #0ba86d;
    font-size: 30px;
    line-height: 40px;
  }
  @media screen and (max-width: 800px) {
    margin-top: 24px;
    text-align: center;
  }
`;

// appointment

export const Appointment = styled.div`
  border-top: 2px solid #cad5dd;
  border-bottom: 2px solid #cad5dd;
  padding: 32px 0;
  color: #091524;
  @media screen and (min-width: 601px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 32px;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ColumnLabel = styled.div`
  font-size: 16px;
  font-family: "BrandontextRegular";
  margin-bottom: 12px;
`;

export const ColumnValue = styled.div`
  font-size: 18px;
  font-family: "BrandontextMedium";
  margin-bottom: 8px;
`;

export const DateColumnValue = styled.div`
  font-size: 24px;
  font-family: "BrandontextMedium";
  margin-bottom: 8px;
`;

// payment

export const PaymentDetails = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 18px;
  padding: 24px 0;
  color: #091524;

  @media screen and (min-width: 801px) {
    div.second-column {
      text-align: right;
    }
  }

  @media screen and (max-width: 800px) {
    flex-direction: column;
    div.second-column {
      margin-top: 24px;
    }
  }
`;

export const TotalPrice = styled(ColumnValue)`
  margin-bottom: 12px;
`;

export const Fee = styled.div`
  span {
    color: #0ba86d;
    font-family: "BrandontextBold";
  }
`;

export const PendingPayment = styled(TotalPrice)`
  span {
    color: #de0048;
  }
`;

export const PayOnAppointmentDate = styled.div``;

export const FooterActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 48px 0;

  @media screen and (min-width: 801px) {
    button {
      margin-left: auto;
    }
  }

  @media screen and (max-width: 800px) {
    flex-direction: column;
    button {
      margin: 16px 0px;
    }
  }
`;

export const MoreInfo = styled.div`
  padding: 0px 32px;
  @media screen and (max-width: 800px) {
    order: 2;
    padding: 0px 0px;
  }
`;
