import { call, put } from "redux-saga/effects";

import api from "services/api";
import { notify } from "services/notification";
import { LastHomeBannerActions as Actions } from "store/ducks/banners";
import { requestErrorHandler } from "utils";

export function* lastHomeBannerRequest() {
  try {
    const url = "banners/last-home-banner";
    const { data } = yield call(api.get, url);

    yield put(Actions.success(data));
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(Actions.failure(errorMessage));
  }
}
