import React from "react";
import { Switch } from "react-router-dom";

import MainRoutes from "./main";

const Routes: React.FC = () => {
  return (
    <Switch>
      <MainRoutes />
    </Switch>
  );
};

export default Routes;
