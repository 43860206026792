import { call, put } from "redux-saga/effects";

import api from "services/api";
import { notify } from "services/notification";
import { requestErrorHandler, queryBuilder } from "utils";
import {
  MembersByServiceActions,
  MembersByServiceRequest,
} from "store/ducks/utils";

export function* fetchMembersByServiceRequest(action: any) {
  try {
    const { query = {}, onSuccess } = action as MembersByServiceRequest;
    const queryString = queryBuilder(query);
    const url = `team-service/members-by-service?${queryString}`;
    const { data } = yield call(api.get, url);
    if (onSuccess) onSuccess();
    yield put(MembersByServiceActions.success(data));
  } catch (error) {
    const { onFailure } = action as MembersByServiceRequest;
    const { errorMessage } = requestErrorHandler(error);
    if (onFailure) onFailure();
    notify("error", errorMessage);
    yield put(MembersByServiceActions.failure(errorMessage));
  }
}
