import styled from "styled-components";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Button = styled.button.attrs({ type: "button" })`
  font-size: 14px;
  text-transform: uppercase;
  color: #000;
  margin-right: 32px;
  margin-left: 32px;
  transition: 300ms ease;
  &:hover {
    color: #de0048;
  }
`;
