import styled from "styled-components";
import { Button } from "styles/components";
import { Calendar } from "@styled-icons/feather";

import salon from "assets/images/home-about-salon.png";

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  position: relative;
`;

export const Background = styled.div`
  width: 100%;
  height: 950px;
  background-color: #f4edea;
  position: absolute;
  z-index: 1;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1250px;
  padding: 0 32px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  :first-child {
    padding-top: 64px;
  }

  @media screen and (max-width: 414px) {
    &.last {
      padding: 0;
    }
  }
`;

export const Subtitle = styled.h5`
  font-size: 16px;
  text-transform: uppercase;
  color: #de0048;
  margin-bottom: 32px;
`;

export const Title = styled.h1`
  font-size: 36px;
  line-height: 40px;
  color: #1a1a1a;
  margin-bottom: 40px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 590px;

  &::after {
    content: "";
    display: block;
    background-color: #c3bebe;
    width: 140px;
    height: 5px;
    margin-top: 40px;
  }

  @media screen and (max-width: 414px) {
    font-size: 30px;
    line-height: 30px;
  }
`;

export const Text = styled.article`
  font-size: 18px;
  line-height: 24px;
  color: #1a1a1a;
  text-align: center;
  max-width: 590px;

  P {
    margin-bottom: 24px;
  }

  @media screen and (max-width: 414px) {
    font-size: 16px;
  }
`;

export const KnowButton = styled(Button)`
  text-transform: uppercase;
  font-size: 14px;
  transition: 300ms ease;

  &:hover {
    color: #de0048;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  margin: 72px 0 96px 0;
  position: relative;

  @media screen and (max-width: 414px) {
    margin-bottom: 0;
  }
`;

export const Photo = styled.img.attrs({ src: salon })`
  width: 100%;
  height: 100%;
  max-width: max-content;

  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const Opening = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #1a1a1a;
  padding: 72px 96px;
  position: absolute;
  right: 0;
  top: 144px;
  width: 100%;
  max-width: 590px;

  @media screen and (max-width: 800px) {
    position: relative;
    right: auto;
    top: auto;
    max-width: 100%;
  }

  @media screen and (max-width: 600px) {
    padding: 48px 32px;
  }
`;

export const OpeningTitle = styled.h2`
  font-size: 38px;
  color: #fff;
  padding-bottom: 32px;
  border-bottom: 1px #434343 solid;
  margin-bottom: 32px;

  @media screen and (max-width: 800px) {
    text-align: center;
  }

  @media screen and (max-width: 414px) {
    font-size: 30px;
    line-height: 30px;
  }
`;

export const WrapperWeeks = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media screen and (max-width: 800px) {
    align-items: center;
  }

  @media screen and (max-width: 375px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const DayOfWeek = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  width: 50%;

  @media screen and (max-width: 375px) {
    flex-direction: column;
    width: 100%;
    justify-content: center;
  }
`;

export const DayOfWeekName = styled.h6`
  font-size: 15px;
  line-height: 15px;
  margin-bottom: 10px;
  color: #fff;
  text-transform: uppercase;

  @media screen and (max-width: 375px) {
    text-align: center;
  }
`;

export const DayOfWeekHour = styled.span`
  font-size: 16px;
  color: #fff;

  @media screen and (max-width: 375px) {
    text-align: center;
    width: 100%;
    display: block;
  }
`;

export const CalendarIcon = styled(Calendar).attrs({ size: 20 })`
  margin-right: 16px;
  color: #fff;

  @media screen and (max-width: 375px) {
    margin-right: 0;
    margin-bottom: 16px;
  }
`;
