import axios from "axios";

const fixedIp = "192.168.0.131";

const environment = {
  local: {
    api: `http://localhost:3333/`,
  },
  fixedIp: {
    api: `http://${fixedIp}:3333/`,
  },
  production: {
    api: `https://bnstudio-api.herokuapp.com/`,
  },
};

const baseURL =
  process.env.NODE_ENV === "development"
    ? environment.local
    : environment.production;

const api = axios.create({
  baseURL: baseURL.api,
});

export default api;
