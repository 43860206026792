import styled from "styled-components";
import { Whatsapp } from "@styled-icons/boxicons-logos";

export const Container = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 24px;
  background-color: #fff;
  border: 1px #edb86f solid;
  height: 50px;
  border-radius: 25px;
  position: fixed;
  bottom: 32px;
  right: 32px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.25);
  font-family: "BrandontextMedium";
  font-size: 16px;
  color: #000;
  transition: 300ms ease;
  z-index: 999;

  &:hover {
    transform: scale(1.1);
  }

  @media screen and (max-width: 414px) {
    span {
      display: none;
    }
    padding: 16px;
  }
`;

export const IconWhatsapp = styled(Whatsapp).attrs({ size: 20 })`
  margin-left: 8px;

  @media screen and (max-width: 414px) {
    margin-left: 0;
  }
`;
