import React from "react";
import { FormattedMessage } from "react-intl";

import * as S from "./styles";

const Banner: React.FC = () => {
  return (
    <S.Container>
      <S.Subtitle>
        <FormattedMessage id="studio.banner.subtitle" />
      </S.Subtitle>
      <S.Title>
        <FormattedMessage id="studio.banner.title" />
      </S.Title>
      <S.Description>
        <FormattedMessage id="studio.banner.description" />
      </S.Description>
    </S.Container>
  );
};

export default Banner;
