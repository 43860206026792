import React from "react";
import { Switch, Route } from "react-router-dom";

import Home from "pages/Home";
import Studio from "pages/Studio";
import Contact from "pages/Contact";
import { PrivateRoute } from "./PrivateRoute";
import { BookingPage, BookingReviewPage } from "pages/Booking";
import { CheckoutCompletePage } from "pages/Checkout";
import { MyAppointmentsPage, UpdateAccountPage } from "pages/MyAccount";

const MainRoutes: React.FC = () => {
  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/home/:slug" exact component={Home} />
      <Route path="/studio" exact component={Studio} />
      <Route path="/booking" exact component={BookingPage} />
      <Route path="/contact" exact component={Contact} />
      <PrivateRoute
        path="/booking-review"
        exact
        component={BookingReviewPage}
      />
      <PrivateRoute
        path="/checkout-complete/:appointmentId"
        exact
        component={CheckoutCompletePage}
      />
      <Route path="/reservations" exact component={MyAppointmentsPage} />
      <PrivateRoute path="/my-account" exact component={UpdateAccountPage} />
    </Switch>
  );
};

export default MainRoutes;
