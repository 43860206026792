import styled, { css } from "styled-components";
import { defaultStyles } from "./defaultStyles";
export { Loading } from "styles/components";

export const MainContainer = styled.div`
  ${defaultStyles};
  width: 100%;
`;

export const FieldSet = styled.fieldset.attrs({
  className: "sq-fieldset",
})`
  display: flex;
  flex-direction: column;
`;

export const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 24px;
  margin-bottom: 24px;
`;

export const FieldGroup = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
`;

export const ErrorMessage = styled.span<{ error?: string }>`
  color: #de0048;
  ${({ error }) => {
    if (typeof error === "string") {
      return css`
        &::after {
          display: block;
          padding: 8px 0;
          content: "${error}";
        }
      `;
    }
  }}
`;

export const ActionButtons = styled(FormRow)`
  @media screen and (max-width: 800px) {
    flex-direction: column;
    button {
      width: 100%;
    }
    button:not(:last-child) {
      margin-bottom: 16px;
    }
  }
`;
