import React, { useCallback, useState } from "react";
import { v4 as uuidV4 } from "uuid";
import {
  SqError,
  SqCardData,
  SqContact,
} from "react-square-payment-form/lib/components/models";
import {
  SquarePaymentForm,
  CreditCardNumberInput,
  CreditCardExpirationDateInput,
  CreditCardCVVInput,
  CreditCardSubmitButton,
} from "react-square-payment-form";

import * as S from "./styles";

interface IFormErrors {
  cardNumber?: string;
  cvv?: string;
  expirationDate?: string;
  postalCode?: string;
}

export interface SquarePaymentData {
  nonce: string;
  idempotency_key: string;
  location_id: string;
  amount: number;
}
interface SquarePaymentProps extends React.ComponentProps<any> {
  isSandbox: boolean;
  isBusy: boolean;
  applicationId: string;
  locationId: string;
  amount: number;
  cancelButton?: JSX.Element;
  processPayment(paymentData: SquarePaymentData): void;
}

type Props = SquarePaymentProps;

export const SquarePayment: React.FC<Props> = ({
  isSandbox,
  isBusy,
  applicationId,
  locationId,
  amount,
  cancelButton,
  processPayment,
}) => {
  const [formErrors, setFormErrors] = useState<IFormErrors>({});

  const handleFormErrors = useCallback((errors: SqError[]): void => {
    const newErrors: IFormErrors = {};
    for (const { field, message } of errors) {
      if (field) Object.assign(newErrors, { [field]: message });
    }
    setFormErrors(newErrors);
  }, []);

  /**
   * Nonce is a secure single-use token
   * Number to be used only Once. Hence Nonce
   */
  const cardNonceResponseReceived = useCallback(
    (
      errors: SqError[] | null,
      nonce: string,
      cardData: SqCardData,
      buyerVerificationToken?: string,
      billingContact?: SqContact
    ): void => {
      if (errors) {
        handleFormErrors(errors);
        return;
      }

      setFormErrors({});

      processPayment({
        nonce,
        idempotency_key: uuidV4(),
        location_id: locationId,
        amount,
      });
    },

    [handleFormErrors, processPayment, locationId, amount]
  );

  return (
    <S.MainContainer>
      {/**
       * the formId and apiWrapper props should be optional,
       * but the source typings are enforcing it anyway 💩
       * */}
      <SquarePaymentForm
        sandbox={isSandbox}
        applicationId={applicationId}
        locationId={locationId}
        cardNonceResponseReceived={cardNonceResponseReceived}
        formId="payment-form"
        apiWrapper="payment-api-wrapper"
      >
        <S.FieldSet>
          <S.FormRow>
            <S.FieldGroup>
              <CreditCardNumberInput />
              <S.ErrorMessage error={formErrors.cardNumber} />
            </S.FieldGroup>
          </S.FormRow>

          <S.FormRow>
            <S.FieldGroup>
              <CreditCardCVVInput />
              <S.ErrorMessage error={formErrors.cvv} />
            </S.FieldGroup>
            <S.FieldGroup>
              <CreditCardExpirationDateInput />
              <S.ErrorMessage error={formErrors.expirationDate} />
            </S.FieldGroup>
          </S.FormRow>
        </S.FieldSet>

        <S.ActionButtons>
          {cancelButton && cancelButton}
          <CreditCardSubmitButton>
            {(isBusy && <S.Loading />) || `Pay $${amount.toFixed(2)}`}
          </CreditCardSubmitButton>
        </S.ActionButtons>
      </SquarePaymentForm>
    </S.MainContainer>
  );
};
