import { createReducer, createActions } from "reduxsauce";
import update from "immutability-helper";

export interface UpdatePasswordRequestBody {
  password_recovery_code: string;
  phone: string;
  password: string;
}

export interface UpdatePasswordRequest {
  postData: UpdatePasswordRequestBody;
  onSuccess?(): void;
}

export interface UpdatePasswordState {
  loading: boolean;
  error: string | null;
}

interface FailureAction {
  error: string;
}

const INITIAL_STATE: UpdatePasswordState = {
  loading: false,
  error: null,
};

const { Types, Creators } = createActions(
  {
    request: ["postData", "onSuccess"],
    success: [],
    failure: ["error"],
    reset: [],
  },
  { prefix: "UPDATE_PASSWORD_" }
);

const request = (state: UpdatePasswordState) =>
  update(state, {
    loading: { $set: true },
    error: { $set: null },
  });

const success = (state: UpdatePasswordState) =>
  update(state, {
    loading: { $set: false },
  });

const failure = (state: UpdatePasswordState, action: FailureAction) =>
  update(state, {
    error: { $set: action.error },
    loading: { $set: false },
  });

const reset = () => INITIAL_STATE;

export const updatePassword = createReducer(INITIAL_STATE, {
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success,
  [Types.FAILURE]: failure,
  [Types.RESET]: reset,
});

export const UpdatePasswordTypes = Types;
export const UpdatePasswordActions = Creators;
