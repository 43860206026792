export const translations = {
  title: {
    pt: "Missão",
    en: "Mission",
    es: "Misión",
  },
  mission: {
    pt: "Nossa missão no BN Studio é fornecer serviços personalizados de beleza e bem-estar através de nossa equipe de profissionais experientes e apaixonados, aumentando a beleza e a confiança de nossos clientes. Estamos empenhados em proporcionar uma experiência única, fazendo com que nossos hóspedes se sintam bem-vindos, relaxados e bonitos. Sua satisfação e bem-estar são nossa prioridade número um. Vejo você em breve!",
    en: "Our mission at BN Studio is to provide personalized beauty and well-being services through our team of experienced and passionate professionals, increasing the beauty and confidence of our customers. We are dedicated to providing a unique experience, making our guests feel welcome, relaxed, and beautiful. Your satisfaction and well-being are our number one priority. See you soon!",
    es: "Nuestra misión en BN Studio es proporcionar servicios personalizados de belleza y bienestar a través de nuestro equipo de profesionales experimentados y apasionados, aumentando la belleza y la confianza de nuestros clientes. Estamos dedicados a brindar una experiencia única, haciendo que nuestros invitados se sientan bienvenidos, relajados y hermosos. Su satisfacción y bienestar es nuestra prioridad número uno. ¡Nos vemos pronto!",
  },
};
