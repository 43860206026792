import { createReducer, createActions } from "reduxsauce";
import update from "immutability-helper";
import { ISelectedService } from "../bookingPage";

const { Creators, Types } = createActions(
  {
    setData: ["data"],
    reset: [],
  },
  { prefix: "CART_" }
);

export interface ICartData {
  startsAt: Date;
  selectedServices: ISelectedService[];
  totalPrice: number;
  totalDuration: number;
}

interface SetDataAction {
  data: ICartData | Partial<ICartData>;
}

export interface CartState extends ICartData {}

const INITIAL_STATE: CartState = {
  startsAt: new Date(),
  selectedServices: [],
  totalPrice: 0,
  totalDuration: 0,
};

const setData = (state: CartState, action: SetDataAction) =>
  update(state, {
    $merge: action.data,
  });

const reset = () => INITIAL_STATE;

export const cart = createReducer(INITIAL_STATE, {
  [Types.SET_DATA]: setData,
  [Types.RESET]: reset,
});

export const CartTypes = Types;
export const CartActions = Creators;
