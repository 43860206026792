import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";

import {
  FindTimeframesActions,
  FindTimeframesState,
} from "store/ducks/booking";
import {
  MembersByServiceState,
  MembersByServiceActions,
} from "store/ducks/utils";
import {
  BookingPageActions,
  ISelectedService,
  BookingPageState,
} from "store/ducks/bookingPage";

import { Header, Footer } from "components/shared";
import {
  FeaturedHeader,
  ServicesSelector,
  IMembersByService,
  TimeframeSelector,
  ICalendarDate,
} from "components/Booking";
import { LoginOrCreateAccount } from "components/Auth";
import { AuthState } from "store/ducks/auth";

import { useTranslation } from "hooks";
import { translations } from "./translations";
import * as S from "./styles";

interface IAppointmentService {
  service_id: number;
  team_id: number;
}
interface IFindTimeframesPostData {
  target_date: string;
  appointment_services: IAppointmentService[];
}

interface BookingPageProps extends React.ComponentProps<any> {}

type Props = BookingPageProps;

export const BookingPage: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const { getTranslation } = useTranslation(translations);

  const {
    nextTargetDate,
    data: findTimeframesData,
    loading: findTimeframesLoading,
  } = useSelector<RootStateOrAny, FindTimeframesState>(
    (state) => state.findTimeframes
  );

  const { data: membersByService } = useSelector<
    RootStateOrAny,
    MembersByServiceState
  >((state) => state.membersByService);

  const { selectedServices } = useSelector<RootStateOrAny, BookingPageState>(
    (state) => state.bookingPage
  );

  const { loggedIn } = useSelector<RootStateOrAny, AuthState>(
    (state) => state.auth
  );

  const [calendarDates, setCalendarDates] = useState<ICalendarDate[]>([]);

  const handleServicesSelectorOnPressSearch = useCallback((): void => {
    const postData: IFindTimeframesPostData = {
      target_date: nextTargetDate,
      appointment_services: selectedServices.map(
        ({ service_id, team_id }: ISelectedService) => {
          return { service_id, team_id };
        }
      ),
    };
    setCalendarDates([]);
    dispatch(FindTimeframesActions.request(postData));
  }, [nextTargetDate, dispatch, selectedServices]);

  const handleServicesSelectorOnChange = useCallback((): void => {
    setCalendarDates([]);
  }, []);

  const handleTimeframesSelectorOnPressMore = useCallback((): void => {
    const postData: IFindTimeframesPostData = {
      target_date: nextTargetDate,
      appointment_services: selectedServices.map(
        ({ service_id, team_id }: ISelectedService) => {
          return { service_id, team_id };
        }
      ),
    };
    dispatch(FindTimeframesActions.request(postData));
  }, [nextTargetDate, dispatch, selectedServices]);

  const AuthArea = useCallback((): JSX.Element => {
    const LoginHeader = (
      <S.AuthHeader>
        <S.AuthHeaderTitle>
          {getTranslation("login", "title")}
        </S.AuthHeaderTitle>
        <S.AuthHeaderSubtitle>
          {getTranslation("login", "subtitle")}
        </S.AuthHeaderSubtitle>
      </S.AuthHeader>
    );

    const CreateAccountHeader = (
      <S.AuthHeader>
        <S.AuthHeaderTitle>
          {getTranslation("account", "title")}
        </S.AuthHeaderTitle>
        <S.AuthHeaderSubtitle>
          {getTranslation("account", "subtitle")}
        </S.AuthHeaderSubtitle>
      </S.AuthHeader>
    );

    const GenerateRecoveryCodeHeader = (
      <S.AuthHeader>
        <S.AuthHeaderTitle>
          {getTranslation("generateCode", "title")}
        </S.AuthHeaderTitle>
        <S.AuthHeaderSubtitle>
          {getTranslation("generateCode", "subtitle")}
        </S.AuthHeaderSubtitle>
      </S.AuthHeader>
    );

    const UpdatePasswordHeader = (
      <S.AuthHeader>
        <S.AuthHeaderTitle>
          {getTranslation("updatePassword", "title")}
        </S.AuthHeaderTitle>
        <S.AuthHeaderSubtitle>
          {getTranslation("updatePassword", "subtitle")}
        </S.AuthHeaderSubtitle>
      </S.AuthHeader>
    );

    return (
      <S.AuthContainer>
        <FeaturedHeader />
        <LoginOrCreateAccount
          loginHeader={LoginHeader}
          createAccountHeader={CreateAccountHeader}
          generateRecoveryCodeHeader={GenerateRecoveryCodeHeader}
          updatePasswordHeader={UpdatePasswordHeader}
        />
      </S.AuthContainer>
    );
  }, [getTranslation]);

  const fetchMembersByService = useCallback((): void => {
    if (!loggedIn) return;
    dispatch(MembersByServiceActions.request());
  }, [dispatch, loggedIn]);

  const onFindTimeframesChangeCB = useCallback((): void => {
    const newCalendarDates = findTimeframesData as ICalendarDate[];
    setCalendarDates((state) => [...state, ...newCalendarDates]);
  }, [findTimeframesData]);

  useEffect(() => {
    fetchMembersByService();
  }, [fetchMembersByService]);

  useEffect(() => {
    onFindTimeframesChangeCB();
  }, [onFindTimeframesChangeCB]);

  useEffect(() => {
    return () => {
      dispatch(MembersByServiceActions.reset());
      dispatch(FindTimeframesActions.reset());
      dispatch(BookingPageActions.reset());
    };
  }, [dispatch]);

  return (
    <S.PageContainer>
      <Header />
      {!loggedIn && <AuthArea />}

      {loggedIn && (
        <>
          <ServicesSelector
            membersByService={membersByService as IMembersByService[]}
            onPressSearch={handleServicesSelectorOnPressSearch}
            onChange={handleServicesSelectorOnChange}
            isSearching={findTimeframesLoading}
          />
          {calendarDates.length > 0 && (
            <TimeframeSelector
              calendarDates={calendarDates}
              onPressMore={handleTimeframesSelectorOnPressMore}
              selectedServices={selectedServices}
              isFetching={findTimeframesLoading}
            />
          )}
        </>
      )}
      <Footer />
    </S.PageContainer>
  );
};
