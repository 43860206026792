import { createReducer, createActions } from "reduxsauce";
import update from "immutability-helper";

export interface NextAppointmentsState {
  data: any[];
  loading: boolean;
  error: string | null;
}

export interface NextAppointmentsRequest {
  onSuccess?(): void;
  onFailure?(): void;
}

interface SuccessAction {
  data: any[];
}

interface FailureAction {
  error: string;
}

const { Types, Creators } = createActions(
  {
    request: ["onSuccess", "onFailure"],
    success: ["data"],
    failure: ["error"],
    reset: [],
  },
  { prefix: "NEXT_APPOINTMENTS" }
);

const INITIAL_STATE: NextAppointmentsState = {
  data: [],
  loading: false,
  error: null,
};

const request = (
  state: NextAppointmentsState,
  action: NextAppointmentsRequest
) =>
  update(state, {
    loading: { $set: true },
    error: { $set: null },
  });

const success = (state: NextAppointmentsState, action: SuccessAction) =>
  update(state, {
    data: { $set: action.data },
    loading: { $set: false },
  });

const failure = (state: NextAppointmentsState, action: FailureAction) =>
  update(state, {
    loading: { $set: false },
    error: { $set: action.error },
  });

const reset = () => INITIAL_STATE;

export const nextAppointments = createReducer(INITIAL_STATE, {
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success,
  [Types.FAILURE]: failure,
  [Types.RESET]: reset,
});

export const NextAppointmentsTypes = Types;
export const NextAppointmentsActions = Creators;
