import { call, put } from "redux-saga/effects";

import api from "services/api";
import { notify } from "services/notification";
import { requestErrorHandler } from "utils";

import {
  GenerateRecoveryCodeRequest,
  GenerateRecoveryCodeActions,
} from "store/ducks/passwordRecovery";

export function* generateRecoveryCodeRequest(action: any) {
  try {
    const { postData, onSuccess } = action as GenerateRecoveryCodeRequest;

    yield call(api.post, "session/generate-password-recovery", postData);

    if (onSuccess) onSuccess();
    notify("success", "Sending your code via SMS");
    yield put(GenerateRecoveryCodeActions.success());
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(GenerateRecoveryCodeActions.failure(errorMessage));
  }
}
