import { createReducer, createActions } from "reduxsauce";
import update from "immutability-helper";

export interface UpdateAccountState {
  loading: boolean;
  error: string | null;
}

export interface UpdateAccountRequestBody {
  first_name: string;
  last_name: string;
  phone: string;
  password?: string;
}

export interface UpdateAccountRequest {
  clientId: number;
  putData: UpdateAccountRequestBody;
  onSuccess?(): void;
  onFailure?(): void;
}

interface FailureAction {
  error: string;
}

const { Types, Creators } = createActions(
  {
    request: ["clientId", "putData", "onSuccess", "onFailure"],
    success: [],
    failure: ["error"],
    reset: [],
  },
  { prefix: "UPDATE_ACCOUNT_" }
);

const INITIAL_STATE: UpdateAccountState = {
  loading: false,
  error: null,
};

const request = (state: UpdateAccountState, action: UpdateAccountRequest) =>
  update(state, {
    loading: { $set: true },
    error: { $set: null },
  });

const success = (state: UpdateAccountState) =>
  update(state, {
    loading: { $set: false },
  });

const failure = (state: UpdateAccountState, action: FailureAction) =>
  update(state, {
    loading: { $set: false },
    error: { $set: action.error },
  });

const reset = () => INITIAL_STATE;

export const updateAccount = createReducer(INITIAL_STATE, {
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success,
  [Types.FAILURE]: failure,
  [Types.RESET]: reset,
});

export const UpdateAccountTypes = Types;
export const UpdateAccountActions = Creators;
