import styled from "styled-components";

export const FieldLabel = styled.label.attrs({ className: "field-label" })`
  display: block;
  font-family: "BrandontextRegular";
  font-size: 16px;
  color: #091524;
  margin-bottom: 16px;
`;

export const FieldError = styled.span.attrs({
  className: "error",
})`
  display: block;
  font-family: "BrandontextRegular";
  font-size: 14px;
  color: #de0048;
  margin-top: 8px;
`;

export const FieldContainer = styled.div.attrs({
  className: "field-container",
})`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;
  &:not(:last-child) {
    margin-right: 16px;
  }

  input:not([type="checkbox"]),
  input:not([type="radio"]) {
    width: 100%;
    border: 1px solid #b4c5d3;
    background-color: #fff;
    min-height: 52px;
    padding: 0 18px;
    border-radius: 4px;
  }
  textarea {
    width: 100%;
    border: 1px solid #b4c5d3;
    background-color: #fff;
    min-height: 140px;
    padding: 18px;
    border-radius: 4px;
  }
`;

export const FormRow = styled.div.attrs({ className: "form-row" })`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
`;

export const FieldSet = styled.fieldset`
  border: none;
`;
