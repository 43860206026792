import React from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import * as S from "./styles";

const About: React.FC = () => {
  const history = useHistory();
  return (
    <S.Container>
      <S.Content>
        <S.Subtitle>
          <FormattedMessage id="home.about.subtitle" />
        </S.Subtitle>
        <S.Title>
          <FormattedMessage id="home.about.title" />
        </S.Title>
        <S.Text>
          <p>
            <FormattedMessage id="home.about.text.p1" />
          </p>
        </S.Text>
        <S.KnowButton
          btStyle="transparent"
          onClick={() => history.push("/studio")}
        >
          <FormattedMessage id="home.about.knowmore" />
        </S.KnowButton>
      </S.Content>
      <S.Content className="last">
        <S.Wrapper>
          <S.Opening>
            <S.OpeningTitle>
              <FormattedMessage id="home.about.opening.title" />
            </S.OpeningTitle>
            <S.WrapperWeeks>
              <S.DayOfWeek>
                <S.CalendarIcon />
                <div>
                  <S.DayOfWeekName>
                    <FormattedMessage id="home.about.week.monday" />
                  </S.DayOfWeekName>
                  <S.DayOfWeekHour>
                    <FormattedMessage id="home.about.closed" />
                  </S.DayOfWeekHour>
                </div>
              </S.DayOfWeek>
              <S.DayOfWeek>
                <S.CalendarIcon />
                <div>
                  <S.DayOfWeekName>
                    <FormattedMessage id="home.about.week.tuesday" />
                  </S.DayOfWeekName>
                  <S.DayOfWeekHour>
                    <FormattedMessage id="home.about.opensat9am" />
                  </S.DayOfWeekHour>
                </div>
              </S.DayOfWeek>
              <S.DayOfWeek>
                <S.CalendarIcon />
                <div>
                  <S.DayOfWeekName>
                    <FormattedMessage id="home.about.week.wednesday" />
                  </S.DayOfWeekName>
                  <S.DayOfWeekHour>
                    <FormattedMessage id="home.about.opensat9am" />
                  </S.DayOfWeekHour>
                </div>
              </S.DayOfWeek>
              <S.DayOfWeek>
                <S.CalendarIcon />
                <div>
                  <S.DayOfWeekName>
                    <FormattedMessage id="home.about.week.thursday" />
                  </S.DayOfWeekName>
                  <S.DayOfWeekHour>
                    <FormattedMessage id="home.about.opensat9am" />
                  </S.DayOfWeekHour>
                </div>
              </S.DayOfWeek>
              <S.DayOfWeek>
                <S.CalendarIcon />
                <div>
                  <S.DayOfWeekName>
                    <FormattedMessage id="home.about.week.friday" />
                  </S.DayOfWeekName>
                  <S.DayOfWeekHour>
                    <FormattedMessage id="home.about.opensat9am" />
                  </S.DayOfWeekHour>
                </div>
              </S.DayOfWeek>
              <S.DayOfWeek>
                <S.CalendarIcon />
                <div>
                  <S.DayOfWeekName>
                    <FormattedMessage id="home.about.week.saturday" />
                  </S.DayOfWeekName>
                  <S.DayOfWeekHour>
                    <FormattedMessage id="home.about.opensat9am" />
                  </S.DayOfWeekHour>
                </div>
              </S.DayOfWeek>
              <S.DayOfWeek>
                <S.CalendarIcon />
                <div>
                  <S.DayOfWeekName>
                    <FormattedMessage id="home.about.week.sunday" />
                  </S.DayOfWeekName>
                  <S.DayOfWeekHour>
                    <FormattedMessage id="home.about.closed" />
                  </S.DayOfWeekHour>
                </div>
              </S.DayOfWeek>
            </S.WrapperWeeks>
          </S.Opening>
          <S.Photo />
        </S.Wrapper>
      </S.Content>
      <S.Background />
    </S.Container>
  );
};

export default About;
