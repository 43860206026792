import React, { useRef, useEffect, useState, useCallback } from "react";
import ReactSelect, {
  OptionTypeBase,
  Props as SelectProps,
} from "react-select";
import SelectBase from "react-select/src/Select";
import { useField } from "@unform/core";
import * as S from "./styles";

interface Props extends SelectProps<OptionTypeBase, true> {
  name: string;
  label?: string;
}

export const Select: React.FC<Props> = ({ name, label, ...rest }) => {
  const selectRef =
    useRef<ReactSelect<OptionTypeBase, true, SelectBase<OptionTypeBase, true>>>(
      null
    );

  // const [lastUpdate, setLastUpdate] = useState<Date>(new Date());

  const { fieldName, defaultValue, registerField, error } = useField(name);

  const Label = useCallback(() => {
    if (!label) return <></>;
    return <S.FieldLabel htmlFor={fieldName}>{label}</S.FieldLabel>;
  }, [label, fieldName]);

  const Error = useCallback(() => {
    if (!error) return <></>;
    return <S.FieldError>{error}</S.FieldError>;
  }, [error]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: undefined,
      setValue: (ref: any, value: any) => {
        ref.state.value = value;
        // setLastUpdate(new Date());
      },
      getValue: (ref: any) => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref?.state?.value?.map(
            (option: OptionTypeBase) => option.value
          );
        }
        if (!ref.state.value) {
          return "";
        }
        return ref.state.value.value;
      },
    });
  }, [selectRef, fieldName, registerField, rest.isMulti]);

  // useEffect(() => {}, [lastUpdate]);

  return (
    <S.FieldContainer>
      <Label />
      <ReactSelect
        styles={S.customStyles}
        name={fieldName}
        defaultValue={defaultValue}
        ref={selectRef}
        classNamePrefix="react-select"
        {...rest}
      />
      <Error />
    </S.FieldContainer>
  );
};
