export const translations = {
  title: {
    pt: "Selecione seus serviços",
    en: "Select your services",
    es: "Seleccione tu servicios",
  },
  fields: {
    service: {
      pt: "Serviço:",
      en: "Service:",
      es: "Servicio:",
    },
    professional: {
      pt: "Profissional:",
      en: "Professional:",
      es: "Profesional:",
    },
    description: {
      pt: "Descrição:",
      en: "Description:",
      es: "Descripción:",
    },
  },
  buttons: {
    addService: {
      pt: "Adicionar Serviço",
      en: "Add Service",
      es: "Agregar Servicio",
    },
    newSearch: {
      pt: "Nova Busca",
      en: "New Search",
      es: "Nueva Búsqueda",
    },
  },
};
