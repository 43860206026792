import { call, put } from "redux-saga/effects";

import api from "services/api";
import { notify } from "services/notification";
import { requestErrorHandler } from "utils";
import {
  AppointmentActions,
  AppointmentRequest,
} from "../../ducks/appointment";

export function* appointmentRequest(action: any) {
  try {
    const { appointmentId, onSuccess } = action as AppointmentRequest;
    const { data } = yield call(api.get, `appointment/${appointmentId}`);
    if (onSuccess) onSuccess();
    yield put(AppointmentActions.success(data));
  } catch (error) {
    const { onFailure } = action as AppointmentRequest;
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    if (onFailure) onFailure();
    yield put(AppointmentActions.failure(errorMessage));
  }
}
