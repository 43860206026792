import React from "react";
import { useSelector } from "react-redux";

import { Route, Redirect } from "react-router-dom";

interface PrivateRouteProps {
  [propName: string]: any;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const { loggedIn } = useSelector((state: any) => state.auth);
  return (
    <Route
      {...rest}
      render={(props) =>
        loggedIn === true ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/" }} />
        )
      }
    />
  );
};
