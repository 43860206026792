import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";

import * as S from "./styles";

const Footer: React.FC = () => {
  const history = useHistory();

  const navigateTo = useCallback(
    (path: string): void => {
      history.push(path);
    },
    [history]
  );

  return (
    <S.Container>
      <S.Content>
        <S.Column className="about">
          <S.Logo />
          <S.About>
            <FormattedMessage id="footer.description" />
          </S.About>
        </S.Column>
        <S.Wrapper>
          <S.Column>
            <S.Title>Menu</S.Title>
            <S.MenuButton onClick={() => navigateTo("/studio")}>
              <FormattedMessage id="menu.studio" />
            </S.MenuButton>
            <S.MenuButton onClick={() => navigateTo("/home/services")}>
              <FormattedMessage id="menu.services" />
            </S.MenuButton>
            <S.MenuButton onClick={() => navigateTo("/home/work")}>
              <FormattedMessage id="menu.work" />
            </S.MenuButton>
            <S.MenuButton onClick={() => navigateTo("/contact")}>
              <FormattedMessage id="menu.contact" />
            </S.MenuButton>
            {/* <S.MenuButton onClick={() => navigateTo("/reservations")}>
              <FormattedMessage id="menu.reservation" />
            </S.MenuButton> */}
          </S.Column>
          <S.Column>
            <S.Title>
              {" "}
              <FormattedMessage id="footer.hours" />
            </S.Title>
            <S.Week>
              <FormattedMessage id="footer.hours.week.monday" />:{" "}
              <FormattedMessage id="footer.hours.closed" />
            </S.Week>
            <S.Week>
              <FormattedMessage id="footer.hours.week.tuesday" />:{" "}
              <FormattedMessage id="footer.hours.opensat9am" />
            </S.Week>
            <S.Week>
              <FormattedMessage id="footer.hours.week.wednesday" />:{" "}
              <FormattedMessage id="footer.hours.opensat9am" />
            </S.Week>
            <S.Week>
              <FormattedMessage id="footer.hours.week.thursday" />:{" "}
              <FormattedMessage id="footer.hours.opensat9am" />
            </S.Week>
            <S.Week>
              <FormattedMessage id="footer.hours.week.friday" />:{" "}
              <FormattedMessage id="footer.hours.opensat9am" />
            </S.Week>
            <S.Week>
              <FormattedMessage id="footer.hours.week.saturday" />:{" "}
              <FormattedMessage id="footer.hours.opensat9am" />
            </S.Week>
            <S.Week>
              <FormattedMessage id="footer.hours.week.sunday" />:{" "}
              <FormattedMessage id="footer.hours.closed" />
            </S.Week>
          </S.Column>
          <S.Column>
            <S.Title>
              <FormattedMessage id="footer.findus" />
            </S.Title>
            <S.Address>
              BN Studio
              <br />
              Suite 101, 30NW 34th Street Miami 33127 (305) 603-9906
            </S.Address>
            <S.MapButton>
              <FormattedMessage id="footer.mapbutton" />
            </S.MapButton>
            <S.Social>
              <a
                href="https://www.instagram.com/bnstudiosalon/"
                target="_blank"
                rel="noreferrer"
              >
                <S.IconInstagram />
              </a>
              <a
                href="https://www.facebook.com/bnstudiosalon/"
                target="_blank"
                rel="noreferrer"
              >
                <S.IconFacebook />
              </a>
            </S.Social>
          </S.Column>
        </S.Wrapper>
      </S.Content>
      <S.Developer>
        <span>© COPYRIGHT 2020 - All rights reserved.</span>
        <span>
          Developed by{" "}
          <a
            href="https://fococomunicacao.com"
            target="_blank"
            rel="noreferrer"
          >
            Foco Comunicação
          </a>
        </span>
      </S.Developer>
    </S.Container>
  );
};

export default Footer;
