import React, { useCallback, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import { Form } from "@unform/web";
import { FormHandles, SubmitHandler } from "@unform/core";
import { format } from "date-fns";
import * as Yup from "yup";

import { useTranslation, useDateTranslation, useValidation } from "hooks";
import { ISelectedService } from "store/ducks/bookingPage";
import { FindTimeframesState } from "store/ducks/booking";
import { CartActions, ICartData } from "store/ducks/cart";
import { Select } from "components/shared";
import { translations } from "./translations";
import * as S from "./styles";

export interface ICalendarDate {
  targetDate: Date;
  timeframes: Date[];
}

interface TimeframeSelectorProps extends React.ComponentProps<any> {
  calendarDates: ICalendarDate[];
  selectedServices: ISelectedService[];
  onPressMore(): void;
  isFetching: boolean;
}

type Props = TimeframeSelectorProps;

export const TimeframeSelector: React.FC<Props> = ({
  calendarDates = [],
  selectedServices = [],
  onPressMore,
  isFetching,
}) => {
  const formRef = useRef<FormHandles>(null);
  const { getTranslation } = useTranslation(translations);
  const { formatDate } = useDateTranslation();
  const { handleFormErrors } = useValidation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { totalPrice, totalDuration } = useSelector<RootStateOrAny>(
    (state) => state.findTimeframes
  ) as FindTimeframesState;

  const [activeDateIndex, setActiveDateIndex] = useState<number>(0);

  const handleSubmit: SubmitHandler<any> = useCallback(
    async (data, { reset }) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          starts_at: Yup.date().typeError(
            getTranslation("errors", "startsAt", "invalid")
          ),
        });
        await schema.validate(data, { abortEarly: false });
        reset();

        const cartData: Partial<ICartData> = {
          startsAt: data.starts_at,
          selectedServices,
          totalPrice,
          totalDuration,
        };

        dispatch(CartActions.setData(cartData));
        history.push("/booking-review");
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [
      handleFormErrors,
      getTranslation,
      dispatch,
      selectedServices,
      totalPrice,
      totalDuration,
      history,
    ]
  );

  const ItemBody: React.FC = useCallback(() => {
    return (
      <S.ItemBody>
        <S.Subheader>
          <div>{getTranslation("labels", "services")}</div>
          <div>{getTranslation("labels", "professionals")}</div>
          <div>{getTranslation("labels", "price")}</div>
        </S.Subheader>
        <S.BodyBox>
          <S.ServiceDetails>
            <S.Column>
              <h4>{getTranslation("labels", "services")}</h4>
              {selectedServices.map(({ service_name }, key) => (
                <S.ColumnValue key={key}>{service_name}</S.ColumnValue>
              ))}
            </S.Column>
            <S.Column>
              <h4>{getTranslation("labels", "professionals")}</h4>
              {selectedServices.map(({ team_name }, key) => (
                <S.ColumnValue key={key}>{team_name}</S.ColumnValue>
              ))}
            </S.Column>
            <S.Column>
              <S.PriceValue>US$ {totalPrice}</S.PriceValue>
              <S.FeeLabel>{getTranslation("labels", "fee")}</S.FeeLabel>
              <S.FeeValue>US$ {totalPrice * 0.2}</S.FeeValue>
            </S.Column>
          </S.ServiceDetails>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Select
              name="starts_at"
              placeholder={getTranslation("fields", "startsAt")}
              options={calendarDates[activeDateIndex].timeframes.map((tf) => {
                return {
                  label: format(new Date(tf), "hh:mm aaa"),
                  value: tf,
                };
              })}
            />
            <S.BookNowButton>
              {getTranslation("buttons", "bookNow")}
            </S.BookNowButton>
          </Form>
        </S.BodyBox>
      </S.ItemBody>
    );
  }, [
    getTranslation,
    activeDateIndex,
    calendarDates,
    selectedServices,
    handleSubmit,
    totalPrice,
  ]);

  const listItemBuilder = useCallback(
    (c: ICalendarDate, key: number) => {
      return (
        <S.ListItem
          key={key}
          className={activeDateIndex === key ? "active" : ""}
        >
          <S.ItemHeader onClick={() => setActiveDateIndex(key)}>
            <S.ItemHeaderTitle>
              {formatDate(new Date(c.targetDate), "MM/dd")}
              {" - "}
              <span>{formatDate(new Date(c.targetDate), "EEEE")}</span>
            </S.ItemHeaderTitle>
            <S.ToggleButton>
              {getTranslation("buttons", "viewMore")}
              <S.ToggleIcon />
            </S.ToggleButton>
          </S.ItemHeader>
          {activeDateIndex === key && <ItemBody />}
        </S.ListItem>
      );
    },
    [getTranslation, formatDate, setActiveDateIndex, activeDateIndex, ItemBody]
  );

  return (
    <S.MainContainer>
      <S.ContentConstrainer>
        <S.Header>
          <S.HeaderTitle>{getTranslation("header", "title")}</S.HeaderTitle>
        </S.Header>
        <S.ListView>
          {calendarDates.map((c, key) => listItemBuilder(c, key))}
        </S.ListView>
        <S.ActionButtons>
          <S.ViewMoreDatesButton onClick={onPressMore}>
            {(isFetching && <S.Loading />) ||
              getTranslation("buttons", "viewMoreDates")}
          </S.ViewMoreDatesButton>
        </S.ActionButtons>
      </S.ContentConstrainer>
    </S.MainContainer>
  );
};
