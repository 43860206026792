import { createReducer, createActions } from "reduxsauce";
import update from "immutability-helper";

export interface CreateAppointmentState {
  loading: boolean;
  error: string | null;
}

interface AppointmentService {
  service_id: number;
  team_id: number;
}

export interface CreateAppointmentPostData {
  client_id: number;
  register_origin: string;
  starts_at: string;
  payment_token: string;
  appointment_services: AppointmentService[];
}

export interface CreateAppointmentRequest {
  postData: CreateAppointmentPostData;
  onSuccess?(appointmentId: number): void;
  onFailure?(): void;
}

interface SuccessAction {}

interface FailureAction {
  error: string;
}

const { Types, Creators } = createActions(
  {
    request: ["postData", "onSuccess", "onFailure"],
    success: [],
    failure: ["error"],
    reset: [],
  },
  { prefix: "CREATE_APPOINTMENT_" }
);

const INITIAL_STATE: CreateAppointmentState = {
  loading: false,
  error: null,
};

const request = (
  state: CreateAppointmentState,
  action: CreateAppointmentRequest
) =>
  update(state, {
    loading: { $set: true },
    error: { $set: null },
  });

const success = (state: CreateAppointmentState, action: SuccessAction) =>
  update(state, {
    loading: { $set: false },
  });

const failure = (state: CreateAppointmentState, action: FailureAction) =>
  update(state, {
    loading: { $set: false },
    error: { $set: action.error },
  });

const reset = () => INITIAL_STATE;

export const createAppointment = createReducer(INITIAL_STATE, {
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success,
  [Types.FAILURE]: failure,
  [Types.RESET]: reset,
});

export const CreateAppointmentTypes = Types;
export const CreateAppointmentActions = Creators;
