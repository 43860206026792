import { createReducer, createActions } from "reduxsauce";
import update from "immutability-helper";

export interface HomeBannerFile {
  path: string;
}

export interface LastHomeBannerState {
  data: HomeBannerFile | null;
  loading: boolean;
  error: string | null;
}

interface LastHomeBannerSuccess {
  data: HomeBannerFile;
}

interface LastHomeBannerFailure {
  error: string;
}

const { Types, Creators } = createActions(
  {
    request: [],
    success: ["data"],
    failure: ["error"],
    reset: [],
  },
  { prefix: "LAST_HOME_BANNER_" }
);

const INITIAL_STATE: LastHomeBannerState = {
  data: null,
  loading: false,
  error: null,
};

const request = (state: LastHomeBannerState) =>
  update(state, {
    loading: { $set: true },
    error: { $set: null },
  });

const success = (state: LastHomeBannerState, action: LastHomeBannerSuccess) =>
  update(state, {
    loading: { $set: false },
    data: { $set: action.data },
  });

const failure = (state: LastHomeBannerState, action: LastHomeBannerFailure) =>
  update(state, {
    loading: { $set: false },
    error: { $set: action.error },
  });

const reset = () => INITIAL_STATE;

export const lastHomeBanner = createReducer(INITIAL_STATE, {
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success,
  [Types.FAILURE]: failure,
  [Types.RESET]: reset,
});

export const LastHomeBannerTypes = Types;
export const LastHomeBannerActions = Creators;
