import { all, takeLatest } from "redux-saga/effects";

// auth
import { AuthTypes } from "../ducks/auth";
import { loginRequest, logoutRequest, rehydrateAccessToken } from "./auth";

// account
import { CreateAccountTypes, UpdateAccountTypes } from "../ducks/account";
import { createAccountRequest, updateAccountRequest } from "./account";

// appointment
import {
  AppointmentTypes,
  CreateAppointmentTypes,
  NextAppointmentsTypes,
  PaginateAppointmentsTypes,
} from "../ducks/appointment";
import {
  appointmentRequest,
  createAppointmentRequest,
  nextAppointmentsRequest,
  paginateAppointmentsRequest,
} from "./appointment";

// booking timeframes
import { FindTimeframesTypes } from "../ducks/booking";
import { findTimeframesRequest } from "./booking";

// banners
import { LastHomeBannerTypes } from "../ducks/banners";
import { lastHomeBannerRequest } from "./banners";

// payment
import { ProcessPaymentTypes } from "../ducks/payment";
import { processPaymentRequest } from "./payment";

// password recovery
import {
  GenerateRecoveryCodeTypes,
  UpdatePasswordTypes,
} from "../ducks/passwordRecovery";
import {
  generateRecoveryCodeRequest,
  updatePasswordRequest,
} from "./passwordRecovery";

// utils
import { MembersByServiceTypes } from "../ducks/utils";
import { fetchMembersByServiceRequest } from "./utils";

export default function* rootSaga() {
  yield all([
    // auth
    takeLatest("persist/REHYDRATE", rehydrateAccessToken),
    takeLatest(AuthTypes.LOGIN_REQUEST, loginRequest),
    takeLatest(AuthTypes.LOGOUT_REQUEST, logoutRequest),
    // account
    takeLatest(CreateAccountTypes.REQUEST, createAccountRequest),
    takeLatest(UpdateAccountTypes.REQUEST, updateAccountRequest),
    // appointment
    takeLatest(AppointmentTypes.REQUEST, appointmentRequest),
    takeLatest(CreateAppointmentTypes.REQUEST, createAppointmentRequest),
    takeLatest(NextAppointmentsTypes.REQUEST, nextAppointmentsRequest),
    takeLatest(PaginateAppointmentsTypes.REQUEST, paginateAppointmentsRequest),
    // banners
    takeLatest(LastHomeBannerTypes.REQUEST, lastHomeBannerRequest),
    // booking timeframes
    takeLatest(FindTimeframesTypes.REQUEST, findTimeframesRequest),
    // payment
    takeLatest(ProcessPaymentTypes.REQUEST, processPaymentRequest),
    // password recovery
    takeLatest(GenerateRecoveryCodeTypes.REQUEST, generateRecoveryCodeRequest),
    takeLatest(UpdatePasswordTypes.REQUEST, updatePasswordRequest),
    // utils
    takeLatest(MembersByServiceTypes.REQUEST, fetchMembersByServiceRequest),
  ]);
}
