import styled from "styled-components";
export { PageContainer, Loading } from "styles/components";

export const AuthContainer = styled.div`
  display: flex;
  flex-direction: row;

  > div {
    flex: 0 0 50%;
    align-self: stretch;
  }

  > div.booking-featured-header {
    padding: 90px 160px;
    @media only scren and (max-width: 1600px) {
      padding: 90px 90px;
    }
    @media only screen and (max-width: 1280px) {
      padding: 90px 48px;
    }
  }

  > div.booking-login-or-create-account {
    padding: 90px 120px;
    @media only scren and (max-width: 1600px) {
      padding: 90px 90px;
    }
    @media only screen and (max-width: 1280px) {
      padding: 90px 48px;
    }
  }

  @media only screen and (max-width: 1000px) {
    flex-wrap: wrap;
    > div {
      flex: 1 0 100%;
    }
  }
`;

export const AuthHeader = styled.div`
  border-bottom: 1px solid #b1c0cb;
  padding-bottom: 32px;
  margin-bottom: 24px;
`;

export const AuthHeaderTitle = styled.h2`
  font-size: 30px;
  margin-bottom: 14px;
  color: #091524;
`;

export const AuthHeaderSubtitle = styled.p`
  color: #434e5c;
`;
