import React, { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

import defaultImage from "assets/images/banner/mina.png";

import {
  LastHomeBannerActions,
  LastHomeBannerState,
} from "store/ducks/banners";
import * as S from "./styles";

const Banner: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { data: homeBanner } = useSelector<RootStateOrAny, LastHomeBannerState>(
    (state) => state.lastHomeBanner
  );

  const BannerImageComponent: React.FC = useCallback(() => {
    const imgSrc = homeBanner?.path || defaultImage;
    return <S.BannerImage src={imgSrc} />;
  }, [homeBanner]);

  const fetchBannerImage = useCallback(() => {
    dispatch(LastHomeBannerActions.request());
  }, [dispatch]);

  useEffect(() => {
    fetchBannerImage();
  }, [fetchBannerImage]);

  useEffect(() => {
    return () => {
      dispatch(LastHomeBannerActions.reset());
    };
  }, [dispatch]);

  return (
    <S.Container>
      <S.Content className="left">
        <S.Wrapper>
          <S.Subtitle>
            <FormattedMessage id="home.banner.get_your_hair" />
          </S.Subtitle>
          <S.Title>
            <FormattedMessage id="home.banner.styled_by_our" />
            <span>
              {" "}
              <FormattedMessage id="home.banner.team" />
            </span>
          </S.Title>
          <S.Description>
            <FormattedMessage id="home.banner.description" />
          </S.Description>
          <S.Button
            whileHover={{ scale: 1.1 }}
            onClick={() =>
              window.open(
                "https://api.whatsapp.com/send?phone=+17863541690",
                "_blank"
              )
            }
          >
            <FormattedMessage id="home.banner.book_now" />
          </S.Button>
          <S.Address>
            <S.IconPin />
            Suite 101, 30NW 34th Street Miami 33127
          </S.Address>
        </S.Wrapper>
      </S.Content>
      <S.Content className="right">
        <BannerImageComponent />
      </S.Content>
    </S.Container>
  );
};

export default Banner;
